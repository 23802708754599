<div class="order-summary">
    <div class="d-flex align-items-center mb-3">
        <div>
            <img class="mr-2" src="assets/icons/icon-flight-ticket.png" width="30" />
            <strong style="vertical-align: middle" class="title-text-size color-general-text">Booking</strong>
        </div>
        <div class="ml-auto">
            <app-searchable-dropdown
                [settings]="monthDropdownSettings"
                [items]="monthDropdownItems"
                (selected)="onSelectDateRange($event)"
            ></app-searchable-dropdown>
        </div>
    </div>

    <div class="order-summary-box">
        <div class="d-flex align-items-center mb-3">
            <span class="color-general-text sub-title-text-size"
                >Online Booking ทั้งหมด {{ totalCount || '-' }} Booking</span
            >
            <span
                class="ml-auto color-blue-text"
                style="cursor: pointer"
                (click)="
                    redirectToOrderListPage([
                        'pending_approve_order',
                        'waiting_paid_first_installment',
                        'waiting_paid_all_installment',
                        'completed'
                    ])
                "
            >
                <u>ดูทั้งหมด</u>
            </span>
        </div>
        <div class="order-status">
            <div
                class="order-status-box"
                style="background-color: #039be5; cursor: pointer"
                (click)="redirectToOrderListPage(['pending_approve_order'])"
            >
                <div class="mr-2">
                    <span class="order-status-icon">
                        <img src="assets/icons/icon-status-pending.png" />
                    </span>
                </div>
                <div class="order-status-text">
                    <span class="sub-title-text-size">รอตรวจสอบ</span>
                    <span>
                        <strong class="big-text-size align-sub">{{ orderCount?.pendingApproveOrder || '-' }}</strong
                        >&nbsp;
                        <span>Booking</span>
                    </span>
                </div>
            </div>
            <div
                class="order-status-box"
                style="background-color: #ffb300; cursor: pointer"
                (click)="redirectToOrderListPage(['waiting_paid_first_installment', 'waiting_paid_all_installment'])"
            >
                <div class="mr-2">
                    <span class="order-status-icon">
                        <img src="assets/icons/icon-status-waiting-payment.png" />
                    </span>
                </div>
                <div class="order-status-text">
                    <span class="sub-title-text-size">รอชำระเงิน</span>
                    <span>
                        <strong class="big-text-size align-sub">{{ orderCount?.waitingPaid || '-' }}</strong
                        >&nbsp;
                        <span>Booking</span>
                    </span>
                </div>
            </div>
            <div
                class="order-status-box"
                style="background-color: #43a047; cursor: pointer"
                (click)="redirectToOrderListPage(['completed'])"
            >
                <div class="mr-2">
                    <span class="order-status-icon">
                        <img src="assets/icons/icon-status-complete.png" />
                    </span>
                </div>
                <div class="order-status-text">
                    <span class="sub-title-text-size">ชำระเงินครบแล้ว</span>
                    <span>
                        <strong class="big-text-size align-sub">{{ orderCount?.complete || '-' }}</strong
                        >&nbsp;
                        <span>Booking</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
