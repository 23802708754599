import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as ProductActions from './product.actions';
import { NgxUiLoaderService } from 'ngx-ui-loader';

// Services
import { ProductService } from 'src/app/services/product.service';
import { ProductPeriodService } from 'src/app/services/product-period.service';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

// Models
import { ProductCount } from 'src/app/models/product/product-count.model';
import { ProductList } from 'src/app/models/product/product-list.model';
import { ProductSingle } from 'src/app/models/product/product-single.model';
import { ProductFilter } from 'src/app/models/product/product-filter.model';
import { ProductFile } from 'src/app/models/product/product-file.model';
import { PeriodDetail } from 'src/app/models/product/product-period/period-detail.model';
import { IProductAttachmentFile, ProductAttachmentFile } from 'src/app/models/product/product-attachment-file.model';
import * as _ from 'lodash-es';

@Injectable()
export class ProductEffects {
    constructor(
        private actions$: Actions,
        private productService: ProductService,
        private productPeriodService: ProductPeriodService,
        private ngxService: NgxUiLoaderService
    ) {}

    getProductsCount = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.getProductsCount),
            switchMap(() => {
                this.ngxService.start();
                return this.productService.getProductsCount().pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return ProductActions.getProductsCountSuccess({
                                productsCount: new ProductCount().deserialize(resp.data),
                            });
                        } else {
                            throw new Error();
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(ProductActions.getProductsCountFailure({ error: error }));
                    })
                );
            })
        )
    );

    getProductsMonths = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.getProductMonths),
            switchMap((action) => {
                return this.productService.getProductMonths(action.productCategoryIds).pipe(
                    map((resp: any) => {
                        if (resp.status == 'success') {
                            return ProductActions.getProductMonthsSuccess({
                                months: (resp.data as any[]).map((monthData) => {
                                    const minDate = new Date(monthData.min_date);
                                    return {
                                        month: minDate.getMonth() + 1,
                                        year: minDate.getFullYear(),
                                    };
                                }),
                            });
                        } else {
                            throw new Error();
                        }
                    }),
                    catchError((error) => {
                        return of(ProductActions.getProductMonthsFailure({ error: error }));
                    })
                );
            })
        )
    );

    getProductListPaginated = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.getProductListPaginated),
            switchMap((action) => {
                this.ngxService.start();
                return this.productService.getProductListPaginated(action.queries).pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return ProductActions.getProductListPaginatedSuccess({
                                productList: new ProductList().deserialize(resp.data),
                                isNextPaginated: action.isNextPaginated,
                            });
                        } else {
                            throw new Error();
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(ProductActions.getProductListPaginatedFailure({ error: error }));
                    })
                );
            })
        )
    );

    getSingleProduct = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.getSingleProduct),
            switchMap((action) => {
                this.ngxService.start();
                return this.productService.getSingleProduct(action.productId).pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return ProductActions.getSingleProductSuccess({
                                product: new ProductSingle().deserialize(resp.data),
                            });
                        } else {
                            throw new Error();
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(ProductActions.getSingleProductFailure({ error: error }));
                    })
                );
            })
        )
    );

    getSinglePeriod = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.getSinglePeriod),
            switchMap((action) => {
                this.ngxService.start();
                return this.productPeriodService.getSinglePeriod(action.productId, action.periodId).pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return ProductActions.getSinglePeriodSuccess({
                                period: new PeriodDetail().deserialize(resp.data),
                            });
                        } else {
                            return ProductActions.getSinglePeriodFailure({ error: resp.error });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(ProductActions.getSinglePeriodFailure({ error: error }));
                    })
                );
            })
        )
    );

    postNewPeriod = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.postNewPeriod),
            switchMap((action) => {
                this.ngxService.start();
                return this.productPeriodService.createPeriod(action.productId, action.period).pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return ProductActions.postNewPeriodSuccess({
                                id: resp.data.product_period_id,
                            });
                        } else {
                            return ProductActions.postNewPeriodFailure({ error: resp.error });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(ProductActions.postNewPeriodFailure({ error: error }));
                    })
                );
            })
        )
    );

    updatePeriod = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.updatePeriod),
            switchMap((action) => {
                this.ngxService.start();
                return this.productPeriodService.updatePeriod(action.productId, action.periodId, action.period).pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return ProductActions.updatePeriodSuccess();
                        } else {
                            return ProductActions.updatePeriodFailure({ error: resp.error });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(ProductActions.updatePeriodFailure({ error: error }));
                    })
                );
            })
        )
    );

    deletePeriod = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.deletePeriod),
            switchMap((action) => {
                this.ngxService.start();
                return this.productPeriodService.deletePeriod(action.productId, action.periodId).pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return ProductActions.deletePeriodSuccess();
                        } else {
                            return ProductActions.deletePeriodFailure({ error: resp.error });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(ProductActions.deletePeriodFailure({ error: error }));
                    })
                );
            })
        )
    );

    postNewProduct = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.postNewProduct),
            switchMap((action) => {
                this.ngxService.start();
                return this.productService.postNewProduct(action.product).pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return ProductActions.postNewProductSuccess({
                                productId: resp.data.product_id,
                            });
                        } else {
                            throw new Error();
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(ProductActions.postNewProductFailure({ error: error }));
                    })
                );
            })
        )
    );

    postProductImage = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.postProductImage),
            switchMap((action) => {
                this.ngxService.start();
                return this.productService.uploadBanner(action.formData).pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            const file = <File>action.formData.get('file');
                            return ProductActions.postProductImageSuccess({
                                data: new ProductFile().deserialize(resp.data, file),
                            });
                        } else {
                            throw resp;
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(ProductActions.postProductImageFailure({ error: error }));
                    })
                );
            })
        )
    );

    getProductsFilter = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.getProductsFilter),
            switchMap((action) => {
                return this.productService.getProductsFilter(action.filters).pipe(
                    map((resp: any) => {
                        if (resp.status == 'success') {
                            return ProductActions.getProductsFilterSuccess({
                                productsFilter: resp.data.map((item) => new ProductFilter().deserialize(item)),
                            });
                        } else {
                            throw new Error();
                        }
                    }),
                    catchError((error) => {
                        return of(ProductActions.getProductsFilterFailure({ error: error }));
                    })
                );
            })
        )
    );

    updateProduct = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.updateProduct),
            switchMap((action) => {
                this.ngxService.start();
                return this.productService.updateProduct(action.productId, action.product).pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return ProductActions.updateProductSuccess();
                        } else {
                            return ProductActions.updateProductFailure({ error: resp.error });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(ProductActions.updateProductFailure({ error: error }));
                    })
                );
            })
        )
    );

    updateProductDetails = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.updateProductDetails),
            switchMap((action) => {
                this.ngxService.start();
                return this.productService.updateProductDetail(action.productId, action.productDetails).pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return ProductActions.updateProductDetailsSuccess();
                        } else {
                            throw new Error();
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(ProductActions.updateProductDetailsFailure({ error: error }));
                    })
                );
            })
        )
    );

    updateProductNote = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.updateProductNote),
            switchMap((action) => {
                this.ngxService.start();
                return this.productService.updateProductNote(action.productId, action.note).pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return ProductActions.updateProductNoteSuccess({ note: action.note });
                        } else {
                            throw new Error();
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(ProductActions.updateProductNoteFailure({ error: error }));
                    })
                );
            })
        )
    );

    postProductAttachment = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.postProductAttachment),
            switchMap((action) => {
                this.ngxService.start();
                return this.productService.uploadAttachment(action.productId, action.formData).pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            const file: IProductAttachmentFile = new ProductAttachmentFile();
                            file.fileId = resp.data.product_attachment_file_id;
                            file.fileUrl = resp.data.product_attachment_file_url;
                            file.fileName = resp.data.product_attachment_file_name;
                            return ProductActions.postProductAttachmentSuccess({ data: file });
                        } else {
                            throw new Error();
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(ProductActions.postProductAttachmentFailure({ error: error }));
                    })
                );
            })
        )
    );

    deleteProductAttachment = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.deleteProductAttachment),
            switchMap((action) => {
                this.ngxService.start();
                return this.productService.deleteAttachment(action.productId, action.fileId).pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return ProductActions.deleteProductAttachmentSuccess({
                                productId: action.productId,
                                fileId: action.fileId,
                            });
                        } else {
                            throw new Error();
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(ProductActions.deleteProductAttachmentFailure({ error: error }));
                    })
                );
            })
        )
    );

    deleteProduct = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ProductActions.deleteProduct),
            switchMap((action) => {
                this.ngxService.start();
                return this.productService.deleteProduct(action.productId).pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return ProductActions.deleteProductSuccess({ productId: action.productId });
                        } else {
                            return ProductActions.deleteProductFailure({ productId: action.productId, error: resp });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(ProductActions.deleteProductFailure({ productId: action.productId, error: error }));
                    })
                );
            })
        )
    );
}
