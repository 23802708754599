import { DateTimeHelpers } from 'src/app/helpers/date-time.helpers';

export class ObAgencyContact {
    public id: number;
    public firstName: string;
    public lastName: string;
    public nickName: string;
    public tel: string;
    public channelObStatusCode: string;
    public agency: {
        id: number;
        companyName: string;
        aliasName: string;
        tourismLicense: string;
        tel: string;
    };
    public user: {
        username: string;
        roleSlug: string;
    };
    public createdAt: Date;

    public deserialize(data: any): this {
        this.id = data.id;
        this.firstName = data.first_name;
        this.lastName = data.last_name;
        this.nickName = data.nick_name;
        this.tel = data.tel;
        this.channelObStatusCode = data.channel_ob_status_code;
        this.agency = {
            id: data.agency.id,
            companyName: data.agency.company_name,
            aliasName: data.agency.alias_name,
            tourismLicense: data.agency.tourism_license,
            tel: data.agency.tel,
        };
        this.user = {
            username: data.user.username,
            roleSlug: data.user.role_slug,
        };
        this.createdAt = DateTimeHelpers.convertStringToDate(data.created_at);
        return this;
    }
}
