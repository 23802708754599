import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENDPOINTS } from '../constants/endpoint.constants';
import { DateTimeHelpers } from '../helpers/date-time.helpers';
import { HttpProService } from './base/http-pro.service';

@Injectable({
    providedIn: 'root',
})
export class ObOrderService {
    constructor(private httpService: HttpProService) {}

    public getCountPerStatus(filters: { minDate: Date; maxDate: Date } = null): Observable<any> {
        const url = ENDPOINTS.OB_ORDER.GET_COUNT_PER_STATUS;

        let params = new HttpParams();
        if (filters) {
            params = params.set(
                'filters',
                JSON.stringify({
                    date_range: {
                        min_date: filters.minDate ? DateTimeHelpers.convertDateToYMDString(filters.minDate) : null,
                        max_date: filters.maxDate ? DateTimeHelpers.convertDateToYMDString(filters.maxDate) : null,
                    },
                })
            );
        }

        return this.httpService.get(url + '?' + params.toString(), true);
    }
}
