import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    CountOrderStatus,
    CountProductSubCategory,
    ProductOrderListPaginated,
} from 'src/app/models/order/product-order-list.model';
import { ProductOrderService } from 'src/app/services/product-order.service';
import * as OrderActions from './order.actions';

@Injectable()
export class OrderEffects {
    constructor(
        private actions$: Actions,
        private ngxService: NgxUiLoaderService,
        private productOrderService: ProductOrderService
    ) {}

    getCountProductSubCategory = createEffect(() => () =>
        this.actions$.pipe(
            ofType(OrderActions.getCountProductSubCategory),
            switchMap((action) => {
                this.ngxService.start();
                return this.productOrderService.getCountProductSubCategory(action.searchString).pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return OrderActions.getCountProductSubCategorySuccess({
                                model: new CountProductSubCategory().deserialize(resp.data),
                            });
                        } else {
                            throw new Error();
                        }
                    }),
                    catchError((error) => {
                        return of(OrderActions.getCountProductSubCategoryError({ error: error.toString() }));
                    })
                );
            })
        )
    );

    getCountOrderStatus = createEffect(() => () =>
        this.actions$.pipe(
            ofType(OrderActions.getCountOrderStatus),
            switchMap((action) => {
                this.ngxService.start();
                return this.productOrderService
                    .getCountOrderStatus(action.productSubCategorySlug, action.searchString)
                    .pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.getCountOrderStatusSuccess({
                                    model: new CountOrderStatus().deserialize(resp.data),
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.getCountOrderStatusError({ error: error.toString() }));
                        })
                    );
            })
        )
    );

    getOrderListPaginated = createEffect(() => () =>
        this.actions$.pipe(
            ofType(OrderActions.getProductOrderListPaginated),
            switchMap((action) => {
                this.ngxService.start();
                return this.productOrderService
                    .getProductOrderListPaginated(
                        action.orderStatusSlug,
                        action.page,
                        action.pageEntries,
                        action.productSubCategorySlug,
                        action.searchString,
                        action.sortBy
                    )
                    .pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.getProductOrderListPaginatedSuccess({
                                    model: new ProductOrderListPaginated().deserialize(resp.data),
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.getProductOrderListPaginatedError({ error: error.toString() }));
                        })
                    );
            })
        )
    );
}
