import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';

//
import { ProductState } from './product-store/product.reducer';
import { ReportState } from './report-store/report.reducer';
import { UtilityState } from './utility-store/utility.reducer';
import { WholesaleState } from './wholesale-store/wholesale.reducer';

export interface State {
    product: ProductState;
    wholesale: WholesaleState;
    utility: UtilityState;
    report: ReportState;
}

export const reducers: ActionReducerMap<State> = {
    product: null,
    wholesale: null,
    utility: null,
    report: null,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
