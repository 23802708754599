<div class="domestic-product-summary general-text-size color-general-text">
    <div class="d-flex align-items-center">
        <div>
            <strong class="title-text-size color-general-text">ทัวร์ในประเทศ</strong>&nbsp;
            <span class="sub-title-text-size color-grey-text">มีจังหวัด ดังนี้</span>&nbsp;
            <a
                class="sub-title-text-size color-blue-text"
                [routerLink]="['/product', 'domestic']"
                [queryParams]="{ loadImmediately: true }"
            >
                <u>ดูทั้งหมด</u>
            </a>
        </div>
        <div class="total-summary ml-auto d-flex">
            <div class="pr-2" style="border-right: solid 1px #eceef3">
                <strong class="big-text-size color-blue-text align-sub">
                    {{ productCount?.domesticPackage || '-' }}
                </strong>
                &nbsp;
                <span class="color-grey-text">Package Tour</span>
            </div>
            <div class="pl-2">
                <strong class="big-text-size color-blue-text align-sub">
                    {{ productCount?.domesticJoin || '-' }}
                </strong>
                &nbsp;
                <span class="color-grey-text">Join Tour</span>
            </div>
        </div>
    </div>

    <!-- has data  -->
    <table class="table">
        <ng-container *ngFor="let region of regions">
            <tr *ngIf="region.provinces.length">
                <td class="region-cell" style="width: 120px">
                    <span>{{ region.name }}</span>
                </td>
                <td class="region-cell">
                    <span
                        style="cursor: pointer"
                        *ngFor="let province of region.provinces"
                        class="province-box mr-2"
                        [routerLink]="['/product', 'domestic']"
                        [queryParams]="{ provinceId: province.id, loadImmediately: true }"
                    >
                        <span>{{ province.nameTH }}</span>
                    </span>
                </td>
            </tr>
        </ng-container>
    </table>

    <!-- data is not found -->
    <div class="data-not-found" *ngIf="regions !== null && provinceCount === 0">
        <div class="data-not-found-box">
            <div>
                <img src="assets/icons/icon-not-found.png" width="110" height="110" />
            </div>
            <div class="mt-3">
                <strong class="secondary-title-text-size color-grey-text">ไม่พบข้อมูลโปรแกรมทัวร์</strong>
            </div>
        </div>
    </div>
</div>
