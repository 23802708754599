<div class="product-period-summary">
    <div class="d-flex align-items-center mb-3">
        <div>
            <img class="mr-2" src="assets/icons/icon-plane-and-globe.png" width="30" />
            <strong style="vertical-align: middle" class="title-text-size color-general-text">Plan ออกกรุ๊ป</strong>
        </div>
        <div class="ml-auto">
            <app-searchable-dropdown
                [settings]="monthDropdownSettings"
                [items]="monthDropdownItems"
                (selected)="onSelectMonthOption($event)"
            ></app-searchable-dropdown>
        </div>
    </div>

    <!-- has data  -->
    <div class="period-box">
        <table class="table">
            <thead class="period-box-header">
                <th style="width: 15%">ประเทศ</th>
                <th>Period ที่ออก</th>
                <th style="width: 10%">บิน</th>
                <th style="width: 15%">Tourcode</th>
                <th style="width: 15%">Group Code</th>
                <th style="width: 10%">size</th>
                <th style="width: 10%">&nbsp;</th>
            </thead>
            <tbody>
                <tr *ngFor="let period of periods">
                    <td>
                        <img
                            *ngIf="period.product.countries[0]"
                            [src]="period.product.countries[0].iconUrl"
                            alt="country-flag"
                            width="23"
                            height="15"
                        />
                        <span *ngIf="period.product.countries[0]" class="color-grey-text pl-2">{{
                            period.product.countries[0].nameTH
                        }}</span>
                    </td>
                    <td>
                        <span class="color-grey-text">{{
                            { date1: period.startAt, date2: period.endAt } | thaiDateRangePipe: true
                        }}</span>
                    </td>
                    <td>
                        <span class="color-grey-text">{{ period.goTransportation?.code || '-' }}</span>
                    </td>
                    <td>
                        <span class="color-grey-text">{{ period.product.tourCode }}</span>
                    </td>
                    <td>
                        <span class="color-grey-text">{{ period.groupCode || '-' }}</span>
                    </td>
                    <td>
                        <span class="color-grey-text">
                            {{ period.quantity }}
                            {{ period.quantityTourLead ? '+' + period.quantityTourLead : '' }}
                        </span>
                    </td>
                    <td>
                        <a
                            class="color-blue-text"
                            [routerLink]="['/product', period.product.subCategorySlug, 'view', period.product.id]"
                            [queryParams]="{ activeTab: 3, selectedPeriod: period.id }"
                        >
                            <u>ดูข้อมูล</u>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- data is not found -->
        <div class="data-not-found" *ngIf="periods && periods.length === 0">
            <div class="data-not-found-box">
                <div>
                    <img src="assets/icons/icon-not-found.png" width="110" height="110" />
                </div>
                <div class="mt-3">
                    <strong class="secondary-title-text-size color-grey-text">ไม่พบ Period ที่ออกกรุ๊ป</strong>
                </div>
            </div>
        </div>
    </div>
</div>
