import { createAction, props } from '@ngrx/store';
import { Continent, Region } from 'src/app/models/location.model';
import { ObAgencyContactCount } from 'src/app/models/ob-agency/ob-agency-contact-count.model';
import { ObAgencyContact } from 'src/app/models/ob-agency/ob-agency-contact.model';
import { ObAgencyCount } from 'src/app/models/ob-agency/ob-agency-count.model';
import { ObOrderCount } from 'src/app/models/order/ob-order-count.model';
import { ProductCount } from 'src/app/models/product/product-count.model';
import { DashBoardProductPeriod } from 'src/app/models/product/product-period/dashboard-period.model';

export enum ActionTypes {
    GET_ACTIVE_PRODUCTS_COUNT = '[Report] Get active products count',
    GET_ACTIVE_PRODUCTS_COUNT_SUCCESS = '[Report] Get active products count successfully',
    GET_ACTIVE_PRODUCTS_COUNT_FAILURE = '[Report] Get active products count unsuccessfully',

    GET_CONTINENTS = '[Report] Get continents',
    GET_CONTINENTS_SUCCESS = '[Report] Get continents successfully',
    GET_CONTINENTS_FAILURE = '[Report] Get continents unsuccessfully',

    GET_REGIONS = '[Report] Get regions',
    GET_REGIONS_SUCCESS = '[Report] Get regions successfully',
    GET_REGIONS_FAILURE = '[Report] Get regions unsuccessfully',

    GET_PRODUCT_PERIODS = '[Report] Get product periods',
    GET_PRODUCT_PERIODS_SUCCESS = '[Report] Get product periods successfully',
    GET_PRODUCT_PERIODS_FAILURE = '[Report] Get product periods unsuccessfully',

    GET_OB_ORDER_COUNT_PER_STATUS = '[Report] Get ob-order count per status',
    GET_OB_ORDER_COUNT_PER_STATUS_SUCCESS = '[Report] Get ob-order count per status successfully',
    GET_OB_ORDER_COUNT_PER_STATUS_FAILURE = '[Report] Get ob-order count per status unsuccessfully',

    GET_AGCY_AGENCY_COUNT = '[Report] Get Agcy-agency count',
    GET_AGCY_AGENCY_COUNT_SUCCESS = '[Report] Get Agcy-agency count successfully',
    GET_AGCY_AGENCY_COUNT_FAILURE = '[Report] Get Agcy-agency count unsuccessfully',

    GET_OB_AGENCY_COUNT = '[Report] Get Ob-agency count',
    GET_OB_AGENCY_COUNT_SUCCESS = '[Report] Get Ob-agency count successfully',
    GET_OB_AGENCY_COUNT_FAILURE = '[Report] Get Ob-agency count unsuccessfully',

    GET_OB_AGENCY_CONTACT_COUNT = '[Report] Get Ob-agency contact count',
    GET_OB_AGENCY_CONTACT_COUNT_SUCCESS = '[Report] Get Ob-agency contact count successfully',
    GET_OB_AGENCY_CONTACT_COUNT_FAILURE = '[Report] Get Ob-agency contact count unsuccessfully',

    GET_OB_AGENCY_CONTACTS = '[Report] Get Ob-agency contacts',
    GET_OB_AGENCY_CONTACTS_SUCCESS = '[Report] Get Ob-agency contacts successfully',
    GET_OB_AGENCY_CONTACTS_FAILURE = '[Report] Get Ob-agency contacts unsuccessfully',
}

export const getActiveProductCount = createAction(ActionTypes.GET_ACTIVE_PRODUCTS_COUNT);
export const getActiveProductCountSuccess = createAction(
    ActionTypes.GET_ACTIVE_PRODUCTS_COUNT_SUCCESS,
    props<{ activeProductCount: ProductCount }>()
);
export const getActiveProductCountFailure = createAction(
    ActionTypes.GET_ACTIVE_PRODUCTS_COUNT_FAILURE,
    props<{ error: any }>()
);

export const getContinents = createAction(ActionTypes.GET_CONTINENTS);
export const getContinentsSuccess = createAction(
    ActionTypes.GET_CONTINENTS_SUCCESS,
    props<{ continents: Continent[] }>()
);
export const getContinentsFailure = createAction(ActionTypes.GET_CONTINENTS_FAILURE, props<{ error: any }>());

export const getRegions = createAction(ActionTypes.GET_REGIONS);
export const getRegionsSuccess = createAction(ActionTypes.GET_REGIONS_SUCCESS, props<{ regions: Region[] }>());
export const getRegionsFailure = createAction(ActionTypes.GET_REGIONS_FAILURE, props<{ error: any }>());

export const getProductPeriods = createAction(
    ActionTypes.GET_PRODUCT_PERIODS,
    props<{ minDate: Date; maxDate: Date }>()
);
export const getProductPeriodsSuccess = createAction(
    ActionTypes.GET_PRODUCT_PERIODS_SUCCESS,
    props<{ periods: DashBoardProductPeriod[] }>()
);
export const getProductPeriodsFailure = createAction(ActionTypes.GET_PRODUCT_PERIODS_FAILURE, props<{ error: any }>());

export const getObOrderCountPerStatus = createAction(
    ActionTypes.GET_OB_ORDER_COUNT_PER_STATUS,
    props<{ minDate: Date; maxDate: Date }>()
);
export const getObOrderCountPerStatusSuccess = createAction(
    ActionTypes.GET_OB_ORDER_COUNT_PER_STATUS_SUCCESS,
    props<{ obOrderCount: ObOrderCount }>()
);
export const getObOrderCountPerStatusFailure = createAction(
    ActionTypes.GET_OB_ORDER_COUNT_PER_STATUS_FAILURE,
    props<{ error: any }>()
);

export const getAgcyAgencyCount = createAction(ActionTypes.GET_AGCY_AGENCY_COUNT);
export const getAgcyAgencyCountSuccess = createAction(
    ActionTypes.GET_AGCY_AGENCY_COUNT_SUCCESS,
    props<{ count: number }>()
);
export const getAgcyAgencyCountFailure = createAction(
    ActionTypes.GET_AGCY_AGENCY_COUNT_FAILURE,
    props<{ error: any }>()
);

export const getObAgencyCount = createAction(ActionTypes.GET_OB_AGENCY_COUNT);
export const getObAgencyCountSuccess = createAction(
    ActionTypes.GET_OB_AGENCY_COUNT_SUCCESS,
    props<{ obAgencyCount: ObAgencyCount }>()
);
export const getObAgencyCountFailure = createAction(ActionTypes.GET_OB_AGENCY_COUNT_FAILURE, props<{ error: any }>());

export const getObAgencyContactCount = createAction(ActionTypes.GET_OB_AGENCY_CONTACT_COUNT);
export const getObAgencyContactCountSuccess = createAction(
    ActionTypes.GET_OB_AGENCY_CONTACT_COUNT_SUCCESS,
    props<{ obAgencyContactCount: ObAgencyContactCount }>()
);
export const getObAgencyContactCountFailure = createAction(
    ActionTypes.GET_OB_AGENCY_CONTACT_COUNT_FAILURE,
    props<{ error: any }>()
);

export const getObAgencyContacts = createAction(ActionTypes.GET_OB_AGENCY_CONTACTS);
export const getObAgencyContactsSuccess = createAction(
    ActionTypes.GET_OB_AGENCY_CONTACTS_SUCCESS,
    props<{ contacts: ObAgencyContact[] }>()
);
export const getObAgencyContactsFailure = createAction(
    ActionTypes.GET_OB_AGENCY_CONTACTS_FAILURE,
    props<{ error: any }>()
);
