import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { defineLocale, thLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

// Share Components
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SearchableDropdownComponent } from './components/searchable-dropdown/searchable-dropdown.component';
import { CalendarInputComponent } from './components/calendar-input/calendar-input.component';
import { RichTextInputComponent } from './components/rich-text-input/rich-text-input.component';
import { TagsInputComponent } from './components/tags-input/tags-input.component';

import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { SuccessModalComponent } from './modals/success-modal/success-modal.component';
import { ConfirmDeleteModalComponent } from './modals/confirm-delete-modal/confirm-delete-modal.component';

const SHARED_COMPONENTS = [
    BreadcrumbComponent,
    SearchableDropdownComponent,
    CalendarInputComponent,
    RichTextInputComponent,
    TagsInputComponent,

    SuccessModalComponent,
    ErrorModalComponent,
    ConfirmDeleteModalComponent,
    ConfirmModalComponent,
];

// Pipes
import { JoinTextPipe } from 'src/app/pipes/string.pipe';
import { ThaiDatePipe, ThaiMonthRangePipe, ThaiDateRangePipe } from 'src/app/pipes/thaidate.pipe';
import { TransportCategoryLabelPipe, TransportLabelPipe } from 'src/app/pipes/transport.pipe';
import { Convert2StringArrayPipe } from 'src/app/pipes/tag.pipe';
import { ProductCategoryPipe } from 'src/app/pipes/product-category.pipe';
import { ThaiDayOfWeekPipe } from 'src/app/pipes/thai-day-of-week.pipe';
import { PhoneNumberPipe } from 'src/app/pipes/phone-number.pipe';

const PIPES = [
    JoinTextPipe,
    ThaiDatePipe,
    ThaiDateRangePipe,
    ThaiMonthRangePipe,
    TransportCategoryLabelPipe,
    TransportLabelPipe,
    Convert2StringArrayPipe,
    ProductCategoryPipe,
    ThaiDayOfWeekPipe,
    PhoneNumberPipe,
];

// Directives
import { IntegerDirective } from 'src/app/directives/integer.directive';
import { CurrencyDirective } from 'src/app/directives/currency.directive';
import { UppercaseInputDirective } from 'src/app/directives/uppercase-input.directive';
import { UnallowedCharactersDirective } from 'src/app/directives/unallowed-characters.directive';
import { UnallowedSpecialCharactersDirective } from 'src/app/directives/unallowed-special-charecters.directive';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';

const DIRECTIVES = [
    IntegerDirective,
    CurrencyDirective,
    UppercaseInputDirective,
    UnallowedCharactersDirective,
    UnallowedSpecialCharactersDirective,
];

defineLocale('th', thLocale);

@NgModule({
    declarations: [...DIRECTIVES, ...PIPES, ...SHARED_COMPONENTS],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        CKEditorModule,
    ],
    exports: [...DIRECTIVES, ...PIPES, ...SHARED_COMPONENTS],
    providers: [BsLocaleService, ...PIPES, CurrencyPipe],
})
export class SharedModule {
    constructor(private localeService: BsLocaleService) {
        // change language date picker
        this.localeService.use('th');
    }
}
