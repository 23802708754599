import { ICountrySubUnit } from "./interfaces/country-sub-unit.interface";

export class CountrySubUnit {
    id: number;
    nameTh: string|null;
    nameEn: string|null;
    countriesId: number;

    deserialize(data: ICountrySubUnit): CountrySubUnit {
        this.id = data.id;
        this.nameEn = data.name_en;
        this.nameTh = data.name_th;
        this.countriesId = data.countries_id;
        return this;
    }
}
