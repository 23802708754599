import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ObAgencyContact } from 'src/app/models/ob-agency/ob-agency-contact.model';
import { ObAgencyCount } from 'src/app/models/ob-agency/ob-agency-count.model';
import { getAgcyAgencyCount, getObAgencyContacts, getObAgencyCount } from 'src/app/stores/report-store/report.actions';
import {
    selectAgcyAgencyCount,
    selectObAgencyContacts,
    selectObAgencyCount,
} from 'src/app/stores/report-store/report.selectors';
import { selectProfile } from 'src/app/stores/wholesale-store/wholesale.selectors';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-agency-contact-summary',
    templateUrl: './agency-contact-summary.component.html',
    styleUrls: ['./agency-contact-summary.component.scss'],
})
export class AgencyContactSummaryComponent implements OnInit, OnDestroy {
    constructor(private store: Store) {}

    v1BaseUrl: string;
    agcyAgencyCount: number;
    obAgencyCount: ObAgencyCount;
    obAgencyContacts: ObAgencyContact[] = null;

    private unsubscribe$ = new Subject<void>();

    ngOnInit(): void {
        this.loadData();

        this.store
            .select(selectProfile)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((profile) => {
                this.v1BaseUrl = environment.tourwowProBackofficeUrl + profile.supplier.productTwpSlug;
            });

        this.store
            .select(selectAgcyAgencyCount)
            .pipe(
                filter((x) => !!x),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((count) => {
                this.agcyAgencyCount = count;
            });

        this.store
            .select(selectObAgencyCount)
            .pipe(
                filter((x) => x !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((count) => {
                this.obAgencyCount = count;
            });

        this.store
            .select(selectObAgencyContacts)
            .pipe(
                filter((x) => x !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((contacts) => {
                this.obAgencyContacts = contacts;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    reload(): void {
        this.loadData();
    }

    private loadData(): void {
        this.store.dispatch(getAgcyAgencyCount());
        this.store.dispatch(getObAgencyCount());
        this.store.dispatch(getObAgencyContacts());
    }

    displayTextTime(datetime: string): string {
        return datetime.replace(/ ([^ ]*)$/, ' เวลา $1');
    }
}
