export class ObAgencyContactCount {
    public pendingApprove: number;
    public available: number;

    public deserialize(data: any): this {
        this.pendingApprove = data.pending_approve;
        this.available = data.available;
        return this;
    }
}
