import { createAction, props } from '@ngrx/store';

// Models
import { BasePaginatedQueries } from 'src/app/models/base-queries.model';
import { ProductCount } from 'src/app/models/product/product-count.model';
import { ProductList } from 'src/app/models/product/product-list.model';
import { ProductPaginatedQueries } from 'src/app/models/product/product-paginated-queries.model';
import { ProductSingle } from 'src/app/models/product/product-single.model';
import { INewProgram, IUpdateProgram } from 'src/app/models/product/product.model';
import { IProductFile } from 'src/app/models/product/product-file.model';
import { ProductFilter } from 'src/app/models/product/product-filter.model';
import { PeriodDetail } from 'src/app/models/product/product-period/period-detail.model';
import { IProductAttachmentFile } from 'src/app/models/product/product-attachment-file.model';
import { ITag } from 'src/app/models/tag.model';
import { ICountry } from 'src/app/models/country.model';

export enum ActionTypes {
    GET_PRODUCTS_COUNT = '[Product] Get products count',
    GET_PRODUCTS_COUNT_SUCCESS = '[Product] Get products count successfully',
    GET_PRODUCTS_COUNT_FAILURE = '[Product] Get products count unsuccessfully',

    GET_PRODUCT_LIST_PAGINATED = '[Product] User tried to get product paginated',
    GET_PRODUCT_LIST_PAGINATED_SUCCESS = '[Product] User tried to get product paginated successfully',
    GET_PRODUCT_LIST_PAGINATED_FAILURE = '[Product] User tried to get product paginated unsuccessfully',

    GET_SINGLE_PRODUCT = '[Product] User tried to get a single product',
    GET_SINGLE_PRODUCT_SUCCESS = '[Product] User tried to get a single product successfully',
    GET_SINGLE_PRODUCT_FAILURE = '[Product] User tried to get a single product unsuccessfully',
    RESET_SINGLE_PRODUCT_FAILURE = '[Product] Reset a single product unsuccessfully',

    POST_NEW_PRODUCT = '[Product] New product.',
    POST_NEW_PRODUCT_SUCCESS = '[Product] New product successfully.',
    POST_NEW_PRODUCT_FAILURE = '[Product] New product unsuccessfully.',

    UPDATE_PRODUCT = '[Product] Update product.',
    UPDATE_PRODUCT_SUCCESS = '[Product] Update product successfully.',
    UPDATE_PRODUCT_FAILURE = '[Product] Update product unsuccessfully.',

    POST_PRODUCT_IMAGE = '[Product] Product image',
    POST_PRODUCT_IMAGE_SUCCESS = '[Product] Product image successfully',
    POST_PRODUCT_IMAGE_FAILURE = '[Product] Product image unsuccessfully',

    GET_PRODUCTS_FILTER = '[Product] Get Products filter',
    GET_PRODUCTS_FILTER_SUCCESS = '[Product] Get Products filter successfully',
    GET_PRODUCTS_FILTER_FAILURE = '[Product] Get Products filter unsuccessfully',

    GET_PRODUCT_MONTHS = '[Product] Get Product Month',
    GET_PRODUCT_MONTHS_SUCCESS = '[Product] Get Product Month successfully',
    GET_PRODUCT_MONTHS_FAILURE = '[Product] Get Product Month unsuccessfully',

    UPDATE_PRODUCT_DETAIL = '[Product] Update Product Details',
    UPDATE_PRODUCT_DETAIL_SUCCESS = '[Product] Update Product Details successfully.',
    UPDATE_PRODUCT_DETAIL_FAILURE = '[Product] Update Product Details unsuccessfully.',

    UPDATE_PRODUCT_NOTE = '[Product] Update Product Note (internal)',
    UPDATE_PRODUCT_NOTE_SUCCESS = '[Product] Update Product Note (internal) successfully.',
    UPDATE_PRODUCT_NOTE_FAILURE = '[Product] Update Product Note (internal) unsuccessfully.',

    RESET_PRODUCT_UPDATE_SUCCESS = '[Product] Reset Single Product Update success.',

    DELETE_PRODUCT = '[Product] User tried to delete product',
    DELETE_PRODUCT_SUCCESS = '[Product] Delete product successfully',
    DELETE_PRODUCT_FAILURE = '[Product] Delete product unsuccessfully',
    DELETE_PRODUCT_RESET_RESPOSNE = '[Product] Delete product: response resetted',

    POST_PRODUCT_ATTACHMENT = '[Product] Product Attachment',
    POST_PRODUCT_ATTACHMENT_SUCCESS = '[Product] Product Attachment successfully',
    POST_PRODUCT_ATTACHMENT_FAILURE = '[Product] Product Attachment unsuccessfully',

    DELETE_PRODUCT_ATTACHMENT = '[Product] Delete Product Attachment',
    DELETE_PRODUCT_ATTACHMENT_SUCCESS = '[Product] Delete Product Attachment successfully',
    DELETE_PRODUCT_ATTACHMENT_FAILURE = '[Product] Delete Product Attachment unsuccessfully',

    GET_SINGLE_PERIOD = '[Product] User tried to get a single period',
    GET_SINGLE_PERIOD_SUCCESS = '[Product] User tried to get a single period successfully',
    GET_SINGLE_PERIOD_FAILURE = '[Product] User tried to get a single period unsuccessfully',

    POST_NEW_PERIOD = '[Product] User tried to create new period',
    POST_NEW_PERIOD_SUCCESS = '[Product] Create period successfully',
    POST_NEW_PERIOD_FAILURE = '[Product] Create period unsuccessfully',
    POST_NEW_PERIOD_RESET_RESPOSNE = '[Product] Create period: response resetted',

    UPDATE_PERIOD = '[Product] User tried to update period',
    UPDATE_PERIOD_SUCCESS = '[Product] Update period successfully',
    UPDATE_PERIOD_FAILURE = '[Product] Update period unsuccessfully',
    UPDATE_PERIOD_RESET_RESPOSNE = '[Product] Update period: response resetted',

    DELETE_PERIOD = '[Product] User tried to delete period',
    DELETE_PERIOD_SUCCESS = '[Product] Delete period successfully',
    DELETE_PERIOD_FAILURE = '[Product] Delete period unsuccessfully',
    DELETE_PERIOD_RESET_RESPOSNE = '[Product] Delete period: response resetted',

    SET_DISPLAY_DETAIL_EXTENSION = '[Product Management Page] Set display detail extension.',
    ADD_PRODUCT_TAGS = '[Product Management Page] Add tags product form.',
    REMOVE_PRODUCT_TAG = '[Product Management Page] Remove tag product form.',
    RESET_PRODUCT_FORM = '[Product Management Page] Reset product form.',
    SET_PRODUCT_COUNTRY = '[Product Management Page] Set country product form.',
    SET_PRODUCT_COUNTRIES = '[Product Management Page] Set countries product form.',

    SET_PRODUCT_PAGE_IS_SUBMITTED = '[Product Management Page] Set isSubmitted',
    SET_PRODUCT_PAGE_IS_CANCELED = '[Product Management Page] Set isCanceled',
}

// Get products count.
export const getProductsCount = createAction(ActionTypes.GET_PRODUCTS_COUNT);
export const getProductsCountSuccess = createAction(
    ActionTypes.GET_PRODUCTS_COUNT_SUCCESS,
    props<{ productsCount: ProductCount }>()
);
export const getProductsCountFailure = createAction(ActionTypes.GET_PRODUCTS_COUNT_FAILURE, props<{ error: any }>());

// Get product month.
export const getProductMonths = createAction(ActionTypes.GET_PRODUCT_MONTHS, props<{ productCategoryIds: number[] }>());
export const getProductMonthsSuccess = createAction(
    ActionTypes.GET_PRODUCT_MONTHS_SUCCESS,
    props<{ months: { month: number; year: number }[] }>()
);
export const getProductMonthsFailure = createAction(ActionTypes.GET_PRODUCT_MONTHS_FAILURE, props<{ error: any }>());

// Get product list paginated.
export const getProductListPaginated = createAction(
    ActionTypes.GET_PRODUCT_LIST_PAGINATED,
    props<{ queries: BasePaginatedQueries<ProductPaginatedQueries>; isNextPaginated: boolean }>()
);
export const getProductListPaginatedSuccess = createAction(
    ActionTypes.GET_PRODUCT_LIST_PAGINATED_SUCCESS,
    props<{ productList: ProductList; isNextPaginated: boolean }>()
);
export const getProductListPaginatedFailure = createAction(
    ActionTypes.GET_PRODUCT_LIST_PAGINATED_FAILURE,
    props<{ error: any }>()
);

// Get single product.
export const getSingleProduct = createAction(ActionTypes.GET_SINGLE_PRODUCT, props<{ productId: number }>());
export const getSingleProductSuccess = createAction(
    ActionTypes.GET_SINGLE_PRODUCT_SUCCESS,
    props<{ product: ProductSingle }>()
);
export const getSingleProductFailure = createAction(ActionTypes.GET_SINGLE_PRODUCT_FAILURE, props<{ error: any }>());
export const resetSingleProductFailure = createAction(ActionTypes.RESET_SINGLE_PRODUCT_FAILURE);

// Post new product
export const postNewProduct = createAction(ActionTypes.POST_NEW_PRODUCT, props<{ product: INewProgram }>());
export const postNewProductSuccess = createAction(ActionTypes.POST_NEW_PRODUCT_SUCCESS, props<{ productId: number }>());
export const postNewProductFailure = createAction(ActionTypes.POST_NEW_PRODUCT_FAILURE, props<{ error: any }>());

// Post product images
export const postProductImage = createAction(ActionTypes.POST_PRODUCT_IMAGE, props<{ formData: FormData }>());
export const postProductImageSuccess = createAction(
    ActionTypes.POST_PRODUCT_IMAGE_SUCCESS,
    props<{ data: IProductFile }>()
);
export const postProductImageFailure = createAction(ActionTypes.POST_PRODUCT_IMAGE_FAILURE, props<{ error: any }>());

// Get Products
export const getProductsFilter = createAction(
    ActionTypes.GET_PRODUCTS_FILTER,
    props<{ filters?: ProductPaginatedQueries }>()
);
export const getProductsFilterSuccess = createAction(
    ActionTypes.GET_PRODUCTS_FILTER_SUCCESS,
    props<{ productsFilter: ProductFilter[] }>()
);
export const getProductsFilterFailure = createAction(ActionTypes.GET_PRODUCTS_FILTER_FAILURE, props<{ error: any }>());

// Update Product
export const updateProduct = createAction(
    ActionTypes.UPDATE_PRODUCT,
    props<{ productId: number; product: IUpdateProgram }>()
);
export const updateProductSuccess = createAction(ActionTypes.UPDATE_PRODUCT_SUCCESS);
export const updateProductFailure = createAction(ActionTypes.UPDATE_PRODUCT_FAILURE, props<{ error: any }>());

// Update Product Details
export const updateProductDetails = createAction(
    ActionTypes.UPDATE_PRODUCT_DETAIL,
    props<{ productId: number; productDetails: any }>()
);
export const updateProductDetailsSuccess = createAction(ActionTypes.UPDATE_PRODUCT_DETAIL_SUCCESS);
export const updateProductDetailsFailure = createAction(
    ActionTypes.UPDATE_PRODUCT_DETAIL_FAILURE,
    props<{ error: any }>()
);

// Update Product Note
export const updateProductNote = createAction(
    ActionTypes.UPDATE_PRODUCT_NOTE,
    props<{ productId: number; note: string }>()
);
export const updateProductNoteSuccess = createAction(
    ActionTypes.UPDATE_PRODUCT_NOTE_SUCCESS,
    props<{ note: string }>()
);
export const updateProductNoteFailure = createAction(ActionTypes.UPDATE_PRODUCT_NOTE_FAILURE, props<{ error: any }>());

// Reset Single Product Update Successful Flag.
export const resetSingleProductUpdateSuccess = createAction(ActionTypes.RESET_PRODUCT_UPDATE_SUCCESS);

// Delete Product
export const deleteProduct = createAction(ActionTypes.DELETE_PRODUCT, props<{ productId: number }>());
export const deleteProductSuccess = createAction(ActionTypes.DELETE_PRODUCT_SUCCESS, props<{ productId: number }>());
export const deleteProductFailure = createAction(
    ActionTypes.DELETE_PRODUCT_FAILURE,
    props<{ productId: number; error: any }>()
);
export const deleteProductResetResponse = createAction(ActionTypes.DELETE_PRODUCT_RESET_RESPOSNE);

// Post product Attachment
export const postProductAttachment = createAction(
    ActionTypes.POST_PRODUCT_ATTACHMENT,
    props<{ productId: number; formData: FormData }>()
);
export const postProductAttachmentSuccess = createAction(
    ActionTypes.POST_PRODUCT_ATTACHMENT_SUCCESS,
    props<{ data: IProductAttachmentFile }>()
);
export const postProductAttachmentFailure = createAction(
    ActionTypes.POST_PRODUCT_ATTACHMENT_FAILURE,
    props<{ error: any }>()
);

// Delete product Attachment
export const deleteProductAttachment = createAction(
    ActionTypes.DELETE_PRODUCT_ATTACHMENT,
    props<{ productId: number; fileId: number }>()
);
export const deleteProductAttachmentSuccess = createAction(
    ActionTypes.DELETE_PRODUCT_ATTACHMENT_SUCCESS,
    props<{ productId: number; fileId: number }>()
);
export const deleteProductAttachmentFailure = createAction(
    ActionTypes.DELETE_PRODUCT_ATTACHMENT_FAILURE,
    props<{ error: any }>()
);

// Get single period.
export const getSinglePeriod = createAction(
    ActionTypes.GET_SINGLE_PERIOD,
    props<{ productId: number; periodId: number }>()
);
export const getSinglePeriodSuccess = createAction(
    ActionTypes.GET_SINGLE_PERIOD_SUCCESS,
    props<{ period: PeriodDetail }>()
);
export const getSinglePeriodFailure = createAction(ActionTypes.GET_SINGLE_PRODUCT_FAILURE, props<{ error: any }>());

// Post single period.
export const postNewPeriod = createAction(
    ActionTypes.POST_NEW_PERIOD,
    props<{ productId: number; period: PeriodDetail }>()
);
export const postNewPeriodSuccess = createAction(ActionTypes.POST_NEW_PERIOD_SUCCESS, props<{ id: number }>());
export const postNewPeriodFailure = createAction(ActionTypes.POST_NEW_PERIOD_FAILURE, props<{ error: any }>());
export const postNewPeriodResetResponse = createAction(ActionTypes.POST_NEW_PERIOD_RESET_RESPOSNE);

// Update single period.
export const updatePeriod = createAction(
    ActionTypes.UPDATE_PERIOD,
    props<{ productId: number; periodId: number; period: PeriodDetail }>()
);
export const updatePeriodSuccess = createAction(ActionTypes.UPDATE_PERIOD_SUCCESS);
export const updatePeriodFailure = createAction(ActionTypes.UPDATE_PERIOD_FAILURE, props<{ error: any }>());
export const updatePeriodResetResponse = createAction(ActionTypes.UPDATE_PERIOD_RESET_RESPOSNE);

// Delete single period.
export const deletePeriod = createAction(ActionTypes.DELETE_PERIOD, props<{ productId: number; periodId: number }>());
export const deletePeriodSuccess = createAction(ActionTypes.DELETE_PERIOD_SUCCESS);
export const deletePeriodFailure = createAction(ActionTypes.DELETE_PERIOD_FAILURE, props<{ error: any }>());

// Setting Product Management Page
export const setDisplayDetailExtension = createAction(
    ActionTypes.SET_DISPLAY_DETAIL_EXTENSION,
    props<{ displayDetailExtension: boolean }>()
);

// Setting Product Form Tags
export const setProductFormCountries = createAction(
    ActionTypes.SET_PRODUCT_COUNTRIES,
    props<{ countries: ICountry[] }>()
);
export const setProductFormCountry = createAction(ActionTypes.SET_PRODUCT_COUNTRY, props<{ country: ICountry }>());
export const addProductFormTags = createAction(ActionTypes.ADD_PRODUCT_TAGS, props<{ tag: ITag }>());
export const removeProductFormTags = createAction(ActionTypes.REMOVE_PRODUCT_TAG, props<{ text: string }>());
export const resetProductForm = createAction(ActionTypes.RESET_PRODUCT_FORM);

export const setProductPageIsSubmitted = createAction(
    ActionTypes.SET_PRODUCT_PAGE_IS_SUBMITTED,
    props<{ isSubmitted: boolean }>()
);
export const setProductPageIsCanceled = createAction(
    ActionTypes.SET_PRODUCT_PAGE_IS_CANCELED,
    props<{ isCanceled: boolean }>()
);
