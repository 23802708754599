export class DateTimeHelpers {
    public static getDateRangeObjectForPipe(dateString1: string, dateString2: string): { date1: Date; date2: Date } {
        const date1: Date = new Date(dateString1);
        const date2: Date = new Date(dateString2);

        return { date1, date2 };
    }

    public static convertDateToString(date: Date): string {
        return `${new Intl.DateTimeFormat('en-US').format(date)}`;
    }

    public static convertDateToYMDString(date: Date): string {
        if (date) {
            const dateWithCorrectTimeZone = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
            return dateWithCorrectTimeZone.toISOString().substring(0, 10);
        }
        return null;
    }

    public static checkDatesAreEqual(date1: Date, date2: Date): boolean {
        if ((!date1 && date2) || (!date2 && date1)) return false;
        if (!date1 && !date2) return true;
        return date1.getTime() === date2.getTime();
    }

    public static convertStringToDate(dateString: string): Date {
        if (!dateString) {
            return null;
        }

        const date = dateString.split(' ');
        if (date.length === 1) {
            return new Date(dateString);
        }

        if (date.length === 2) {
            return new Date(date[0] + 'T' + date[1]);
        }

        return null;
    }
}
