<div class="outbound-product-summary general-text-size color-general-text">
    <div class="d-flex align-items-center">
        <div>
            <strong class="title-text-size color-general-text">ทัวร์ต่างประเทศ</strong>&nbsp;
            <span class="sub-title-text-size color-grey-text">มีประเทศ ดังนี้</span>&nbsp;
            <a
                class="sub-title-text-size color-blue-text"
                [routerLink]="['/product', 'outbound']"
                [queryParams]="{ loadImmediately: true }"
            >
                <u>ดูทั้งหมด</u>
            </a>
        </div>
        <div class="total-summary ml-auto d-flex">
            <div class="pr-2" style="border-right: solid 1px #eceef3">
                <strong class="big-text-size color-blue-text align-sub">
                    {{ productCount?.outboundPackage || '-' }}
                </strong>
                &nbsp;
                <span class="color-grey-text">Package Tour</span>
            </div>
            <div class="pl-2">
                <strong class="big-text-size color-blue-text align-sub">
                    {{ productCount?.outboundJoin || '-' }}
                </strong>
                &nbsp;
                <span class="color-grey-text">Join Tour</span>
            </div>
        </div>
    </div>

    <!-- has data  -->
    <table class="table">
        <ng-container *ngFor="let continent of continents">
            <tr *ngIf="continent.countries.length">
                <td class="country-cell" style="width: 120px">
                    <span>{{ continent.nameTH }}</span>
                </td>
                <td class="country-cell">
                    <span
                        style="cursor: pointer"
                        *ngFor="let country of continent.countries"
                        class="country-box mr-2"
                        [routerLink]="['/product', 'outbound']"
                        [queryParams]="{ countryId: country.id, loadImmediately: true }"
                    >
                        <img [src]="country.iconUrl" alt="country-flag" width="23" height="15" />
                        &nbsp;<span>{{ country.nameTH }}</span>
                    </span>
                </td>
            </tr>
        </ng-container>
    </table>

    <!-- data is not found -->
    <div class="data-not-found" *ngIf="continents !== null && countryCount === 0">
        <div class="data-not-found-box">
            <div>
                <img src="assets/icons/icon-not-found.png" width="110" height="110" />
            </div>
            <div class="mt-3">
                <strong class="secondary-title-text-size color-grey-text"> ไม่พบข้อมูลโปรแกรมทัวร์ </strong>
            </div>
        </div>
    </div>
</div>
