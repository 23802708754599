import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Region } from 'src/app/models/location.model';
import { ProductCount } from 'src/app/models/product/product-count.model';
import { getRegions } from 'src/app/stores/report-store/report.actions';
import { selectActiveProductsCount, selectRegions } from 'src/app/stores/report-store/report.selectors';

@Component({
    selector: 'app-domestic-product-summary',
    templateUrl: './domestic-product-summary.component.html',
    styleUrls: ['./domestic-product-summary.component.scss'],
})
export class DomesticProductSummaryComponent implements OnInit, OnDestroy {
    constructor(private store: Store) {}

    public productCount: ProductCount;
    public regions: Region[] = null;
    public provinceCount: number = null;

    private unsubscribe$ = new Subject<void>();

    ngOnInit(): void {
        this.store.dispatch(getRegions());

        this.store
            .select(selectActiveProductsCount)
            .pipe(
                filter((x) => x !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((count) => {
                this.productCount = count;
            });

        this.store
            .select(selectRegions)
            .pipe(
                filter((x) => x !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((regions) => {
                this.regions = regions;
                this.provinceCount = this.regions.reduce((prev, curr) => prev + curr.provinces.length, 0);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public reload(): void {
        this.store.dispatch(getRegions());
    }
}
