import { createAction, props } from '@ngrx/store';

import { Transportation } from 'src/app/models/transportation.model';
import { Region, Continent } from 'src/app/models/location.model';
import { Country } from 'src/app/models/country.model';
import { Province } from 'src/app/models/province.model';
import { CountrySubUnit } from 'src/app/models/country-sub-unit.model';

export enum ActionTypes {
    GET_TRANSPORTATIONS = '[Utility] Get transportations',
    GET_TRANSPORTATIONS_SUCCESS = '[Utility] Get transportations successfully',
    GET_TRANSPORTATIONS_FAILURE = '[Utility] Get transportations unsuccessfully',

    GET_REGIONS = '[Product] Get regions',
    GET_REGIONS_SUCCESS = '[Product] Get regions successfully',
    GET_REGIONS_FAILURE = '[Product] Get regions unsuccessfully',

    GET_PROVINCES = '[Product] Get provinces',
    GET_PROVINCES_SUCCESS = '[Product] Get provinces successfully',
    GET_PROVINCES_FAILURE = '[Product] Get provinces unsuccessfully',

    GET_CONTINENTS = '[Product] Get Continents',
    GET_CONTINENTS_SUCCESS = '[Product] Get Continents successfully',
    GET_CONTINENTS_FAILURE = '[Product] Get Continents unsuccessfully',

    GET_COUNTRIES = '[Product] Get Countries',
    GET_COUNTRIES_SUCCESS = '[Product] Get Countries successfully',
    GET_COUNTRIES_FAILURE = '[Product] Get Countries unsuccessfully',

    GET_COUNTRY_SUB_UNIT = '[Product] Get Country sub unit',
    GET_COUNTRY_SUB_UNIT_SUCCESS = '[Product] Get Country sub unit successfully',
    GET_COUNTRY_SUB_UNIT_FAILURE = '[Product] Get Country sub unit unsuccessfully'
}

// Get transportations.
export const getTransportations = createAction(ActionTypes.GET_TRANSPORTATIONS);
export const getTransportationsSuccess = createAction(
    ActionTypes.GET_TRANSPORTATIONS_SUCCESS,
    props<{ data: Transportation[] }>()
);
export const getTransportationsFailure = createAction(ActionTypes.GET_TRANSPORTATIONS_FAILURE, props<{ error: any }>());

// Get regions.
export const getRegions = createAction(ActionTypes.GET_REGIONS);
export const getRegionsSuccess = createAction(ActionTypes.GET_REGIONS_SUCCESS, props<{ regions: Region[] }>());
export const getRegionsFailure = createAction(ActionTypes.GET_REGIONS_FAILURE, props<{ error: any }>());

// Get provinces.
export const getProvinces = createAction(ActionTypes.GET_PROVINCES, props<{ countryId?: number }>());
export const getProvincesSuccess = createAction(ActionTypes.GET_PROVINCES_SUCCESS, props<{ provinces: Province[] }>());
export const getProvincesFailure = createAction(ActionTypes.GET_PROVINCES_FAILURE, props<{ error: any }>());

// Get Continents
export const getContinents = createAction(ActionTypes.GET_CONTINENTS);
export const getContinentsSuccess = createAction(
    ActionTypes.GET_CONTINENTS_SUCCESS,
    props<{ continents: Continent[] }>()
);
export const getContinentsFailure = createAction(ActionTypes.GET_CONTINENTS_FAILURE, props<{ error: any }>());

// Get Contries
export const getCountries = createAction(ActionTypes.GET_COUNTRIES);
export const getCountriesSuccess = createAction(ActionTypes.GET_COUNTRIES_SUCCESS, props<{ countries: Country[] }>());
export const getCountriesFailure = createAction(ActionTypes.GET_COUNTRIES_FAILURE, props<{ error: any }>());

// Get Country sub units
export const getCountrySubUnits = createAction(ActionTypes.GET_COUNTRY_SUB_UNIT);
export const getCountrySubUnitsSuccess = createAction(ActionTypes.GET_COUNTRY_SUB_UNIT_SUCCESS, props<{ countrySubUnits: CountrySubUnit[] }>());
export const getCountrySubUnitsFailure = createAction(ActionTypes.GET_COUNTRY_SUB_UNIT_FAILURE, props<{ error: any }>());