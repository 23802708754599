import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENDPOINTS } from '../constants/endpoint.constants';
import { IKeywordResponse } from '../models/interfaces/keyword-response.interface';
import { IKeyword } from '../models/interfaces/keyword.interface';
import { IResponse } from '../models/interfaces/response.interface';
import { HttpProService } from './base/http-pro.service';

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    constructor(private httpService: HttpProService) {}

    getTransportations(): Observable<any> {
        return this.httpService.get(ENDPOINTS.UTILITY.GET_TRANSPORTATIONS, true);
    }

    getKeywords(countryId: number, countrySubUnitId: number): Observable<IKeyword[]> {
        let param = this.httpService.getQueryString({
            filters: { country_id: countryId, country_sub_unit_id: countrySubUnitId },
        });

        return this.httpService.get(ENDPOINTS.KEYWORD.GET + '?' + param, true).pipe(
            map((response: IResponse<IKeywordResponse[]>) => {
                if (response.status === 'success') {
                    return response.data.map((keyword: IKeywordResponse) => {
                        return {
                            id: keyword.id,
                            displayName: keyword.display_name,
                            countryId: keyword.country_id,
                            countrySubUnitId: keyword.country_sub_unit_id,
                        };
                    });
                }

                return [];
            })
        );
    }
}
