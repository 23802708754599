export const ENDPOINTS: any = {
    PRODUCT: {
        GET_PRODUCTS_COUNT: '/products/count',
        GET_REGIONS_PROVINCES: '/regions/provinces',
        GET_CONTINENTS_CONTRIES: '/continents/countries',
        GET_PROVINCES: '/provinces',
        GET_COUNTRIES: '/countries',
        GET_COUNTRY_SUB_UNITS: '/country_sub_units',
        GET_MONTHS: '/products/months',
        GET_PRODUCTS: '/products',
        GET_PRODUTS_PAGINATED: '/products/paginated',
        GET_SINGLE_PRODUCT: '/products/{product_id}',
        POST_NEW_PRODUCT: '/products',
        POST_PRODUCT_IMAGE: '/products/images',
        POST_PRODUCT_TW_IMAGE: '/products/tw_images',
        UPDATE_PRODUCT: '/products/{product_id}',
        UPDATE_PRODUCT_KEYWORDS: '/products/{product_id}/keywords',
        UPDATE_TW_PRODUCT: '/products/{product_id}/tw_product',
        UPDATE_PRODUCT_DETAIL: '/products/{product_id}/detail',
        UPDATE_PRODUCT_NOTE: '/products/{product_id}/note',
        UPDATE_PRODUCT_TOURWOW_CONFIG: '/products/{product_id}/tourwow_config',
        UPDATE_PRODUCT_TAG: '/products/{product_id}/tags',
        DELETE_PRODUCT: '/products/{product_id}',

        POST_PRODUCT_ATTACHMENT: '/products/{product_id}/attachments',
        DELETE_PRODUCT_ATTACHMENT: '/products/{product_id}/attachments/{attachment_id}',

        PERIOD: {
            GET_LIST: '/products/periods',
            GET_PERIOD_DETAILS: '/products/{product_id}/periods/{period_id}',
            POST_NEW_PERIOD: '/products/{product_id}/periods',
            UPDATE_PERIOD: '/products/{product_id}/periods/{period_id}',
            VERIFY_PERIOD: '/products/{product_id}/periods/{period_id}/verification',
            DELETE_PERIOD: '/products/{product_id}/periods/{period_id}',
        },
    },
    UTILITY: {
        GET_TRANSPORTATIONS: '/transportations',
    },
    PRODUCT_ORDER: {
        GET_COUNT_PRODUCT_SUB_CATEGORY: '/products/orders/count/per_product_sub_category',
        GET_COUNT_ORDER_STATUS: '/products/orders/count/per_order_status',
        GET_ORDER_LIST_PAGINATED: '/products/orders/paginated',
    },
    OB_ORDER: {
        GET_COUNT_PER_STATUS: '/ob/orders/count/per_order_status',
    },
    AGCY_AGENCY: {
        GET_COUNT: '/agcy/agencies/count',
    },
    OB_AGENCY: {
        GET_COUNT_PER_STATUS: '/ob/agencies/count/per_status',
        CONTACT: {
            GET_COUNT_PER_STATUS: '/ob/agencies/contacts/count/per_status',
            GET_LIST: '/ob/agencies/contacts',
        },
    },
    KEYWORD: {
        GET: '/keywords',
        PAGINATED: '/keywords/paginated',
    },
};
