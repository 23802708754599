import { DateTimeHelpers } from 'src/app/helpers/date-time.helpers';

export class ProductActionLog {
    public entity: ProductActionEntity;
    public type: ProductActionType;
    public periodStartAt?: Date;
    public periodEndAt?: Date;
    public attachmentFileName?: string;
    public loggedAt: Date;
    public byUser: ProductActionLogUser;

    public deserialize(data: any) {
        this.entity = data.entity_type_slug;
        this.type = data.action_type_slug;
        this.periodStartAt = DateTimeHelpers.convertStringToDate(data.period_start_at);
        this.periodEndAt = DateTimeHelpers.convertStringToDate(data.period_end_at);
        this.attachmentFileName = data.attachment_file_name;
        this.loggedAt = DateTimeHelpers.convertStringToDate(data.logged_at);
        this.byUser = data.by_user ? new ProductActionLogUser().deserialize(data.by_user) : null;

        return this;
    }
}

export class ProductActionLogUser {
    public id: number;
    public firstName: string;
    public lastName: string;
    public nickName: string;

    public deserialize(data: any) {
        this.id = data.id;
        this.firstName = data.first_name;
        this.lastName = data.last_name;
        this.nickName = data.nick_name;

        return this;
    }
}

export enum ProductActionEntity {
    Product = 'product',
    ProductDetail = 'product_detail',
    ProductPeriod = 'product_period',
    ProductNote = 'product_note',
    ProductAttachment = 'product_attachment',
}

export enum ProductActionType {
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
}
