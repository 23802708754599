export class ObAgencyCount {
    public verified: number;
    public unverified: number;

    public deserialize(data: any): this {
        this.verified = data.verified;
        this.unverified = data.unverified;
        return this;
    }
}
