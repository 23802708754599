export interface IProductAttachmentFile {
    fileId: number;
    fileUrl: string;
    fileName: string;
    isMain: boolean;

    deserialise(item: any): ProductAttachmentFile;
}

export class ProductAttachmentFile implements IProductAttachmentFile {
    public fileId: number;
    public fileUrl: string;
    public fileName: string;
    public isMain: boolean;

    public deserialise(item: any): ProductAttachmentFile {
        this.fileId = item.file_id;
        this.fileUrl = item.file_url;
        this.fileName = item.file_name;
        return this;
    }
}
