<app-sidenav></app-sidenav>
<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<app-footer></app-footer>

<ngx-ui-loader [fgsTemplate]="foregroundSpinner"></ngx-ui-loader>
<ng-template #foregroundSpinner>
    <app-loading-icon></app-loading-icon>
</ng-template>
