import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Continent } from 'src/app/models/location.model';
import { ProductCount } from 'src/app/models/product/product-count.model';
import { getContinents } from 'src/app/stores/report-store/report.actions';
import { selectActiveProductsCount, selectContinents } from 'src/app/stores/report-store/report.selectors';

@Component({
    selector: 'app-outbound-product-summary',
    templateUrl: './outbound-product-summary.component.html',
    styleUrls: ['./outbound-product-summary.component.scss'],
})
export class OutboundProductSummaryComponent implements OnInit, OnDestroy {
    constructor(private store: Store) {}

    public productCount: ProductCount;
    public continents: Continent[] = null;
    public countryCount: number = null;

    private unsubscribe$ = new Subject<void>();

    ngOnInit(): void {
        this.store.dispatch(getContinents());

        this.store
            .select(selectActiveProductsCount)
            .pipe(
                filter((x) => x !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((count) => {
                this.productCount = count;
            });

        this.store
            .select(selectContinents)
            .pipe(
                filter((x) => x !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((continents) => {
                this.continents = continents;
                this.countryCount = this.continents.reduce((prev, curr) => prev + curr.countries.length, 0);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public reload(): void {
        this.store.dispatch(getContinents());
    }
}
