import { Component, OnInit, EventEmitter, Output, Input, Inject, PLATFORM_ID } from '@angular/core';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { isPlatformBrowser } from '@angular/common';
import { Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import { NgxUiLoaderService } from 'ngx-ui-loader';

// https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/angular.html
// import Editor from 'src/assets/scripts/ckeditor';
declare let ClassicEditor: any;

@Component({
    selector: 'app-rich-text-input',
    templateUrl: './rich-text-input.component.html',
    styleUrls: ['./rich-text-input.component.scss'],
})
export class RichTextInputComponent implements OnInit {
    @Input() placeholder: string;
    @Input() value: string = null;
    @Input() optionalFields?: RichTextInputOptinalFields;
    @Output() valueChange = new EventEmitter<string>();

    public Editor: any;
    public isBrowser = false;
    public toolbar: string[];
    public config: any;

    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        private store: Store<State>,
        private ngxService: NgxUiLoaderService
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        if (this.isBrowser) {
            this.Editor = ClassicEditor;
        }
    }

    ngOnInit() {
        this.setToolbar();
        this.setConfig();
    }

    private setConfig(): void {
        this.config = {
            toolbar: this.toolbar,
            placeholder: this.placeholder,
            fillEmptyBlocks: true,
            // image: {
            //     styles: ['alignLeft', 'alignCenter', 'alignRight'],
            //     toolbar: [
            //         'imageStyle:alignLeft',
            //         'imageStyle:alignCenter',
            //         'imageStyle:alignRight',
            //         '|',
            //         'imageTextAlternative',
            //         'linkImage',
            //     ],
            // },
            // table: {
            //     contentToolbar: [
            //         'tableColumn',
            //         'tableRow',
            //         'mergeTableCells',
            //         'tableProperties',
            //         'tableCellProperties',
            //     ],
            // },
            // mediaEmbed: {
            //     previewsInData: true,
            //     removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook'],
            // },
            // link: {
            //     addTargetToExternalLinks: true,
            // },
        };
    }

    public onChange({ editor }: ChangeEvent) {
        if (editor) {
            const data = editor.getData();
            this.valueChange.emit(data);
        }
    }

    private setToolbar(): void {
        const toolbar = ['bold', 'italic'];

        if (this.optionalFields) {
            if (this.optionalFields.underline) {
                toolbar.push('Underline');
            }
            if (this.optionalFields.fontSize) {
                toolbar.push('FontSize');
            }
            if (this.optionalFields.textColour) {
                toolbar.push('FontColor');
            }
            if (this.optionalFields.imageSettings) {
                toolbar.push('|', 'imageUpload');
            }
            if (this.optionalFields.link) {
                toolbar.push('link');
            }
            if (this.optionalFields.mediaEmbed) {
                toolbar.push('mediaEmbed');
            }
        }

        toolbar.push(
            // 'insertTable',
            '|',
            'NumberedList',
            'BulletedList'
            // '|',
            // 'alignment:left',
            // 'alignment:right',
            // 'alignment:center',
            // 'alignment:justify'
        );
        this.toolbar = toolbar;
    }
}

export class RichTextInputOptinalFields {
    underline?: boolean;
    fontSize?: boolean;
    textColour?: boolean;
    imageSettings?: {
        galleryId: number;
    };
    link?: boolean;
    mediaEmbed?: boolean;
}
