import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { updateProfile } from 'src/app/stores/wholesale-store/wholesale.actions';

import { IWholesale } from 'src/app/models/wholesale.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private jwtToken = 'jwt_token';
    private isLoggedIn = false;

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private store: Store) {}

    public saveJWTToken(token: string): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(this.jwtToken, token);
        }
    }

    public getJWTToken(): string {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem(this.jwtToken);
        }
        return null;
    }

    public deleteJWTToken(): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem(this.jwtToken);
            localStorage.removeItem('supplier-slug');
        }

        this.isLoggedIn = false;
    }

    public fetchSupplierDataFromToken(): void {
        const token = this.getJWTToken();
        if (!token) {
            return;
        }

        const data = JSON.parse(atob(token.split('.')[1]));
        const wholesale: IWholesale = {
            id: +data.user.id,
            firstName: data.user.first_name,
            lastName: data.user.last_name,
            nickName: data.user.nick_name,
            email: data.user.email,
            rolesSlug: data.user.roles_slug,
            supplier: {
                id: +data.supplier.id,
                isChannelOB: data.supplier.is_channel_ob,
                nameEN: null,
                nameTH: data.supplier.name_th,
                productTwpSlug: data.supplier.product_twp_slug,
            },
        };

        this.store.dispatch(updateProfile({ data: wholesale }));
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('supplier-slug', data.supplier.product_twp_slug);
        }

        this.isLoggedIn = true;
    }

    public isLoggetIn(): boolean {
        const token = this.getJWTToken();
        if (token) {
            return true;
        }
        return false;
    }

    public logout(): void {
        this.deleteJWTToken();
        location.href = environment.tourwowProBackofficeUrl + 'logout';
    }
}
