import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { setPrefixUrl } from './helpers/url-utility.helpers';
import { SharedModule } from './modules/shared/shared.module';

// Stores
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers, metaReducers } from './stores/reducers';
import { ProductStoreModule } from './stores/product-store/product-store.module';
import { WholesaleStoreModule } from './stores/wholesale-store/wholesale-store.module';
import { UtilityStoreModule } from './stores/utility-store/utility-store.module';
import { OrderStoreModule } from './stores/order-store/order-store.module';
import { ReportStoreModule } from './stores/report-store/report-store.module';

// Components
import { AppComponent } from './app.component';

// Partial Components
import { MainComponent } from './themes/main/main.component';
import { FooterComponent } from './themes/partials/footer/footer.component';
import { HeaderComponent } from './themes/partials/header/header.component';
import { SidenavComponent } from './themes/partials/sidenav/sidenav.component';
import { LoadingIconComponent } from './themes/partials/loading-icon/loading-icon.component';

import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';

// Constants
import { NGX_LOADER_CONFIG } from './constants/ngx-loader-config.contant';

// Services
import { WholesaleService } from './services/wholesale.service';

// Error Pages
import { PageNotFoundComponent } from './themes/error/page-not-found/page-not-found.component';

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        FooterComponent,
        SidenavComponent,
        LoadingIconComponent,

        // Error Pages
        PageNotFoundComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        HttpClientModule,
        EffectsModule.forRoot(),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        ProductStoreModule,
        OrderStoreModule,
        WholesaleStoreModule,
        ReportStoreModule,
        UtilityStoreModule,
        NgxUiLoaderModule.forRoot(NGX_LOADER_CONFIG),
        BrowserAnimationsModule,
        SharedModule,
        !environment.production ? StoreDevtoolsModule.instrument() : [],
    ],
    providers: [{ provide: APP_BASE_HREF, useFactory: setPrefixUrl, deps: [WholesaleService] }],
    bootstrap: [AppComponent],
})
export class AppModule {}
