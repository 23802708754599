<div class="input-tag" (click)="focusInputTag()">
    <div class="tags">
        <ul>
            <li
                *ngFor="let tag of tags"
                [ngClass]="{
                    disabled: tag.isCountry || tag.isSubUnit,
                    old: tag.type === 'old',
                    new: tag.type === 'new',
                    keyword: tag.type === 'keyword'
                }"
            >
                {{ tag.text }}
                <span (click)="removeTag(tag)">
                    <i class="icon icon-close-circle" [ngClass]="{ 'custom-tag': tag.isCustomTag }"></i
                ></span>
            </li>
            <li class="input" [ngClass]="{ hide: hidingInputBox }">
                <input
                    #inputTag
                    type="text"
                    placeholder="{{ placeholder }}"
                    [(ngModel)]="tag"
                    (keyup.enter)="onEnter($event)"
                />
            </li>
        </ul>
    </div>
</div>
