import { Injectable } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { NgxUiLoaderService } from 'ngx-ui-loader';

// Models

@Injectable()
export class WholesaleEffects {
    constructor(private actions$: Actions, private ngxService: NgxUiLoaderService) {}
}
