import { environment } from 'src/environments/environment';

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash-es';
import * as dayjs from 'dayjs';

@Pipe({
    name: 'thaiDatePipe',
})
export class ThaiDatePipe implements PipeTransform {
    transform(
        value: Date,
        useAbbr: boolean = false,
        hideDay: boolean = false,
        hideTime: boolean = true,
        hideSecond: boolean = false
    ): string {
        if (!value) {
            return '';
        }
        if (typeof value === 'string') {
            value = dayjs(value).toDate();
        }
        const options: Intl.DateTimeFormatOptions = {
            year: '2-digit',
            month: 'numeric',
            day: 'numeric',
        };

        if (useAbbr) {
            options.month = 'short';
        }

        if (hideDay) {
            delete options.day;
        }

        if (!hideTime) {
            options.hour = 'numeric';
            options.minute = 'numeric';

            if (!hideSecond) {
                options.second = 'numeric';
            }
        }

        return `${new Intl.DateTimeFormat(environment.dateLanguage, options).format(value)}`;
    }
}

// Refactor when this becomes available
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/formatRange
@Pipe({
    name: 'thaiDateRangePipe',
})
export class ThaiDateRangePipe implements PipeTransform {
    transform(value: { date1: Date; date2: Date }, useAbbr: boolean = false): string {
        if (!value || !value.date1 || !value.date2) {
            return '';
        }

        const options: Intl.DateTimeFormatOptions = { year: '2-digit', month: 'numeric', day: 'numeric' };

        if (typeof value.date1 === 'string') {
            value.date1 = dayjs(value.date1).toDate();
        }

        if (typeof value.date2 === 'string') {
            value.date2 = dayjs(value.date2).toDate();
        }

        if (useAbbr) {
            options.month = 'short';
        }

        const date1: { type: string; value: string }[] = new Intl.DateTimeFormat(
            environment.dateLanguage,
            options
        ).formatToParts(value.date1);
        const date2: { type: string; value: string }[] = new Intl.DateTimeFormat(
            environment.dateLanguage,
            options
        ).formatToParts(value.date2);

        let dateRange: string = _.find(date1, ['type', 'day']).value;

        const date1Month = _.find(date1, ['type', 'month']).value;
        const date2Month = _.find(date2, ['type', 'month']).value;

        if (date1Month !== date2Month) {
            dateRange += ' ' + date1Month;
        }

        const date1Year = _.find(date1, ['type', 'year']).value;
        const date2Year = _.find(date2, ['type', 'year']).value;

        if (date1Year !== date2Year) {
            dateRange += ' ' + date1Year;
        }

        dateRange += ' - ' + new Intl.DateTimeFormat(environment.dateLanguage, options).format(value.date2);

        return dateRange;
    }

    private isValidDate(dateString): boolean {
        const regEx = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateString.match(regEx)) return false; // Invalid format
        const d = new Date(dateString);
        const dNum = d.getTime();
        if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
        return d.toISOString().slice(0, 10) === dateString;
    }
}

// Refactor when this becomes available
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/formatRange
@Pipe({
    name: 'thaiMonthRangePipe',
})
export class ThaiMonthRangePipe implements PipeTransform {
    transform(value: { date1: Date; date2: Date }, useAbbr: boolean = false): string {
        if (!value || !value.date1 || !value.date2) {
            return '';
        }
        const options: Intl.DateTimeFormatOptions = { year: '2-digit', month: 'numeric' };

        if (useAbbr) {
            options.month = 'short';
        }

        const date1: { type: string; value: string }[] = new Intl.DateTimeFormat(
            environment.dateLanguage,
            options
        ).formatToParts(value.date1);
        const date2: { type: string; value: string }[] = new Intl.DateTimeFormat(
            environment.dateLanguage,
            options
        ).formatToParts(value.date2);

        const date1Month = _.find(date1, ['type', 'month']).value;
        const date2Month = _.find(date2, ['type', 'month']).value;

        const date1Year = _.find(date1, ['type', 'year']).value;
        const date2Year = _.find(date2, ['type', 'year']).value;

        if (date1Month === date2Month && date1Year === date2Year) {
            return date2Month + ' ' + date2Year;
        } else if (date1Month !== date2Month && date1Year === date2Year) {
            return date1Month + ' - ' + date2Month + ' ' + date2Year;
        } else {
            return date1Month + ' ' + date1Year + ' - ' + date2Month + ' ' + date2Year;
        }
    }
}
