import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class WholesaleService {
    constructor(private localStorageService: LocalStorageService) {}

    public getSupplierSlug(): string {
        return this.localStorageService.get('supplier-slug');
    }
}
