export class FirstPricePeriod {
    public id: number;
    public priceAdultDouble: number;

    public deserialise(first_price_period: any): this {
        if (first_price_period) {
            this.id = first_price_period.id;
            this.priceAdultDouble = first_price_period.price_adult_double;
            return this;
        }
        return null;
    }
}