import { Pipe, PipeTransform } from '@angular/core';
import { TransportTypeId, TransportTypeName } from '../constants/transport-types.constant';
import { CategoryTransport } from '../models/category-transport.model';
import { Transportation } from '../models/transportation.model';

@Pipe({
    name: 'transportCategoryLabel',
})
export class TransportCategoryLabelPipe implements PipeTransform {
    transform(value: CategoryTransport): string {
        if (!value) {
            value = new CategoryTransport();
        }
        switch (value.id) {
            case TransportTypeId.Plane:
                return TransportTypeName.Plane;
            case TransportTypeId.Train:
                return TransportTypeName.Train;
            case TransportTypeId.Car:
                return TransportTypeName.Car;
            case TransportTypeId.Bus:
                return TransportTypeName.Bus;
            case TransportTypeId.Van:
                return TransportTypeName.Van;
            case TransportTypeId.Boat:
                return TransportTypeName.Boat;
            default:
                return '';
        }
    }
}

@Pipe({
    name: 'transportLabel',
})
export class TransportLabelPipe implements PipeTransform {
    transform(value: Transportation): string {
        if (value) {
            let result = `${value.nameEN}`;
            if (value.code) {
                result += ` (${value.code})`;
            }
            return result;
        }
        return '';
    }
}
