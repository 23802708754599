import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IBreadcrumb } from 'src/app/models/breadcrumb.model';
import { IWholesale } from 'src/app/models/wholesale.model';
import { SuccessModalComponent } from 'src/app/modules/shared/modals/success-modal/success-modal.component';
import { getActiveProductCount } from 'src/app/stores/report-store/report.actions';
import { selectProfile } from 'src/app/stores/wholesale-store/wholesale.selectors';
import { environment } from 'src/environments/environment';
import { AgencyContactSummaryComponent } from '../../components/agency-contact-summary/agency-contact-summary.component';
import { DomesticProductSummaryComponent } from '../../components/domestic-product-summary/domestic-product-summary.component';
import { OrderSummaryComponent } from '../../components/order-summary/order-summary.component';
import { OutboundProductSummaryComponent } from '../../components/outbound-product-summary/outbound-product-summary.component';
import { ProductPeriodSummaryComponent } from '../../components/product-period-summary/product-period-summary.component';

import { ActionTypes } from 'src/app/stores/report-store/report.actions';
import { ofType } from '@ngrx/effects';
@Component({
    selector: 'app-dashboard-page',
    templateUrl: './dashboard-page.component.html',
    styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent implements OnInit, OnDestroy {
    @ViewChild(OutboundProductSummaryComponent)
    private outboundProductSummaryComponent: OutboundProductSummaryComponent;

    @ViewChild(DomesticProductSummaryComponent)
    private domesticProductSummaryComponent: DomesticProductSummaryComponent;

    @ViewChild(ProductPeriodSummaryComponent)
    private productPeriodSummaryComponent: ProductPeriodSummaryComponent;

    @ViewChild(OrderSummaryComponent)
    private orderSummaryComponent: OrderSummaryComponent;

    @ViewChild(AgencyContactSummaryComponent)
    private AgencyContactSummaryComponent: AgencyContactSummaryComponent;

    public breadcrumbs: IBreadcrumb[] = [
        { name: 'หน้าแรก', link: null },
        { name: 'Dashboard', link: null },
    ];

    public timeWhenLoaded: Date;
    public profile: IWholesale;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private store: Store,
        private modalService: BsModalService,
        private actionsSubj: ActionsSubject,
        private ngxService: NgxUiLoaderService
    ) {}

    ngOnInit(): void {
        this.ngxService.start();
        this.timeWhenLoaded = new Date();

        this.store
            .select(selectProfile)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((profile) => {
                this.profile = profile;
            });

        this.store.dispatch(getActiveProductCount());

        // Check subscribe action all loading success
        this.actionsSubj
            .pipe(
                ofType(
                    ActionTypes.GET_ACTIVE_PRODUCTS_COUNT_SUCCESS &&
                        ActionTypes.GET_CONTINENTS_SUCCESS &&
                        ActionTypes.GET_REGIONS_SUCCESS &&
                        ActionTypes.GET_PRODUCT_PERIODS_SUCCESS &&
                        ActionTypes.GET_OB_ORDER_COUNT_PER_STATUS_SUCCESS &&
                        ActionTypes.GET_AGCY_AGENCY_COUNT_SUCCESS &&
                        ActionTypes.GET_OB_AGENCY_CONTACT_COUNT_SUCCESS &&
                        ActionTypes.GET_OB_AGENCY_CONTACTS_SUCCESS
                ),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.ngxService.stop();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public copyOnlineBookingLink(): void {
        const url = environment.bookingUrl.replace('{supplier_slug}', this.profile.supplier.productTwpSlug) + '/sale';
        navigator.clipboard.writeText(url).then(() => {
            this.modalService.show(SuccessModalComponent, {
                initialState: {
                    message: 'Copy Link เรียบร้อยแล้ว',
                    buttonText: 'ปิด',
                    hasCloseButton: true,
                },
            });
        });
    }

    public reload(): void {
        this.timeWhenLoaded = new Date();
        this.store.dispatch(getActiveProductCount());

        this.outboundProductSummaryComponent.reload();
        this.domesticProductSummaryComponent.reload();
        this.productPeriodSummaryComponent.reload();
        this.orderSummaryComponent.reload();
        this.AgencyContactSummaryComponent.reload();
    }
}
