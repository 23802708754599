import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appUnallowedSpecialCharacters]',
})
export class UnallowedSpecialCharactersDirective {
    constructor(private el: ElementRef) {}

    @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
        let isKeyAllowed = true;
        if (e.key.length == 1) {
            if (e.metaKey || e.ctrlKey) {
                /** detecting cmd or ctrl */
                isKeyAllowed = true;
            } else if (new RegExp('^[A-Za-z0-9ก-๙เ-]+$').test(e.key) === false) {
                isKeyAllowed = false;
            }
        }
        if (!isKeyAllowed) {
            e.preventDefault();
        }
    }

    @HostListener('paste', ['$event']) onPaste(e: ClipboardEvent) {
        const text = e.clipboardData.getData('text');
        if (new RegExp('^[A-Za-z0-9ก-๙เ-]+$').test(text) === false) {
            e.preventDefault();
        }
    }
}
