import { Product } from './product.model';

export class ProductList {
    public page: number;
    public pageEntries: number;
    public total: number;
    public products: Product[];

    constructor(params?: Partial<ProductList>) {
        this.page = params?.page;
        this.pageEntries = params?.pageEntries;
        this.total = params?.total;
        this.products = params?.products;
    }

    public deserialize(data): ProductList {
        this.page = data.page;
        this.pageEntries = data.page_entries;
        this.total = data.total;
        this.products = data.result.map((product) => new Product().deserialize(product));
        return this;
    }
}
