export class Period {
    public id: number;
    public startAt: Date;
    public endAt: Date;
    public quantityRemaining: number;

    public deserialize(item: any): this {
        this.id = item.id;
        this.startAt = new Date(item.start_at + ' 00:00:00');
        this.endAt = new Date(item.end_at + ' 00:00:00');
        this.quantityRemaining = item.quantity_remaining;

        return this;
    }
}
