import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

// State
import { Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import { selectProfile } from 'src/app/stores/wholesale-store/wholesale.selectors';

// Models
import { IWholesale } from 'src/app/models/wholesale.model';

// Services
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

import { environment, Feature_TOGGLE } from 'src/environments/environment';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
    public profile$: Observable<IWholesale>;

    public salesManagementCollapse: boolean = true;
    public accountManagementCollapse: boolean = true;
    public agencyManagementCollapse: boolean = true;
    public downloadManagementCollapse: boolean = true;

    public showTwmOrderMenu: boolean = Feature_TOGGLE.side_menu_twm_order;

    public appVersion = environment.appVersion;
    private supplierSlug: string;

    constructor(private store: Store<State>, private authService: AuthenticationService) {}

    ngOnInit(): void {
        this.profile$ = this.store.select(selectProfile);
        this.profile$.subscribe((profile) => (this.supplierSlug = profile.supplier.productTwpSlug));
    }

    public redirectToV1(url: string): string {
        return environment.tourwowProBackofficeUrl + this.supplierSlug + '/' + url;
    }

    public onToggleSubmenu(subMenu: string): void {
        switch (subMenu) {
            case 'sales':
                this.salesManagementCollapse = !this.salesManagementCollapse;
                this.accountManagementCollapse = true;
                this.agencyManagementCollapse = true;
                this.downloadManagementCollapse = true;
                break;

            case 'account':
                this.salesManagementCollapse = true;
                this.accountManagementCollapse = !this.accountManagementCollapse;
                this.agencyManagementCollapse = true;
                this.downloadManagementCollapse = true;
                break;

            case 'agency':
                this.salesManagementCollapse = true;
                this.accountManagementCollapse = true;
                this.agencyManagementCollapse = !this.agencyManagementCollapse;
                this.downloadManagementCollapse = true;
                break;
            case 'download':
                this.salesManagementCollapse = true;
                this.accountManagementCollapse = true;
                this.agencyManagementCollapse = true;
                this.downloadManagementCollapse = !this.downloadManagementCollapse;
                break;
        }
    }

    public logout(): void {
        this.authService.logout();
    }
}
