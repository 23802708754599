import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { HttpProService } from './base/http-pro.service';

import { environment } from 'src/environments/environment';

import { Observable, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ENDPOINTS } from '../constants/endpoint.constants';

// Models
import { BasePaginatedQueries } from '../models/base-queries.model';
import { ProductPaginatedQueries } from '../models/product/product-paginated-queries.model';
import { INewProgram, IUpdateProgram } from '../models/product/product.model';

import * as _ from 'lodash-es';
import { IResponse } from '../models/interfaces/response.interface';
import { ITagPayload } from '../models/interfaces/tag-payload.interface';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    constructor(private httpService: HttpProService, private http: HttpClient) {}

    getProductsCount(filters: { productIsActive: boolean } = null): Observable<any> {
        const url = ENDPOINTS.PRODUCT.GET_PRODUCTS_COUNT;
        let params = new HttpParams();
        if (filters) {
            if (filters.productIsActive !== null) {
                params = params.set('product_is_active', filters.productIsActive);
            }
        }
        return this.httpService.get(url + '?' + params.toString(), true);
    }

    getRegions(filters: { provinceHasActiveProduct: boolean } = null): Observable<any> {
        const url = ENDPOINTS.PRODUCT.GET_REGIONS_PROVINCES;
        let params = new HttpParams();
        if (filters) {
            if (filters.provinceHasActiveProduct !== null) {
                params = params.set('province_has_active_product', filters.provinceHasActiveProduct);
            }
        }
        return this.httpService.get(url + '?' + params.toString(), true);
    }

    getContinents(filters: { countryHasActiveProduct: boolean } = null): Observable<any> {
        const url = ENDPOINTS.PRODUCT.GET_CONTINENTS_CONTRIES;
        let params = new HttpParams();
        if (filters) {
            if (filters.countryHasActiveProduct !== null) {
                params = params.set('country_has_active_product', filters.countryHasActiveProduct);
            }
        }
        return this.httpService.get(url + '?' + params.toString(), true);
    }

    getProvinces(countryId: number = 1): Observable<any> {
        const filters = this.httpService.getQueryString({ filters: { country_id: countryId } });
        return this.httpService.get(ENDPOINTS.PRODUCT.GET_PROVINCES + '?' + filters, true);
    }

    getCountries(): Observable<any> {
        return this.httpService.get(ENDPOINTS.PRODUCT.GET_COUNTRIES, true);
    }

    getCountrySubUnits(countryId: number | undefined = undefined): Observable<any> {
        let params = new HttpParams();
        if (countryId) {
            params = params.append(
                'filters',
                JSON.stringify({
                    country_id: countryId,
                    is_active: true,
                })
            );
        }

        const url: string = ENDPOINTS.PRODUCT.GET_COUNTRY_SUB_UNITS + '?' + params.toString();
        return this.httpService.get(url, true);
    }

    getProductMonths(productCatgoryIds: number[]): Observable<any> {
        let params = new HttpParams();
        params = params.append(
            'filters',
            JSON.stringify({
                category_sub_product_ids: productCatgoryIds,
            })
        );
        const url: string = ENDPOINTS.PRODUCT.GET_MONTHS + '?' + params.toString();
        return this.httpService.get(url, true);
    }

    getProductsFilter(filters?: ProductPaginatedQueries): Observable<any> {
        let url = ENDPOINTS.PRODUCT.GET_PRODUCTS;
        if (filters) {
            let params = new HttpParams();
            const clonedFilters = _.cloneDeep(filters);
            clonedFilters.product_ids = [];
            params = params.append('filters', JSON.stringify(clonedFilters));
            url += '?' + params.toString();
        }
        return this.httpService.get(url, true);
    }

    getProductListPaginated(queries: BasePaginatedQueries<ProductPaginatedQueries>): Observable<any> {
        let params = new HttpParams();
        if (queries) {
            if (queries.filters) {
                params = params.append('filters', JSON.stringify(queries.filters));
            }
            params = params.append('page', '' + queries.page);
            params = params.append('page_entries', '' + queries.page_entries);
            params = params.append('sort_by', '' + queries.sort_by);
        }
        params.toString();
        return this.httpService.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };
                return this.http.get(environment.apiProWsUrl + ENDPOINTS.PRODUCT.GET_PRODUTS_PAGINATED, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    getSingleProduct(productId: number): Observable<any> {
        const url: string = ENDPOINTS.PRODUCT.GET_SINGLE_PRODUCT.replace('{product_id}', productId);
        return this.httpService.get(url, true);
    }

    postNewProduct(product: INewProgram): Observable<any> {
        return this.httpService.post(ENDPOINTS.PRODUCT.POST_NEW_PRODUCT, product, true);
    }

    uploadBanner(formData: FormData): Observable<any> {
        const url = ENDPOINTS.PRODUCT.POST_PRODUCT_IMAGE;
        return this.httpService.postWithFormData(url, formData, true);
    }

    updateProduct(productId: number, product: IUpdateProgram): Observable<any> {
        const url: string = ENDPOINTS.PRODUCT.UPDATE_PRODUCT.replace('{product_id}', productId);
        return this.httpService.put(url, product, true);
    }

    updateProductDetail(productId: number, productDetail: any): Observable<any> {
        const url: string = ENDPOINTS.PRODUCT.UPDATE_PRODUCT_DETAIL.replace('{product_id}', productId);
        return this.httpService.put(url, productDetail, true);
    }

    deleteProduct(productId: number): Observable<any> {
        const url: string = ENDPOINTS.PRODUCT.DELETE_PRODUCT.replace('{product_id}', productId);
        return this.httpService.delete(url, true);
    }

    updateProductNote(productId: number, data: string): Observable<any> {
        const url: string = ENDPOINTS.PRODUCT.UPDATE_PRODUCT_NOTE.replace('{product_id}', productId);
        return this.httpService.put(url, { note: data }, true);
    }

    uploadAttachment(productId: number, formData: FormData): Observable<any> {
        const url = ENDPOINTS.PRODUCT.POST_PRODUCT_ATTACHMENT.replace('{product_id}', productId);
        return this.httpService.postWithFormData(url, formData, true);
    }

    deleteAttachment(productId: number, attachmentId: number): Observable<any> {
        const url = ENDPOINTS.PRODUCT.DELETE_PRODUCT_ATTACHMENT.replace('{product_id}', productId).replace(
            '{attachment_id}',
            attachmentId
        );
        return this.httpService.delete(url, true);
    }

    updateProductTourwowConfig(productId: number, data: string): Observable<any> {
        const url = ENDPOINTS.PRODUCT.UPDATE_PRODUCT_TOURWOW_CONFIG.replace('{product_id}', productId);
        return this.httpService.put(url, { tourwow_slug: data }, true);
    }

    updateProductTags(productId: number, payload: ITagPayload[]): Observable<boolean> {
        const url = ENDPOINTS.PRODUCT.UPDATE_PRODUCT_TAG.replace('{product_id}', productId);
        return this.httpService.put(url, { tags: payload }, true);
    }
}
