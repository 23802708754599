export class CategoryTransport {
    id: number;
    slug: string;

    public deserialize(item: any) {
        if (item) {
            this.id = item.id;
            this.slug = item.slug;
            return this;
        }
        return null;
    }
}
