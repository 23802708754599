export interface ITag {
    key: string;
    isCustomTag: boolean;
    text: string;
    relateCountryId?: number;
    relateProvinceId?: number;
    isCountry: boolean;
    isSubUnit: boolean;

    serialize(): any;
    deserialise(item: any): Tag;
}

export class Tag implements ITag {
    public key: string;
    public isCustomTag: boolean;
    public text: string;
    public relateCountryId?: number;
    public relateProvinceId?: number;
    public isCountry: boolean = false;
    public isSubUnit: boolean = false;
    public type?: 'old' | 'keyword' | 'new' = 'old';

    public serialize(): any {
        return {
            text: this.text,
            related_country_id: this.relateCountryId,
            is_country: this.isCountry,
        };
    }

    public deserialise(item: any): Tag {
        this.key = item.key;
        this.text = item.text;
        this.relateCountryId = item.related_country_id;
        this.isCountry = item.is_country;
        return this;
    }
}

export class TagChecker implements ITag {
    public key: string;
    public isCustomTag: boolean;
    public text: string;
    public relateCountryId?: number;
    public isCountry: boolean;
    public isSubUnit: boolean;

    public isCheck: boolean = false;

    public serialize(): any {
        return {
            text: this.text,
            related_country_id: this.relateCountryId,
            is_country: this.isCountry,
        };
    }

    public deserialise(item: any): Tag {
        this.text = item.text;
        this.relateCountryId = item.related_country_id;
        this.isCountry = item.is_country;
        return this;
    }
}
