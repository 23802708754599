import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-confirm-delete-tag-modal',
    templateUrl: './confirm-delete-tag-modal.component.html',
    styleUrls: ['./confirm-delete-tag-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmDeleteTagModalComponent implements OnInit {
    public title: string;
    public areYouSureText: string;
    public itemTitle: string;

    @Output() confirmed = new EventEmitter<any>();
    @Output() cancelled = new EventEmitter<any>();

    constructor(public bsModalRef: BsModalRef, private router: Router) {}

    ngOnInit(): void {
        this.closeOnRouteChange();
    }

    public confirmButton(): void {
        this.confirmed.emit();
        this.bsModalRef.hide();
    }

    public cancelButton(): void {
        this.cancelled.emit();
        this.bsModalRef.hide();
    }

    private closeOnRouteChange(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.bsModalRef.hide();
        });
    }
}
