<div class="online-booking-member">
    <div class="d-flex align-items-center mb-3">
        <div>
            <img class="mr-2" src="assets/icons/icon-member-card.png" width="30" />
            <strong style="vertical-align: middle" class="title-text-size color-general-text">
                Online Booking Member
            </strong>
        </div>
    </div>

    <!-- has data -->
    <div class="agency-box">
        <div class="agency-box-title">
            <div>
                <span class="sub-title-text-size color-grey-text">
                    รออนุมัติ <strong>{{ obAgencyCount?.unverified }}</strong>
                    Agency และอนุมัติแล้ว
                    <strong>{{ obAgencyCount?.verified }}</strong>
                    Agency
                </span>
            </div>
            <div class="ml-auto">
                <span class="sub-title-text-size color-grey-text">
                    จาก Tourwow <strong>{{ agcyAgencyCount }}</strong>
                    Agency
                </span>
                <img src="assets/images/logoTourwowMall.svg" width="40" />
            </div>
        </div>
        <table id="agency-box-table" class="table small-text-size">
            <thead class="agency-box-header">
                <th style="width: 5%">&nbsp;</th>
                <th style="width: 15%">
                    วันที่สมัคร <br />
                    Online Booking
                </th>
                <th style="width: 30%">ข้อมูลสมาชิก</th>
                <th style="width: 30%">ลูกค้า/Agency</th>
                <th style="width: 20%">
                    สิทธิ์การใช้งาน <br />
                    Online Booking
                </th>
            </thead>
            <tbody class="agency-box-body">
                <tr *ngFor="let contact of obAgencyContacts; let index = index">
                    <td>
                        <span class="color-grey-text">{{ index + 1 }}.</span>
                    </td>
                    <td>
                        <strong class="color-grey-text">
                            {{ displayTextTime(contact.createdAt | thaiDatePipe: true:false:false:true) }}
                        </strong>
                    </td>
                    <td>
                        <div class="d-flex flex-column">
                            <span class="color-grey-text">
                                {{ contact.firstName }}
                                {{ contact.lastName || '' }}
                                (โทร. {{ (contact.tel | phoneNumber) || '-' }})
                            </span>
                            <span class="color-grey-text">Username : {{ contact.user.username }}</span>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex flex-column">
                            <span class="color-grey-text">
                                {{ contact.agency.companyName }}
                                <span *ngIf="contact.agency.aliasName">({{ contact.agency.aliasName }})</span>
                            </span>
                            <span class="color-grey-text">ใบอนุญาตินำเที่ยว : {{ contact.agency.tourismLicense }}</span>
                            <span class="color-grey-text">
                                เบอร์ติดต่อ : {{ (contact.agency.tel | phoneNumber) || '-' }}
                            </span>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex flex-column">
                            <strong class="color-grey-text mb-2">
                                {{ contact.channelObStatusCode === 'pending_approve' ? 'รออนุมัติ' : '-' }}
                            </strong>
                            <a [href]="v1BaseUrl + '/agency/view/' + contact.agency.id + '/sales/' + contact.id">
                                <button class="btn btn-blue small-text-size">ดูข้อมูล</button>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- data is not found -->
    <div class="data-not-found" *ngIf="obAgencyContacts && obAgencyContacts.length === 0">
        <div class="data-not-found-box">
            <div>
                <img src="assets/icons/icon-not-found.png" width="110" height="110" />
            </div>
            <div class="mt-3">
                <strong class="secondary-title-text-size color-grey-text">ไม่พบ Agency ที่รออนุมัติ</strong>
            </div>
        </div>
    </div>

    <div class="agency-box-footer" *ngIf="obAgencyContacts && obAgencyContacts.length > 0">
        <a class="sub-title-text-size color-blue-text" [href]="v1BaseUrl + '/agency/member/list'"><u>ดูทั้งหมด</u></a>
    </div>
</div>
