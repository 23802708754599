import {
    SearchableDropdownSettings,
    SearchableDropdownItem,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { CategoryTransport } from '../category-transport.model';
import { Province } from '../province.model';
import { Period } from './product-period/period.model';
import { Transportation } from '../transportation.model';
import { ITag } from '../tag.model';
import { Country } from '../country.model';
import { FirstPricePeriod } from './product-period/first-price-period';
import { TOURISM_TYPES } from 'src/app/constants/tourism-types.contant';

export class Product {
    id: number;
    name: string;
    tourCode: string;
    bannerImageFileUrl: string;
    countries: Country[];
    provinces: Province[];
    durationDay: number;
    durationNight: number;
    price: number;
    priceCompare: number;
    firstPricePeriod: FirstPricePeriod;
    startAt: Date;
    endAt: Date;
    commissionCompany: number;
    commissionSeller: number;
    categoryTransport: CategoryTransport;
    transportation: Transportation;
    docFileUrl: string;
    pdfFileUrl: string;
    hilightDescription: string;
    updatedAt: Date;
    periods: Period[];

    public deserialize(item: any): this {
        this.id = item.id;
        this.name = item.name;
        this.tourCode = item.tour_code;
        this.bannerImageFileUrl = item.banner_image_file_url;
        this.countries = item.countries.map((p) => new Country().deserialize(p));
        this.provinces = item.provinces.map((p) => new Province().deserialize(p));
        this.durationDay = item.duration_day;
        this.durationNight = item.duration_night;
        this.price = item.price;
        this.priceCompare = item.price_compare;
        this.firstPricePeriod = new FirstPricePeriod().deserialise(item.first_price_period);
        this.startAt = item.start_at ? new Date(item.start_at) : null;
        this.endAt = item.end_at ? new Date(item.end_at) : null;
        this.commissionCompany = item.commission_company;
        this.commissionSeller = item.commission_seller;
        this.categoryTransport = new CategoryTransport().deserialize(item.category_transport);
        this.transportation = new Transportation().deserialize(item.transportation);
        this.docFileUrl = item.doc_file_url;
        this.pdfFileUrl = item.pdf_file_url;
        this.hilightDescription = item.hilight_description;
        this.updatedAt = item.updated_at ? new Date(item.updated_at) : null;
        this.periods = item.product_periods.map((period) => new Period().deserialize(period));
        return this;
    }
}

export class ProgramTourFormViewModel {
    banners: ProgramTourBannerViewModel[];

    name: string;
    countryIds: number[];
    provinceIds: number[];
    amountOfDay: number;
    amountOfNight: number;
    startPrice: number;
    companyCommission: number;
    sellerCommission: number;

    transportCategoryId: number;
    transportId: number;
    startPeriod: Date;
    endPeriod: Date;
    highlightText: string;

    tourCode: string;
    wordDocument: string;
    wordDocumentIsDirty: boolean = false;
    pdfDocument: string;
    pdfDocumentIsDirty: boolean = false;

    defaultImageBanner: string;
    hasDisplayMoreDetail: boolean;

    tags: any[];
    hasVisa: boolean = false;
    visaPrice: number | null = null;
    hotelStar: number | null = null;
    mealAmount: number | null = null;
    freeDay: number | null = null;
}

export class ProgramTourBannerViewModel {
    fileId?: number;
    image: string;
    isDefaultImage?: boolean;
}

export class ProgramTourFormDataViewModel {
    countryDropdownSettings: SearchableDropdownSettings;
    countryDropdownItems: SearchableDropdownItem[];

    outboundCountryDropdownSettings: SearchableDropdownSettings;

    provinceDropdownSettings: SearchableDropdownSettings;
    provinceDropdownItems: SearchableDropdownItem[];

    transportCategoryDropdownSettings: SearchableDropdownSettings;
    transportCategoryDropdownItems: SearchableDropdownItem[];

    transportDropdownDisabled: boolean;
    transportDropdownSettings: SearchableDropdownSettings;
    transportDropdownItems: SearchableDropdownItem[];

    countrySubUnitDropdownSettings: SearchableDropdownSettings;
    countrySubUnitDropdownItems: SearchableDropdownItem[];
    countrySubUnitDropdownItemsFiltered: SearchableDropdownItem[];

    hotelStarDropdownSettings: SearchableDropdownSettings;
    hotelStarDropdownItems: SearchableDropdownItem[];

    constructor() {
        this.countryDropdownSettings = new SearchableDropdownSettings();
        this.countryDropdownSettings.dropdownListPlaceholder = 'เลือกประเทศ';
        this.countryDropdownSettings.searchBoxPlaceholder = 'กรอกชื่อประเทศ';
        this.countryDropdownSettings.styleSetting.searchBoxPlaceholderFontSize = '12px';
        this.countryDropdownSettings.styleSetting.width = '360px';
        this.countryDropdownItems = [];

        this.outboundCountryDropdownSettings = new SearchableDropdownSettings();
        this.outboundCountryDropdownSettings.dropdownListPlaceholder = 'เลือกประเทศ';
        this.outboundCountryDropdownSettings.searchBoxPlaceholder = 'กรอกชื่อประเทศ';
        this.outboundCountryDropdownSettings.styleSetting.searchBoxPlaceholderFontSize = '12px';
        this.outboundCountryDropdownSettings.styleSetting.width = '480px';

        this.provinceDropdownSettings = new SearchableDropdownSettings();
        this.provinceDropdownSettings.dropdownListAllItemsLabel = 'ทุกจังหวัด';
        this.provinceDropdownSettings.dropdownListPlaceholder = 'เลือกจังหวัด';
        this.provinceDropdownSettings.searchBoxPlaceholder = 'กรอกชื่อจังหวัด';
        this.provinceDropdownSettings.styleSetting.searchBoxPlaceholderFontSize = '12px';
        this.provinceDropdownItems = [];

        this.transportCategoryDropdownSettings = new SearchableDropdownSettings();
        this.transportCategoryDropdownSettings.multipleSelection = false;
        this.transportCategoryDropdownSettings.closeAfterSelect = true;
        this.transportCategoryDropdownSettings.dropdownListPlaceholder = 'เลือกประเภทการเดินทาง';
        this.transportCategoryDropdownSettings.hideSearchBox = true;
        this.transportCategoryDropdownSettings.dropdownlistHeight = 170;
        this.transportCategoryDropdownItems = [];

        this.transportDropdownDisabled = true;
        this.transportDropdownSettings = new SearchableDropdownSettings();
        this.transportDropdownSettings.multipleSelection = false;
        this.transportDropdownSettings.closeAfterSelect = true;
        this.transportDropdownSettings.dropdownListPlaceholder = 'เลือกสายการบิน';
        this.transportDropdownSettings.hideSearchBox = true;
        this.transportDropdownItems = [];

        this.countrySubUnitDropdownSettings = new SearchableDropdownSettings();
        this.countrySubUnitDropdownSettings.dropdownListPlaceholder = 'เลือกเมือง/จังหวัด';
        this.countrySubUnitDropdownSettings.searchBoxPlaceholder = 'กรอกเมือง/จังหวัด';
        this.countrySubUnitDropdownSettings.styleSetting.searchBoxPlaceholderFontSize = '12px';
        this.countrySubUnitDropdownSettings.displayPreSelectName = true;
        this.countrySubUnitDropdownSettings.styleSetting.width = '480px';
        this.countrySubUnitDropdownItems = [];
        this.countrySubUnitDropdownItemsFiltered = [];

        this.hotelStarDropdownSettings = new SearchableDropdownSettings();
        this.hotelStarDropdownSettings.multipleSelection = false;
        this.hotelStarDropdownSettings.closeAfterSelect = true;
        this.hotelStarDropdownSettings.dropdownListPlaceholder = 'ระดับ';
        this.hotelStarDropdownSettings.hideSearchBox = true;
        this.hotelStarDropdownSettings.styleSetting.searchBoxPlaceholderFontSize = '12px';
        this.hotelStarDropdownSettings.dropdownlistHeight = 140;
        this.hotelStarDropdownSettings.customSelectItem = 'px-0';
        this.hotelStarDropdownItems = [];
    }
}

export class ProgramTourValidationViewModel {
    isBannerEmpty: boolean;
    isBannerSizeInvalid: boolean;
    isBannerDimensionInvalid: boolean;
    isBannerUploadError: boolean;
    isNameEmpty: boolean;
    isNameLengthInvalid: boolean;
    isCountryEmpty: boolean;
    isProvinceEmpty: boolean;
    isAmountOfDaysEmpty: boolean;
    isAmountOfNightEmpty: boolean;
    isStartPriceEmpty: boolean;
    isStartPriceInvalid: boolean;
    isTourCodeEmpty: boolean;
    isCompanyCommissionInvalid: boolean;
    isSellerCommissionInvalid: boolean;
    isHighlightTextInvalid: boolean;
    isWordDocumentInvalid: boolean;
    isWordSizeInvalid: boolean;
    isPdfDocumentInvalid: boolean;
    isPdfSizeInvalid: boolean;
    isHasVisaInvalid: boolean;
    isMealAmountInvalid: boolean;
    isFreeDayInvalid: boolean;
    isAllValid: boolean = true;
}

export interface INewProgram {
    is_domestic: boolean;
    name: string;
    price: number;
    tour_code: string;
    countries_id: number[];
    provinces_id: number[];
    duration_day: number;
    duration_night: number;
    start_at: string;
    end_at: string;
    category_transport_id: number;
    transportation_id: number;
    commission_company: number;
    commission_seller: number;
    hilight_description: string;
    doc_file: {
        base64_data: string;
    };
    pdf_file: {
        base64_data: string;
    };
    banner_image_file_ids: number[];
    main_banner_image_file_id: number;
    tags: ITag[];
    has_visa: boolean;
    visa_price: number;
    hotel_star: number;
    free_day: number;
    meal_amount: number;
}

export interface IUpdateProgram {
    name: string;
    price: number;
    tour_code: string;
    countries_id: number[];
    provinces_id: number[];
    duration_day: number;
    duration_night: number;
    start_at: string;
    end_at: string;
    category_transport_id: number;
    transportation_id: number;
    commission_company: number;
    commission_seller: number;
    hilight_description: string;
    doc_file: {
        base64_data: string;
        is_same_file: boolean;
    };
    pdf_file: {
        base64_data: string;
        is_same_file: boolean;
    };
    banner_image_file_ids: number[];
    main_banner_image_file_id: number;
    tags: ITag[];
    has_visa: boolean;
    visa_price: number;
    hotel_star: number;
    free_day: number;
    meal_amount: number;
}
