import { Pipe, PipeTransform } from '@angular/core';
import { CATEGORY_SUB_PRODUCTS_TO_STRING } from '../constants/product/product-category.contants';

@Pipe({
    name: 'productCategory',
})
export class ProductCategoryPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        const categoryName = CATEGORY_SUB_PRODUCTS_TO_STRING[value];
        return categoryName ? categoryName : '';
    }
}
