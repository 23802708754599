import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as UtilityActions from './utility.actions';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import { NgxUiLoaderService } from 'ngx-ui-loader';

// Services
import { ProductService } from 'src/app/services/product.service';
import { UtilityService } from 'src/app/services/utility.service';

// Models
import { Continent, Region } from 'src/app/models/location.model';
import { Transportation } from 'src/app/models/transportation.model';
import { Country } from 'src/app/models/country.model';
import { Province } from 'src/app/models/province.model';
import { CountrySubUnit } from 'src/app/models/country-sub-unit.model';
import { ICountrySubUnitResponse } from 'src/app/models/interfaces/country-sub-unit-response.interface';
import { ICountrySubUnit } from 'src/app/models/interfaces/country-sub-unit.interface';

@Injectable()
export class UtilityEffects {
    constructor(
        private actions$: Actions,
        private utilityService: UtilityService,
        private productService: ProductService,
        private ngxService: NgxUiLoaderService
    ) {}

    getTransportations = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.getTransportations),
            switchMap(() => {
                this.ngxService.start();
                return this.utilityService.getTransportations().pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return UtilityActions.getTransportationsSuccess({
                                data: resp.data.map((d) => new Transportation().deserialize(d)),
                            });
                        } else {
                            throw new Error();
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(UtilityActions.getTransportationsFailure(error));
                    })
                );
            })
        )
    );

    getRegions = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.getRegions),
            switchMap(() => {
                this.ngxService.start();
                return this.productService.getRegions().pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return UtilityActions.getRegionsSuccess({
                                regions: resp.data.regions.map((item) => new Region().deserialize(item)),
                            });
                        } else {
                            throw new Error();
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(UtilityActions.getRegionsFailure(error));
                    })
                );
            })
        )
    );

    getProvinces = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.getProvinces),
            switchMap((action) => {
                this.ngxService.start();
                return this.productService.getProvinces(action.countryId).pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return UtilityActions.getProvincesSuccess({
                                provinces: resp.data.map((item) => new Province().deserialize(item, action.countryId)),
                            });
                        } else {
                            throw new Error();
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(UtilityActions.getProvincesFailure(error));
                    })
                );
            })
        )
    );

    getContinents = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.getContinents),
            switchMap(() => {
                this.ngxService.start();
                return this.productService.getContinents().pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return UtilityActions.getContinentsSuccess({
                                continents: resp.data.continents.map((item) => new Continent().deserialize(item)),
                            });
                        } else {
                            throw new Error();
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(UtilityActions.getContinentsFailure(error));
                    })
                );
            })
        )
    );

    getCountries = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.getCountries),
            switchMap(() => {
                this.ngxService.start();
                return this.productService.getCountries().pipe(
                    map((resp: any) => {
                        this.ngxService.stop();
                        if (resp.status == 'success') {
                            return UtilityActions.getCountriesSuccess({
                                countries: resp.data.map((item) => new Country().deserialize(item)),
                            });
                        } else {
                            throw new Error();
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(UtilityActions.getCountriesFailure(error));
                    })
                );
            })
        )
    );

    getCountrySubUnits = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(UtilityActions.getCountrySubUnits),
                switchMap(() => {
                    this.ngxService.start();
                    return this.productService.getCountrySubUnits().pipe(
                        map((resp: ICountrySubUnitResponse) => {
                            this.ngxService.stop();
                            if (resp.status === 'success') {
                                return UtilityActions.getCountrySubUnitsSuccess({
                                    countrySubUnits: resp.data.map((item: ICountrySubUnit) =>
                                        new CountrySubUnit().deserialize(item)
                                    ),
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error: any) => {
                            this.ngxService.stop();
                            return of(UtilityActions.getCountrySubUnitsSuccess(error));
                        })
                    );
                })
            )
    );


}
