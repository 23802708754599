import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DashBoardProductPeriod } from 'src/app/models/product/product-period/dashboard-period.model';
import {
    SearchableDropdownItem,
    SearchableDropdownSettings,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { getProductPeriods } from 'src/app/stores/report-store/report.actions';
import { selectDashboardProductPeriods } from 'src/app/stores/report-store/report.selectors';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-product-period-summary',
    templateUrl: './product-period-summary.component.html',
    styleUrls: ['./product-period-summary.component.scss'],
})
export class ProductPeriodSummaryComponent implements OnInit, OnDestroy {
    public monthDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    public monthDropdownItems: SearchableDropdownItem[] = [];
    public monthDropdownSelected: number;

    public periods: DashBoardProductPeriod[] = null;

    private unsubscribe$ = new Subject<void>();

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.initDropdowns();
        this.loadData(this.monthDropdownSelected);

        this.store
            .select(selectDashboardProductPeriods)
            .pipe(
                filter((x) => !!x),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((periods) => {
                this.periods = periods;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public reload(): void {
        this.loadData(this.monthDropdownSelected);
    }

    private initDropdowns(): void {
        const dateFormatter = new Intl.DateTimeFormat(environment.dateLanguage, { month: 'long', year: 'numeric' });
        const dates = [
            new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
            new Date(new Date().getFullYear(), new Date().getMonth() + 2, 1),
        ];
        //
        this.monthDropdownSettings.closeAfterSelect = true;
        this.monthDropdownSettings.dropdownlistHeight = 100;
        this.monthDropdownSettings.hideSearchBox = true;
        this.monthDropdownSettings.multipleSelection = false;
        this.monthDropdownSettings.styleSetting.fontSize = '18px';
        this.monthDropdownSettings.styleSetting.width = '300px';
        this.monthDropdownSettings.styleSetting.height = '40px';
        this.monthDropdownItems = dates.map((date) => {
            return new SearchableDropdownItem({
                id: date.getMonth(),
                name: dateFormatter.format(date),
                value: date.getMonth(),
            });
        });
        this.monthDropdownItems[0].name = `เดือนล่าสุด ${this.monthDropdownItems[0].name}`;
        this.monthDropdownItems[0].isChecked = true;
        this.monthDropdownSelected = this.monthDropdownItems[0].value;
    }

    public onSelectMonthOption(monthSelected: number): void {
        this.monthDropdownSelected = monthSelected[0];
        this.loadData(this.monthDropdownSelected);
    }

    private loadData(month: number): void {
        const minDate: Date = new Date(new Date().getFullYear(), month, 1);
        const maxDate: Date = new Date(new Date().getFullYear(), month + 1, 0);
        this.store.dispatch(getProductPeriods({ minDate: minDate, maxDate: maxDate }));
    }
}

interface IMonthOption {
    label: string;
    minDate: Date;
    maxDate: Date;
}
