import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './wholesale.reducer';
import { WholesaleEffects } from './wholesale.effects';

export const FEATURE_NAME = 'wholesale';
@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(FEATURE_NAME, reducer),
        EffectsModule.forFeature([WholesaleEffects]),
    ],
    providers: [WholesaleEffects],
})
export class WholesaleStoreModule {}
