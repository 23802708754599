import { ProductDescriptionType } from 'src/app/constants/product/product-description-types.constant';
import { ProductFileType } from 'src/app/constants/product/product-file-types.constant';
import { IProductFile } from './product-file.model';

export interface IProductDescription {
    id: number;
    ordinal: number;
    typeSlug: ProductDescriptionType;
    text: string;
    fileId: number;
    imageFileUrl: string;
    toUpdate: boolean;

    deserialise(data: any): IProductDescription;
    deserialiseToProductFile(): IProductFile;
    serialise(): any;
}

export class ProductDescription implements IProductDescription {
    id: number;
    ordinal: number;
    typeSlug: ProductDescriptionType;
    text: string;
    fileId: number;
    imageFileUrl: string;
    toUpdate: boolean = false;

    public deserialise(data: any): ProductDescription {
        this.id = data.id;
        this.ordinal = data.ordinal;
        this.typeSlug = data.type_slug === 'text' ? ProductDescriptionType.TEXT : ProductDescriptionType.IMAGE;
        this.text = data.description;
        this.fileId = null;
        this.imageFileUrl = data.image_file_url;
        return this;
    }

    public deserialiseToProductFile(): IProductFile {
        return {
            id: this.id,
            file: null,
            pathURL: this.imageFileUrl,
            typeSlug: ProductFileType.Description,
        };
    }

    public serialise(): any {
        return {
            id: this.id,
            ordinal: this.ordinal,
            type_slug: this.typeSlug,
            product_image_file_id: this.fileId,
            description: this.text,
            to_update: this.toUpdate,
        };
    }
}
