import { Action, createReducer, on } from '@ngrx/store';
import { Continent, Region } from 'src/app/models/location.model';
import { ObAgencyContactCount } from 'src/app/models/ob-agency/ob-agency-contact-count.model';
import { ObAgencyContact } from 'src/app/models/ob-agency/ob-agency-contact.model';
import { ObAgencyCount } from 'src/app/models/ob-agency/ob-agency-count.model';
import { ObOrderCount } from 'src/app/models/order/ob-order-count.model';
import { ProductCount } from 'src/app/models/product/product-count.model';
import { DashBoardProductPeriod } from 'src/app/models/product/product-period/dashboard-period.model';
import * as ReportActions from './report.actions';

export const reportFeatureKey = 'report';

export interface ReportState {
    activeProductCount: ProductCount;
    continents: Continent[];
    regions: Region[];
    productPeriods: DashBoardProductPeriod[];
    obOrderCount: ObOrderCount;
    agcyAgencyCount: number;
    obAgencyContactCount: ObAgencyContactCount;
    obAgencyCount: ObAgencyCount;
    obAgencyContacts: ObAgencyContact[];
}

export const initialState: ReportState = {
    activeProductCount: null,
    continents: null,
    regions: null,
    productPeriods: null,
    obOrderCount: null,
    agcyAgencyCount: null,
    obAgencyContactCount: null,
    obAgencyCount: null,
    obAgencyContacts: null,
};

export const reportReducer = createReducer(
    initialState,

    on(ReportActions.getActiveProductCountSuccess, (state, action) => {
        return { ...state, activeProductCount: action.activeProductCount };
    }),

    on(ReportActions.getContinentsSuccess, (state, action) => {
        return { ...state, continents: action.continents };
    }),

    on(ReportActions.getRegionsSuccess, (state, action) => {
        return { ...state, regions: action.regions };
    }),

    on(ReportActions.getProductPeriodsSuccess, (state, action) => {
        return { ...state, productPeriods: action.periods };
    }),

    on(ReportActions.getObOrderCountPerStatusSuccess, (state, action) => {
        return { ...state, obOrderCount: action.obOrderCount };
    }),

    on(ReportActions.getAgcyAgencyCountSuccess, (state, action) => {
        return { ...state, agcyAgencyCount: action.count };
    }),

    on(ReportActions.getObAgencyCountSuccess, (state, action) => {
        return { ...state, obAgencyCount: action.obAgencyCount };
    }),
    on(ReportActions.getObAgencyContactCountSuccess, (state, action) => {
        return { ...state, obAgencyContactCount: action.obAgencyContactCount };
    }),

    on(ReportActions.getObAgencyContactsSuccess, (state, action) => {
        return { ...state, obAgencyContacts: action.contacts };
    })
);

export function reducer(state: ReportState | undefined, action: Action) {
    return reportReducer(state, action);
}
