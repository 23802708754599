export const environment = {
    production: false,
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    appVersion: require('../../package.json').version + '-staging',
    tourwowProBackofficeUrl: 'https://staging2.tourwowpro.com/',
    apiProWsUrl: 'https://ws-api-staging2.tourwowpro.com',
    agencyLoginUrl: 'https://agency-staging2.tourwowpro.com/login',
    partnerBackofficeLoginUrl: 'https://biz-staging2.dealwow.com/login',
    bookingUrl: 'https://booking-staging2.tourwowpro.com/{supplier_slug}',
    dateLanguage: 'th-TH',
};

export const Feature_TOGGLE = {
    side_menu_twm_order: true,
};
