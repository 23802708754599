import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './utility.reducer';
import { UtilityEffects } from './utility.effects';
import * as fromUtility from './utility.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(fromUtility.utilityFeatureKey, reducer),
        EffectsModule.forFeature([UtilityEffects]),
    ],
    providers: [UtilityEffects],
})
export class UtilityStoreModule {}
