import { ITwBannerImageFile } from '../interfaces/tw-banner-image-file.interface';
import { ITwProductImageFileResponse } from '../interfaces/tw-product-image-file-response.interface';

export class TwProductFile {
    id: number;
    url: string | null;
    isMain: boolean | null;

    setData(id: number, url: string | null, isMain: boolean | null) {
        this.id = id;
        this.url = url;
        this.isMain = isMain;
        return this;
    }

    deserialize(data: ITwBannerImageFile) {
        this.id = data.file_id;
        this.url = data.file_url;
        this.isMain = data.is_main;
        return this;
    }

    deserializeReponse(resp: ITwProductImageFileResponse) {
        this.id = resp.data.file_id;
        this.url = resp.data.file_url;
        this.isMain = resp.data.is_main;
        return this;
    }
}
