import { Action, createReducer, on, State } from '@ngrx/store';
import * as WholesaleActions from './wholesale.actions';

// Models
import { ISupplier } from 'src/app/models/supplier.model';
import { IWholesale } from 'src/app/models/wholesale.model';
import { User } from 'src/app/models/user.model';

export const productFeatureKey = 'wholesale';

export interface WholesaleState {
    profile: IWholesale;
}

export const initialState: WholesaleState = {
    profile: null,
};

export const wholesaleReducer = createReducer(
    initialState,

    on(WholesaleActions.updateProfile, (state, action) => {
        return {
            ...state,
            profile: action.data,
        };
    })
);

export function reducer(state: WholesaleState | undefined, action: Action) {
    return wholesaleReducer(state, action);
}
