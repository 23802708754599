import { Country } from './country.model';
import { Province } from './province.model';

export class Region {
    id: number;
    name: string;
    provinces: Province[];

    public deserialize(item) {
        this.id = item.id;
        this.name = item.name;
        this.provinces = item.provinces.map((p) => new Province().deserialize(p));
        return this;
    }
}

export class Continent {
    id: number;
    nameTH: string;
    nameEN: string;
    countries: Country[];

    public deserialize(item) {
        this.id = item.id;
        this.nameTH = item.name_th;
        this.nameEN = item.name_en;
        this.countries = item.countries.map((p) => new Country().deserialize(p));
        return this;
    }
}
