import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'thaiDayOfWeek',
})
export class ThaiDayOfWeekPipe implements PipeTransform {
    private thaiShortDays = ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'];

    private thaiLongDays = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์'];

    transform(date: Date, useAbbr: boolean = true): string {
        if (!date) return '';
        if (useAbbr) {
            return this.thaiShortDays[date.getDay()] + '.';
        } else {
            return this.thaiLongDays[date.getDay()];
        }
    }
}
