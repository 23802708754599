export interface IProvince {
    id: number;
    countryId?: number;
    nameTH: string;
    nameEN: string;

    deserialize(item);
}

export class Province implements IProvince {
    public id: number;
    public countryId?: number;
    public nameTH: string;
    public nameEN: string;

    public deserialize(item: any, countryId?: number) {
        this.id = item.id;
        this.nameTH = item.name_th;
        this.nameEN = item.name_en;
        this.countryId = item.country_id;
        return this;
    }
}
