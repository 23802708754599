import { NgxUiLoaderConfig } from 'ngx-ui-loader';

export const NGX_LOADER_CONFIG: NgxUiLoaderConfig = {
    // bgsColor: 'blue',
    // bgsOpacity: 0.5,
    // bgsPosition: 'bottom-right',
    // bgsSize: 0,
    // bgsType: 'ball-spin-clockwise',
    blur: 5,
    // delay: 0,
    // fgsColor: '#1565c0',
    // fgsPosition: 'center-center',
    // fgsSize: 0,
    // fgsType: 'ball-spin-clockwise',
    // gap: 24,
    // logoPosition: 'center-center',
    // logoSize: 60,
    // logoUrl: 'assets/images/icon-loading.gif',
    masterLoaderId: 'master',
    overlayBorderRadius: '0',
    overlayColor: 'rgba(40, 40, 40, 0.8)',
    pbColor: 'blue',
    pbDirection: 'ltr',
    pbThickness: 3,
    hasProgressBar: true,
    maxTime: -1,
    minTime: 300,
};
