import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

// State
import { Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import { selectProfile } from 'src/app/stores/wholesale-store/wholesale.selectors';

// Models
import { IWholesale } from 'src/app/models/wholesale.model';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    public profile$: Observable<IWholesale>;

    constructor(private store: Store<State>) {}

    ngOnInit(): void {
        this.profile$ = this.store.select(selectProfile);
    }
}
