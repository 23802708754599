import * as dayjs from 'dayjs';

export class CountProductSubCategory {
    all: number;
    outbound: number;
    domestic: number;

    deserialize(source: any): this {
        this.all = source.all;
        this.outbound = source.outbound;
        this.domestic = source.domestic;

        return this;
    }
}

export class CountOrderStatus {
    waitingConfirm: number;
    waitingFirstPaid: number;
    waitingFullPaid: number;
    complete: number;
    cancel: number;

    deserialize(source: any): this {
        this.waitingConfirm = source.waiting_confirm;
        this.waitingFirstPaid = source.waiting_first_paid;
        this.waitingFullPaid = source.waiting_full_paid;
        this.complete = source.complete;
        this.cancel = source.cancel;

        return this;
    }
}

export class ProductOrderListPaginated {
    page: number;
    pageEntries: number;
    total: number;
    result: ProductOrderListItem[];

    constructor(page?: number, pageEntries?: number, total?: number, result?: ProductOrderListItem[]) {
        this.page = page;
        this.pageEntries = pageEntries;
        this.total = total;
        this.result = result;
    }

    deserialize(source: any): this {
        this.page = source.page;
        this.pageEntries = source.page_entries;
        this.total = source.total;
        this.result = source.result.map((r) => new ProductOrderListItem().deserialize(r));

        return this;
    }
}

export class ProductOrderListItem {
    id: number;
    orderCode: string;
    orderStatusSlug: 'complete';
    isOrderCompleted: boolean;
    netSumAmount: number;
    quantity: number;
    fullyPaid: boolean;
    createdAt: Date;

    customerName: string;
    customerPhoneNumber: string;
    customerEmail: string;
    customerRemark: string;

    productSubCategoryId: number;
    productSubCategorySlug: string;
    productSubCategoryName: string;
    productSubCategoryNameTh: string;

    productName: string;
    productTourCode: string;

    productPeriodStartAt: Date;
    productPeriodEndAt: Date;

    agencyId: number;
    agencyProductCode: string;
    agencyNameEn: string;
    agencyNameTh: string;

    firstInstallment: ProductOrderInstallment;
    installments: ProductOrderInstallment[];
    totalInstallment: number;

    deserialize(source: any): this {
        this.id = source.id;
        this.orderCode = source.order_code;
        this.orderStatusSlug = source.order_status_slug;
        this.isOrderCompleted = this.orderStatusSlug === 'complete';
        this.netSumAmount = source.net_sum_amount;
        this.quantity = source.quantity;
        this.fullyPaid = source.fully_paid;
        this.createdAt = source.created_at ? dayjs(source.created_at).toDate() : null;

        if (source.customer) {
            this.customerName = source.customer.contact_name;
            this.customerPhoneNumber = source.customer.phone_number;
            this.customerEmail = source.customer.email;
            this.customerRemark = source.customer.customer_remark;
        }

        if (source.product_sub_category) {
            this.productSubCategoryId = source.product_sub_category.id;
            this.productSubCategorySlug = source.product_sub_category.slug;
            this.productSubCategoryName = source.product_sub_category.name;
            this.productSubCategoryNameTh = source.product_sub_category.name_th;
        }

        if (source.product) {
            this.productName = source.product.name;
            this.productTourCode = source.product.tour_code;
        }

        if (source.product_period) {
            this.productPeriodStartAt = source.product_period.start_at
                ? dayjs(source.product_period.start_at).toDate()
                : null;
            this.productPeriodEndAt = source.product_period.end_at
                ? dayjs(source.product_period.end_at).toDate()
                : null;
        }

        if (source.agency) {
            this.agencyId = source.agency.id;
            this.agencyProductCode = source.agency.product_code;
            this.agencyNameEn = source.agency.name_en;
            this.agencyNameTh = source.agency.name_th;
        }

        this.installments = source.installments.map((x) => new ProductOrderInstallment().deserialize(x));
        this.totalInstallment = this.installments.length;
        if (this.totalInstallment) {
            this.firstInstallment = this.installments[0];
        }

        return this;
    }
}

export class ProductOrderInstallment {
    netAmount: number;
    paidAt: Date;

    deserialize(source: any): this {
        this.netAmount = source.net_amount;
        this.paidAt = source.paid_at ? dayjs(source.paid_at).toDate() : null;

        return this;
    }
}

export class ProductOrderListSearchCriteria {
    searchString: string;
    productSubCategorySlug: 'all' | 'outbound' | 'domestic';
    orderStatusSlug: 'waiting_confirm' | 'waiting_first_paid' | 'waiting_full_paid' | 'complete' | 'cancel';
    isLocalSearch: boolean;
}
