import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './order.reducer';
import { OrderEffects } from './order.effects';

export const FEATURE_NAME = 'order';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, reducer), EffectsModule.forFeature([OrderEffects])],
    providers: [OrderEffects],
})
export class OrderStoreModule {}
