import { Transportation } from '../../models/transportation.model';
import { Action, createReducer, on, State } from '@ngrx/store';
import * as UtilityActions from './utility.actions';
import { Region, Continent } from 'src/app/models/location.model';
import { ICountry } from 'src/app/models/country.model';
import { IProvince } from 'src/app/models/province.model';
import { CountrySubUnit } from 'src/app/models/country-sub-unit.model';

export const utilityFeatureKey = 'utility';

export interface UtilityState {
    transportations: Transportation[];
    transportationsError: any;
    regions: Region[];
    regionsError: any;
    provinces: IProvince[];
    provincesError: any;
    continents: Continent[];
    continentsError: any;
    countries: ICountry[];
    countriesError: any;
    countrySubUnits: CountrySubUnit[];
    countrySubUnitsError: any;
}

export const initialState: UtilityState = {
    transportations: null,
    transportationsError: null,
    regions: null,
    regionsError: null,
    provinces: null,
    provincesError: null,
    continents: null,
    continentsError: null,
    countries: null,
    countriesError: null,
    countrySubUnits: null,
    countrySubUnitsError: null
};

export const utilityReducer = createReducer(
    initialState,

    on(UtilityActions.getTransportations, (state) => {
        return { ...state };
    }),

    on(UtilityActions.getTransportationsSuccess, (state, action) => {
        return { ...state, transportations: action.data };
    }),

    on(UtilityActions.getTransportationsFailure, (state, action) => {
        return { ...state, transportationsError: action.error };
    }),

    on(UtilityActions.getRegions, (state) => {
        return { ...state };
    }),

    on(UtilityActions.getRegionsSuccess, (state, action) => {
        return { ...state, regions: action.regions };
    }),

    on(UtilityActions.getRegionsFailure, (state, action) => {
        return { ...state, productsCountError: action.error };
    }),

    on(UtilityActions.getProvinces, (state) => {
        return { ...state };
    }),

    on(UtilityActions.getProvincesSuccess, (state, action) => {
        return { ...state, provinces: action.provinces };
    }),

    on(UtilityActions.getProvincesFailure, (state, action) => {
        return { ...state, provincesError: action.error };
    }),

    on(UtilityActions.getContinents, (state) => {
        return { ...state };
    }),

    on(UtilityActions.getContinentsSuccess, (state, action) => {
        return { ...state, continents: action.continents };
    }),

    on(UtilityActions.getContinentsFailure, (state, action) => {
        return { ...state, continentsError: action.error };
    }),

    on(UtilityActions.getCountries, (state) => {
        return { ...state };
    }),

    on(UtilityActions.getCountriesSuccess, (state, action) => {
        return { ...state, countries: action.countries };
    }),

    on(UtilityActions.getCountriesFailure, (state, action) => {
        return { ...state, countriesError: action.error };
    }),

    on(UtilityActions.getCountrySubUnits, (state) => {
        return { ...state };
    }),

    on(UtilityActions.getCountrySubUnitsSuccess, (state, action) => {
        return { ...state, countrySubUnits: action.countrySubUnits };
    }),

    on(UtilityActions.getCountrySubUnitsFailure, (state, action) => {
        return { ...state, countrySubUnitsError: action.error };
    }),
    
);

export function reducer(state: UtilityState | undefined, action: Action) {
    return utilityReducer(state, action);
}
