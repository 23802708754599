export class ProductFilter {
    public id: number;
    public tourCode: string;
    public name: string;

    public deserialize(item: any) {
        this.id = item.id;
        this.tourCode = item.tour_code;
        this.name = item.name;
        return this;
    }
}
