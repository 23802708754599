import * as _ from 'lodash-es';
import { PeriodPriceType } from 'src/app/constants/product/product-period.constants';
import { DateTimeHelpers } from 'src/app/helpers/date-time.helpers';
import { CategoryTransport } from '../../category-transport.model';
import { Transportation } from '../../transportation.model';

export class PeriodDetail {
    public id: number;
    public startAt: Date = null;
    public endAt: Date = null;
    public groupCode: string = null;

    public commissionCompany: number = null;
    public commissionSeller: number = null;
    public deposit: number = null;
    public depositIsActive: boolean = false;
    public quantity: number = null;
    public quantitySold: number = null;
    public quantityInformal: number = null;
    public quantityTourLead: number = null;
    public quantityMinimum: number = null;
    public isExample: boolean = false;
    public remark: string = null;
    public isChannelTwpOnlineBooking: boolean = false;
    public categoryTransport: CategoryTransport = null;
    public transportationDescription: string = null;
    public updatedAt: string;

    public goTransportation: PeriodTransportation = new PeriodTransportation('go');
    public backTransportation: PeriodTransportation = new PeriodTransportation('back');

    public priceAdultSingle: PeriodPrice = new PeriodPrice(PeriodPriceType.adultSingle);
    public priceAdultDouble: PeriodPrice = new PeriodPrice(PeriodPriceType.adultDouble);
    public priceAdultTriple: PeriodPrice = new PeriodPrice(PeriodPriceType.adultTriple);
    public priceChildBed: PeriodPrice = new PeriodPrice(PeriodPriceType.childBed);
    public priceChildNoBed: PeriodPrice = new PeriodPrice(PeriodPriceType.childNoBed);
    public priceInfant: PeriodPrice = new PeriodPrice(PeriodPriceType.infant);
    public priceJoinLand: PeriodPrice = new PeriodPrice(PeriodPriceType.joinLand);

    sellStatusCode: number = 1; // 1 is open sale this period, 3 is close period.
    sellStatusReason: string | null = 'ปิดกรุ๊ป';

    public deserialize(data: any): this {
        this.id = data.id;
        this.startAt = data.start_at ? new Date(data.start_at) : null;
        this.endAt = data.end_at ? new Date(data.end_at) : null;
        this.groupCode = data.group_code;
        this.commissionCompany = data.commission_company;
        this.commissionSeller = data.commission_seller;
        this.deposit = data.deposit;
        this.depositIsActive = data.deposit_is_active;
        this.quantity = data.quantity;
        this.quantitySold = data.quantity_sold;
        this.quantityInformal = data.quantity_informal;
        this.quantityTourLead = data.quantity_tour_lead;
        this.quantityMinimum = data.quantity_minimum;
        this.isExample = data.is_example;
        this.remark = data.remark;
        this.isChannelTwpOnlineBooking = data.is_channel_twp_online_booking;
        this.categoryTransport = data.category_transport
            ? new CategoryTransport().deserialize(data.category_transport)
            : null;
        this.transportationDescription = data.transportation_description;
        this.updatedAt = data.updated_at;

        this.goTransportation.deserialize(data);
        this.backTransportation.deserialize(data);

        this.priceAdultSingle.deserialize(data);
        this.priceAdultDouble.deserialize(data);
        this.priceAdultTriple.deserialize(data);
        this.priceChildBed.deserialize(data);
        this.priceChildNoBed.deserialize(data);
        this.priceInfant.deserialize(data);
        this.priceJoinLand.deserialize(data);

        this.sellStatusCode = +data.sell_status_code;
        this.sellStatusReason = data.sell_status_reason;

        return this;
    }

    public serialize() {
        return {
            start_at: DateTimeHelpers.convertDateToYMDString(this.startAt),
            end_at: DateTimeHelpers.convertDateToYMDString(this.endAt),
            group_code: this.groupCode,

            price_adult_single: this.priceAdultSingle.price,
            price_adult_single_compare: this.priceAdultSingle.priceCompare,
            price_adult_single_plus: this.priceAdultSingle.pricePlus,

            price_adult_double: this.priceAdultDouble.price,
            price_adult_double_compare: this.priceAdultDouble.priceCompare,
            price_adult_double_plus: this.priceAdultDouble.pricePlus,

            price_adult_triple: this.priceAdultTriple.price,
            price_adult_triple_compare: this.priceAdultTriple.priceCompare,
            price_adult_triple_plus: this.priceAdultTriple.pricePlus,

            price_child_bed: this.priceChildBed.price,
            price_child_bed_compare: this.priceChildBed.priceCompare,
            price_child_bed_plus: this.priceChildBed.pricePlus,

            price_child_no_bed: this.priceChildNoBed.price,
            price_child_no_bed_compare: this.priceChildNoBed.priceCompare,
            price_child_no_bed_plus: this.priceChildNoBed.pricePlus,

            price_infant: this.priceInfant.price,
            price_infant_compare: this.priceInfant.priceCompare,
            price_infant_plus: this.priceInfant.pricePlus,

            price_join_land: this.priceJoinLand.price,
            price_join_land_compare: this.priceJoinLand.priceCompare,
            price_join_land_plus: this.priceJoinLand.pricePlus,

            commission_company: this.commissionCompany || null,
            commission_seller: this.commissionSeller || null,
            deposit: this.deposit,
            deposit_is_active: this.depositIsActive,
            quantity: this.quantity,
            quantity_informal: this.quantityInformal,
            quantity_tour_lead: this.quantityTourLead,
            quantity_minimum: this.quantityMinimum,
            is_example: this.isExample,
            remark: this.remark,
            is_channel_twp_online_booking: this.isChannelTwpOnlineBooking,

            category_transports_id: this.categoryTransport?.id || null,
            transportation_description: this.transportationDescription,

            go_transportations_id: this.goTransportation.transportation?.id || null,
            go_flight_number: this.goTransportation.flightNumber,
            go_airport_departure: this.goTransportation.airportDeparture,
            go_airport_arrival: this.goTransportation.airportArrival,
            go_flight_time_departure: this.goTransportation.flightTimeDeparture,
            go_flight_time_arrival: this.goTransportation.flightTimeArrival,

            back_transportations_id: this.backTransportation.transportation?.id || null,
            back_flight_number: this.backTransportation.flightNumber,
            back_airport_departure: this.backTransportation.airportDeparture,
            back_airport_arrival: this.backTransportation.airportArrival,
            back_flight_time_departure: this.backTransportation.flightTimeDeparture,
            back_flight_time_arrival: this.backTransportation.flightTimeArrival,

            sell_status_code: this.sellStatusCode,
            sell_status_reason: this.sellStatusCode === 3 ? this.sellStatusReason : null,
        };
    }
}

export class PeriodTransportation {
    transportation: Transportation = null;
    flightNumber: string = null;
    airportDeparture: string = null;
    airportArrival: string = null;
    flightTimeDeparture: string = null;
    flightTimeArrival: string = null;

    constructor(private direction: 'go' | 'back') {}

    public deserialize(data: any): this {
        this.transportation = new Transportation().deserialize(data[`${this.direction}_transportation`]);
        this.flightNumber = data[`${this.direction}_flight_number`];
        this.airportDeparture = data[`${this.direction}_airport_departure`];
        this.airportArrival = data[`${this.direction}_airport_arrival`];
        this.flightTimeDeparture = data[`${this.direction}_flight_time_departure`];
        this.flightTimeArrival = data[`${this.direction}_flight_time_arrival`];
        return this;
    }
}

export class PeriodPrice {
    public price: number = null;
    public priceCompare: number = null;
    public pricePlus: number = null;

    constructor(private priceType: PeriodPriceType) {}

    recheckPriceString(): void {
        if (!this.priceCompare) {
            return;
        }

        if (this.priceCompare == 0) {
            this.priceCompare = null;
        } else {
            this.priceCompare = +this.priceCompare.toString().replace(/\D/g, '');
        }
    }

    public deserialize(data: any): this {
        this.price = +data[`price_${this.priceType}`];
        this.priceCompare = +data[`price_${this.priceType}_compare`];
        this.pricePlus = +data[`price_${this.priceType}_plus`];
        return this;
    }
}
