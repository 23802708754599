<div class="modal-header">
</div>
<div class="modal-body">
    <h3>{{ message }}</h3>
    <h3>กรุณาตรวจสอบข้อมูลใหม่อีกครั้ง</h3>
</div>
<div class="modal-footer">
    <div class="w-100 d-flex align-items-center justify-content-center">
        <button type="button" class="btn btn-primary" (click)="bsModalRef.hide()">OK</button>
    </div>
</div>
