export class ProductTourwowConfig {
    tourwowCode: string;
    tourwowSlug: string;

    public deserialize(item) {
        this.tourwowCode = item.tourwow_code;
        this.tourwowSlug = item.tourwow_slug;
        return this;
    }
}

export class ProductTourwowConfigValidation {
    isTourwowSlugEmpty: boolean = false;
    isTourwowSlugLengthInvalid: boolean = false;
    isTourwowSlugAlready: boolean = false;
}
