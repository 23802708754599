import { Component } from '@angular/core';

// State
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    public isLoggedIn = false;

    constructor() {}
}
