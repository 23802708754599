import { HttpParams } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DateTimeHelpers } from 'src/app/helpers/date-time.helpers';
import { ObOrderCount } from 'src/app/models/order/ob-order-count.model';
import {
    SearchableDropdownSettings,
    SearchableDropdownItem,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { ThaiMonthRangePipe } from 'src/app/pipes/thaidate.pipe';
import { getObOrderCountPerStatus } from 'src/app/stores/report-store/report.actions';
import { selectObOrderCount } from 'src/app/stores/report-store/report.selectors';
import { selectProfile } from 'src/app/stores/wholesale-store/wholesale.selectors';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-order-summary',
    templateUrl: './order-summary.component.html',
    styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent implements OnInit, OnDestroy {
    constructor(private store: Store, private thaiMonthRangePipe: ThaiMonthRangePipe) {}

    monthDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    monthDropdownItems: SearchableDropdownItem[] = [];
    monthDropdownSelected: number;

    orderCount: ObOrderCount;

    // selectedDateRangeOption: IDateRangeOption;
    // dateRangeOptions: IDateRangeOption[] = [];
    orderListPageUrl: string;

    private unsubscribe$ = new Subject<void>();

    get totalCount(): number {
        if (this.orderCount) {
            return this.orderCount.pendingApproveOrder + this.orderCount.waitingPaid + this.orderCount.complete;
        }
        return 0;
    }

    ngOnInit(): void {
        this.initDropdowns();
        this.loadData();

        this.store
            .select(selectProfile)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((profile) => {
                this.orderListPageUrl =
                    environment.tourwowProBackofficeUrl + profile.supplier.productTwpSlug + '/order/new-list';
            });

        this.store
            .select(selectObOrderCount)
            .pipe(
                filter((x) => x !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((orderCount) => {
                this.orderCount = orderCount;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    reload(): void {
        this.loadData();
    }

    private initDropdowns(): void {
        this.monthDropdownSettings.closeAfterSelect = true;
        this.monthDropdownSettings.dropdownlistHeight = 100;
        this.monthDropdownSettings.hideSearchBox = true;
        this.monthDropdownSettings.multipleSelection = false;
        this.monthDropdownSettings.styleSetting.fontSize = '18px';
        this.monthDropdownSettings.styleSetting.width = '300px';
        this.monthDropdownSettings.styleSetting.height = '40px';
        this.monthDropdownItems.push(
            new SearchableDropdownItem({
                id: 1,
                name:
                    'เดือนล่าสุด ' +
                    new Intl.DateTimeFormat(environment.dateLanguage, { month: 'long', year: 'numeric' }).format(),
                value: 1,
            })
        );

        this.monthDropdownItems.push(
            new SearchableDropdownItem({
                id: 2,
                name:
                    '3 เดือนล่าสุด ' +
                    this.thaiMonthRangePipe.transform(
                        {
                            date1: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1),
                            date2: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                        },
                        true
                    ),
                value: 2,
            })
        );

        this.monthDropdownItems.push(
            new SearchableDropdownItem({
                id: 3,
                name:
                    '6 เดือนล่าสุด ' +
                    this.thaiMonthRangePipe.transform(
                        {
                            date1: new Date(new Date().getFullYear(), new Date().getMonth() - 5, 1),
                            date2: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                        },
                        true
                    ),
                value: 3,
            })
        );

        this.monthDropdownItems[0].isChecked = true;
        this.monthDropdownSelected = this.monthDropdownItems[0].value;
    }

    onSelectDateRange(value: number): void {
        this.monthDropdownSelected = value[0];
        this.loadData();
    }

    redirectToOrderListPage(orderStatusCodes: string[] = null): void {
        let previousMonth = 0;
        if (this.monthDropdownSelected === 2) {
            previousMonth = 2;
        } else if (this.monthDropdownSelected === 3) {
            previousMonth = 5;
        }

        const minDate: Date = new Date(new Date().getFullYear(), new Date().getMonth() - previousMonth, 1);
        const maxDate: Date = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

        let params = new HttpParams();
        params = params.set('load_immediately', true);
        params = params.set('created_at_start', DateTimeHelpers.convertDateToYMDString(minDate));
        params = params.set('created_at_end', DateTimeHelpers.convertDateToYMDString(maxDate));
        if (orderStatusCodes) {
            params = params.set('ws_order_status_key_arr', JSON.stringify(orderStatusCodes));
        }
        window.location.href = this.orderListPageUrl + '?' + params.toString();
    }

    private loadData(): void {
        let previousMonth = 0;
        if (this.monthDropdownSelected === 2) {
            previousMonth = 2;
        } else if (this.monthDropdownSelected === 3) {
            previousMonth = 5;
        }

        const minDate: Date = new Date(new Date().getFullYear(), new Date().getMonth() - previousMonth, 1);
        const maxDate: Date = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
        this.store.dispatch(
            getObOrderCountPerStatus({
                minDate: minDate,
                maxDate: maxDate,
            })
        );
    }
}
