<div class="page-container">
    <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
    <div class="d-flex justify-content-between">
        <div>
            <p class="title">
                <img class="mr-2" src="assets/icons/icon-dashboard.png" width="30" />
                <span style="vertical-align: middle">Dashboard</span>
                <img
                    class="ml-2"
                    style="cursor: pointer; vertical-align: middle"
                    src="assets/icons/icon-reload.png"
                    width="18"
                    (click)="reload()"
                />
            </p>
            <p class="sub-title">
                <img class="mr-2" src="assets/icons/icon-program-tour.png" width="30" />
                <span style="font-size: 26px; font-weight: bold">โปรแกรมทัวร์ในระบบ</span>
                <span style="font-size: 16px; color: #56697e"> (เฉพาะที่เปิดขายและยังไม่หมดอายุ) </span>
                <span style="font-size: 16px; color: #1565c0; cursor: pointer" (click)="copyOnlineBookingLink()">
                    <img
                        class="ml-2 mr-1"
                        style="width: 19px; vertical-align: baseline"
                        src="assets/icons/icon-copy-2.png"
                    />
                    <u>Link Online Booking</u>
                </span>
            </p>
        </div>
        <div class="welcome-box">
            <div style="font-size: 26px">
                Hello {{ profile.firstName }}
                <img class="mr-2" src="assets/icons/icon-say-hi.png" width="25" />
            </div>
            <div style="font-size: 14px">
                Welcome Back !!! {{ timeWhenLoaded | thaiDayOfWeek }}
                {{ timeWhenLoaded | thaiDatePipe: true:false:false:true }}
            </div>
        </div>
    </div>

    <div class="mt-3 mb-3">
        <app-outbound-product-summary></app-outbound-product-summary>
    </div>
    <div class="mt-3 mb-3">
        <app-domestic-product-summary></app-domestic-product-summary>
    </div>
    <div class="mt-3 mb-3">
        <app-product-period-summary></app-product-period-summary>
    </div>
    <div class="mt-3 mb-3">
        <app-order-summary></app-order-summary>
    </div>
    <div class="mt-3 mb-3">
        <app-agency-contact-summary></app-agency-contact-summary>
    </div>
</div>
