import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Continent, Region } from 'src/app/models/location.model';
import { ObAgencyContactCount } from 'src/app/models/ob-agency/ob-agency-contact-count.model';
import { ObAgencyContact } from 'src/app/models/ob-agency/ob-agency-contact.model';
import { ObAgencyCount } from 'src/app/models/ob-agency/ob-agency-count.model';
import { ObOrderCount } from 'src/app/models/order/ob-order-count.model';
import { ProductCount } from 'src/app/models/product/product-count.model';
import { DashBoardProductPeriod } from 'src/app/models/product/product-period/dashboard-period.model';
import { AgcyAgencyService } from 'src/app/services/agcy-agency.service';
import { ObAgencyContactService } from 'src/app/services/ob-agency-contact.service';
import { ObAgencyService } from 'src/app/services/ob-agency.service';
import { ObOrderService } from 'src/app/services/ob-order.service';
import { ProductPeriodService } from 'src/app/services/product-period.service';
import { ProductService } from 'src/app/services/product.service';

import * as ReportActions from './report.actions';

@Injectable()
export class ReportEffects {
    constructor(
        private actions$: Actions,
        private productService: ProductService,
        private productPeriodService: ProductPeriodService,
        private obOrderService: ObOrderService,
        private agcyAgencyService: AgcyAgencyService,
        private obAgencyContactService: ObAgencyContactService,
        private obAgencyService: ObAgencyService,
        private ngxService: NgxUiLoaderService
    ) {}

    getActiveProductsCount = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ReportActions.getActiveProductCount),
            switchMap(() => {
                return this.productService.getProductsCount({ productIsActive: true }).pipe(
                    map((resp: any) => {
                        if (resp.status == 'success') {
                            return ReportActions.getActiveProductCountSuccess({
                                activeProductCount: new ProductCount().deserialize(resp.data),
                            });
                        } else {
                            return ReportActions.getActiveProductCountFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        return of(ReportActions.getActiveProductCountFailure({ error: error }));
                    })
                );
            })
        )
    );

    getContinents = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ReportActions.getContinents),
            switchMap(() => {
                return this.productService.getContinents({ countryHasActiveProduct: true }).pipe(
                    map((resp: any) => {
                        if (resp.status == 'success') {
                            return ReportActions.getContinentsSuccess({
                                continents: resp.data.continents.map((item) => new Continent().deserialize(item)),
                            });
                        } else {
                            return ReportActions.getActiveProductCountFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        return of(ReportActions.getContinentsFailure({ error: error }));
                    })
                );
            })
        )
    );

    getRegions = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ReportActions.getRegions),
            switchMap(() => {
                return this.productService.getRegions({ provinceHasActiveProduct: true }).pipe(
                    map((resp: any) => {
                        if (resp.status == 'success') {
                            return ReportActions.getRegionsSuccess({
                                regions: resp.data.regions.map((item) => new Region().deserialize(item)),
                            });
                        } else {
                            return ReportActions.getRegionsFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        return of(ReportActions.getRegionsFailure({ error: error }));
                    })
                );
            })
        )
    );

    getProductPeriods = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ReportActions.getProductPeriods),
            switchMap((action) => {
                return this.productPeriodService.getPeriods({ minDate: action.minDate, maxDate: action.maxDate }).pipe(
                    map((resp: any) => {
                        if (resp.status == 'success') {
                            return ReportActions.getProductPeriodsSuccess({
                                periods: resp.data.map((item) => new DashBoardProductPeriod().deserialize(item)),
                            });
                        } else {
                            return ReportActions.getProductPeriodsFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        return of(ReportActions.getProductPeriodsFailure({ error: error }));
                    })
                );
            })
        )
    );

    getObOrderCount = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ReportActions.getObOrderCountPerStatus),
            switchMap((action) => {
                return this.obOrderService.getCountPerStatus({ minDate: action.minDate, maxDate: action.maxDate }).pipe(
                    map((resp: any) => {
                        if (resp.status == 'success') {
                            return ReportActions.getObOrderCountPerStatusSuccess({
                                obOrderCount: new ObOrderCount().deserialize(resp.data),
                            });
                        } else {
                            return ReportActions.getObOrderCountPerStatusFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        return of(ReportActions.getObOrderCountPerStatusFailure({ error: error }));
                    })
                );
            })
        )
    );

    getAgcyAgencyCount = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ReportActions.getAgcyAgencyCount),
            switchMap((action) => {
                return this.agcyAgencyService.getAgencyCount().pipe(
                    map((resp: any) => {
                        if (resp.status == 'success') {
                            return ReportActions.getAgcyAgencyCountSuccess({
                                count: resp.data.count,
                            });
                        } else {
                            return ReportActions.getAgcyAgencyCountFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        return of(ReportActions.getAgcyAgencyCountFailure({ error: error }));
                    })
                );
            })
        )
    );

    getObAgencyCount = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ReportActions.getObAgencyCount),
            switchMap((action) => {
                return this.obAgencyService.getCountPerStatus().pipe(
                    map((resp: any) => {
                        if (resp.status == 'success') {
                            return ReportActions.getObAgencyCountSuccess({
                                obAgencyCount: new ObAgencyCount().deserialize(resp.data),
                            });
                        } else {
                            return ReportActions.getObAgencyCountFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        return of(ReportActions.getObAgencyCountFailure({ error: error }));
                    })
                );
            })
        )
    );

    getObAgencyContactCount = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ReportActions.getObAgencyContactCount),
            switchMap((action) => {
                return this.obAgencyContactService.getContactCountPerStatus().pipe(
                    map((resp: any) => {
                        if (resp.status == 'success') {
                            return ReportActions.getObAgencyContactCountSuccess({
                                obAgencyContactCount: new ObAgencyContactCount().deserialize(resp.data),
                            });
                        } else {
                            return ReportActions.getObAgencyContactCountFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        return of(ReportActions.getObAgencyContactCountFailure({ error: error }));
                    })
                );
            })
        )
    );

    getObAgencyContacts = createEffect(() => () =>
        this.actions$.pipe(
            ofType(ReportActions.getObAgencyContacts),
            switchMap((action) => {
                return this.obAgencyContactService
                    .getContacts({
                        limit: 3,
                        agencyHasAdmin: false,
                        channelObStatusCode: 'pending_approve',
                        isAdmin: false,
                    })
                    .pipe(
                        map((resp: any) => {
                            if (resp.status == 'success') {
                                return ReportActions.getObAgencyContactsSuccess({
                                    contacts: resp.data.map((item) => new ObAgencyContact().deserialize(item)),
                                });
                            } else {
                                return ReportActions.getObAgencyContactsFailure({ error: resp });
                            }
                        }),
                        catchError((error) => {
                            return of(ReportActions.getObAgencyContactsFailure({ error: error }));
                        })
                    );
            })
        )
    );
}
