import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENDPOINTS } from '../constants/endpoint.constants';
import { HttpProService } from './base/http-pro.service';

@Injectable({
    providedIn: 'root',
})
export class ObAgencyContactService {
    constructor(private httpService: HttpProService) {}

    public getContactCountPerStatus(): Observable<any> {
        const url = ENDPOINTS.OB_AGENCY.CONTACT.GET_COUNT_PER_STATUS;
        return this.httpService.get(url, true);
    }

    public getContacts(
        filters: {
            limit: number;
            agencyHasAdmin: boolean;
            channelObStatusCode: string;
            isAdmin: boolean;
        } = null
    ): Observable<any> {
        const url = ENDPOINTS.OB_AGENCY.CONTACT.GET_LIST;
        let params = new HttpParams();
        if (filters) {
            if (filters.limit) {
                params = params.set('db_limit', filters.limit);
            }
            if (filters.agencyHasAdmin !== null) {
                params = params.set('agency_has_admin', filters.agencyHasAdmin);
            }
            if (filters.channelObStatusCode) {
                params = params.set('channel_ob_status_code', filters.channelObStatusCode);
            }
            if (filters.isAdmin !== null) {
                params = params.set('is_admin', filters.isAdmin);
            }
        }

        return this.httpService.get(url + '?' + params.toString(), true);
    }
}
