export class ProductCount {
    domesticJoin: number = 0;
    domesticPackage: number = 0;
    outboundJoin: number = 0;
    outboundPackage: number = 0;
    voucher: number;

    public get outbound(): number {
        return this.outboundJoin + this.outboundPackage;
    }
    public get domestic(): number {
        return this.domesticJoin + this.domesticPackage;
    }

    public deserialize(item) {
        this.domesticJoin = item.domestic_join_tour_count;
        this.domesticPackage = item.domestic_package_tour_count;
        this.outboundJoin = item.outbound_join_tour_count;
        this.outboundPackage = item.outbound_package_tour_count;
        this.voucher = item.voucher_count;
        return this;
    }
}
