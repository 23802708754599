import { ProductCategoryId } from 'src/app/constants/product/product-category.contants';
import { DateTimeHelpers } from 'src/app/helpers/date-time.helpers';
import { Country } from '../../country.model';

export class DashBoardProductPeriod {
    public id: number;
    public groupCode: string;
    public startAt: Date;
    public endAt: Date;
    public quantity: number;
    public quantityTourLead: number;
    public goTransportation: {
        id: number;
        code: number;
    };
    public product: {
        id: number;
        subCategoryId: number;
        subCategorySlug: string;
        tourCode: string;
        countries: Country[];
    };

    public deserialize(data: any): this {
        this.id = data.id;
        this.groupCode = data.group_code;
        this.startAt = DateTimeHelpers.convertStringToDate(data.start_at);
        this.endAt = DateTimeHelpers.convertStringToDate(data.end_at);
        this.quantity = data.quantity;
        this.quantityTourLead = data.quantity_tour_lead;
        this.goTransportation = data.go_transportation
            ? {
                  id: data.go_transportation.id,
                  code: data.go_transportation.code,
              }
            : null;

        let subCategorySlug = '';
        if (
            [ProductCategoryId.JOIN_PROGRAM_OUTBOUND, ProductCategoryId.PACKAGE_PROGRAM_OUTBOUND].includes(
                data.product.product_sub_category_id
            )
        ) {
            subCategorySlug = 'outbound';
        } else if (
            [ProductCategoryId.JOIN_PROGRAM_DOMESTIC, ProductCategoryId.PACKAGE_PROGRAM_DOMESTIC].includes(
                data.product.product_sub_category_id
            )
        ) {
            subCategorySlug = 'domestic';
        }

        this.product = {
            id: data.product.id,
            subCategoryId: data.product.product_sub_category_id,
            subCategorySlug: subCategorySlug,
            tourCode: data.product.tour_code,
            countries: (data.product.countries as any[]).map((item) => new Country().deserialize(item)),
        };
        return this;
    }
}
