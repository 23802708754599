import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogoutPageComponent } from './modules/public/pages/logout-page/logout-page.component';
import { DashboardPageComponent } from './modules/report/pages/dashboard-page/dashboard-page.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { PageNotFoundComponent } from './themes/error/page-not-found/page-not-found.component';
import { MainComponent } from './themes/main/main.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
    },
    {
        path: '',
        canActivate: [AuthGuard],
        component: MainComponent,
        children: [
            {
                path: 'order',
                loadChildren: () => import('./modules/order/order.module').then((m) => m.OrderModule),
            },
            {
                path: 'product',
                loadChildren: () => import('./modules/product/product.module').then((m) => m.ProductModule),
            },
            {
                path: 'report',
                loadChildren: () => import('./modules/report/report.module').then((m) => m.ReportModule),
            },
            {
                path: 'dashboard',
                component: DashboardPageComponent,
            },
        ],
    },
    {
        path: 'logout',
        component: LogoutPageComponent,
    },
    {
        path: 'loading',
        component: MainComponent,
    },
    {
        path: '404',
        component: PageNotFoundComponent,
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'loading',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            relativeLinkResolution: 'legacy',
            // enableTracing: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
