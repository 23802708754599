export class ObOrderCount {
    public pendingApproveOrder: number;
    public waitingPaid: number;
    public complete: number;

    public deserialize(data: any): this {
        this.pendingApproveOrder = data.pending_approve_order;
        this.waitingPaid = data.waiting_paid;
        this.complete = data.complete;
        return this;
    }
}
