import * as _ from 'lodash-es';
import { ProductFilter } from './../../models/product/product-filter.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as ProductActions from './product.actions';

// Models
import { ProductCount } from 'src/app/models/product/product-count.model';
import { ProductList } from 'src/app/models/product/product-list.model';
import { ProductSingle } from 'src/app/models/product/product-single.model';
import { IProductFile } from 'src/app/models/product/product-file.model';
import { PeriodDetail } from 'src/app/models/product/product-period/period-detail.model';
import { ITag } from 'src/app/models/tag.model';
import { IProductManagementPageSettings } from 'src/app/models/product/product-managenent-page-settings.model';

export const productFeatureKey = 'product';

export interface ProductState {
    productsCount: ProductCount;
    productsCountError: any;
    productMonths: { month: number; year: number }[];
    productMonthsError: any;
    productList: ProductList;
    productListError: any;
    singleProduct: ProductSingle;
    singleProductSuccessId: number;
    singleProductUpdateSuccess: boolean;
    singleProductError: any;
    singlePeriod: PeriodDetail;
    singlePeriodCreateResponse: {
        success: boolean;
        periodId: number;
        error: any;
    };
    singlePeriodUpdateResponse: {
        success: boolean;
        error: any;
    };
    singlePeriodError: any;
    productImage: IProductFile;
    productImageError: any;
    productsFilter: ProductFilter[];
    productsFilterError: any;
    productManagementPageIsSubmitted: boolean;
    productManagementPageIsCanceled: boolean;
    productManagementPageSettings: IProductManagementPageSettings; // Obsoleted by PK
    productDeletionSuccess: boolean;
}

export const initialState: ProductState = {
    productsCount: null,
    productsCountError: null,
    productMonths: null,
    productMonthsError: null,
    productList: null,
    productListError: null,
    singleProduct: null,
    singleProductSuccessId: null,
    singleProductUpdateSuccess: null,
    singleProductError: null,
    singlePeriod: null,
    singlePeriodCreateResponse: null,
    singlePeriodUpdateResponse: null,
    singlePeriodError: null,
    productImage: null,
    productImageError: null,
    productsFilter: null,
    productsFilterError: null,
    productManagementPageIsSubmitted: false,
    productManagementPageIsCanceled: false,
    productManagementPageSettings: {
        displayDetailExtension: false,
        productForm: {
            tags: [],
            selectedCountries: [],
        },
    },
    productDeletionSuccess: null,
};

export const productReducer = createReducer(
    initialState,

    on(ProductActions.getProductsCount, (state) => {
        return { ...state };
    }),

    on(ProductActions.getProductsCountSuccess, (state, action) => {
        return { ...state, productsCount: action.productsCount };
    }),

    on(ProductActions.getProductsCountFailure, (state, action) => {
        return { ...state, productsCountError: action.error };
    }),

    on(ProductActions.getProductMonthsSuccess, (state, action) => {
        return { ...state, productMonths: action.months };
    }),

    on(ProductActions.getProductMonthsFailure, (state, action) => {
        return { ...state, productMonthsError: action.error };
    }),

    on(ProductActions.getProductListPaginated, (state) => {
        return { ...state };
    }),

    on(ProductActions.getProductListPaginatedSuccess, (state, action) => {
        if (!action.productList) {
            return { ...state, productList: null };
        }
        let products = state.productList ? [...state.productList.products] : [];
        if (!action.isNextPaginated) {
            products = action.productList.products;
        } else {
            products.push(...action.productList.products);
        }
        return {
            ...state,
            productList: new ProductList({
                page: action.productList.page,
                pageEntries: action.productList.pageEntries,
                total: action.productList.total,
                products: products,
            }),
        };
    }),

    on(ProductActions.getProductListPaginatedFailure, (state, action) => {
        return { ...state, productListError: action.error };
    }),

    on(ProductActions.getSingleProduct, (state) => {
        return { ...state };
    }),

    on(ProductActions.getSingleProductSuccess, (state, action) => {
        return {
            ...state,
            singleProduct: action.product,
        };
    }),

    on(ProductActions.getSingleProductFailure, (state, action) => {
        return { ...state, singleProductError: action.error };
    }),

    on(ProductActions.resetSingleProductFailure, (state) => {
        return { ...state, singleProductError: null };
    }),

    /** period reducer - start */
    /** Get */
    on(ProductActions.getSinglePeriod, (state) => {
        return { ...state };
    }),

    on(ProductActions.getSinglePeriodSuccess, (state, action) => {
        return {
            ...state,
            singlePeriod: action.period,
        };
    }),

    on(ProductActions.getSinglePeriodFailure, (state, action) => {
        return { ...state, singlePeriodError: action.error };
    }),

    /** Post */
    on(ProductActions.postNewPeriod, (state) => {
        return { ...state, singlePeriodCreateResponse: null };
    }),

    on(ProductActions.postNewPeriodResetResponse, (state) => {
        return { ...state, singlePeriodCreateResponse: null };
    }),

    on(ProductActions.postNewPeriodSuccess, (state, action) => {
        if (action.id) {
            return {
                ...state,
                singlePeriodCreateResponse: {
                    success: true,
                    periodId: action.id,
                    error: null,
                },
            };
        } else {
            return {
                ...state,
                singlePeriodCreateResponse: null,
            };
        }
    }),

    on(ProductActions.postNewPeriodFailure, (state, action) => {
        return {
            ...state,
            singlePeriodCreateResponse: {
                success: false,
                periodId: null,
                error: action.error,
            },
        };
    }),

    /** Update */
    on(ProductActions.updatePeriod, (state) => {
        return { ...state, singlePeriodUpdateResponse: null };
    }),

    on(ProductActions.updatePeriodResetResponse, (state) => {
        return { ...state, singlePeriodUpdateResponse: null };
    }),

    on(ProductActions.updatePeriodSuccess, (state, action) => {
        return {
            ...state,
            singlePeriodUpdateResponse: { success: true, error: null },
        };
    }),

    on(ProductActions.updatePeriodFailure, (state, action) => {
        return {
            ...state,
            singlePeriodUpdateResponse: { success: false, error: action.error },
        };
    }),

    /** period reducer - end */

    on(ProductActions.postNewProduct, (state, action) => {
        return { ...state, singleProductSuccessId: null, singleProductError: null };
    }),

    on(ProductActions.postNewProductSuccess, (state, action) => {
        return {
            ...state,
            singleProductSuccessId: action.productId,
            singleProductError: null,
        };
    }),

    on(ProductActions.postNewProductFailure, (state, action) => {
        return { ...state, singleProductError: action.error };
    }),

    on(ProductActions.postProductImage, (state, action) => {
        return { ...state, productImage: null, productImageError: null };
    }),

    on(ProductActions.postProductImageSuccess, (state, action) => {
        return {
            ...state,
            productImage: action.data,
            productImageError: null,
        };
    }),

    on(ProductActions.postProductImageFailure, (state, action) => {
        return { ...state, productImageError: action.error };
    }),

    on(ProductActions.getProductsFilter, (state) => {
        return { ...state };
    }),

    on(ProductActions.getProductsFilterSuccess, (state, action) => {
        return { ...state, productsFilter: action.productsFilter };
    }),

    on(ProductActions.getProductsFilterFailure, (state, action) => {
        return { ...state, countriesError: action.error };
    }),

    on(ProductActions.updateProduct, (state) => {
        return { ...state, singleProductUpdateSuccess: null, singleProductError: null };
    }),

    on(ProductActions.updateProductSuccess, (state) => {
        return { ...state, singleProductUpdateSuccess: true, singleProductError: null };
    }),

    on(ProductActions.updateProductFailure, (state, action) => {
        return { ...state, singleProductUpdateSuccess: false, singleProductError: action.error };
    }),

    on(ProductActions.updateProductDetails, (state, action) => {
        return { ...state, singleProductUpdateSuccess: null, singleProductError: null };
    }),

    on(ProductActions.updateProductDetailsSuccess, (state, action) => {
        return { ...state, singleProductUpdateSuccess: true, singleProductError: null };
    }),

    on(ProductActions.updateProductDetailsFailure, (state, action) => {
        return { ...state, singleProductUpdateSuccess: false, singleProductError: action.error };
    }),

    on(ProductActions.updateProductNoteSuccess, (state, action) => {
        const singleProduct = _.cloneDeep(state.singleProduct);
        singleProduct.note = action.note;
        return {
            ...state,
            singleProduct: singleProduct,
            singleProductUpdateSuccess: true,
        };
    }),

    on(ProductActions.updateProductNoteFailure, (state, action) => {
        return { ...state, singleProductUpdateSuccess: false, singleProductError: action.error };
    }),

    on(ProductActions.resetSingleProductUpdateSuccess, (state, action) => {
        return {
            ...state,
            singleProductUpdateSuccess: null,
        };
    }),

    on(ProductActions.postProductAttachmentSuccess, (state, action) => {
        const singleProduct: ProductSingle = _.cloneDeep(state.singleProduct);
        singleProduct.productAttachmentFiles.push(action.data);
        return {
            ...state,
            singleProduct: singleProduct,
        };
    }),

    on(ProductActions.deleteProductAttachmentSuccess, (state, action) => {
        const singleProduct: ProductSingle = _.cloneDeep(state.singleProduct);
        if (singleProduct.id === action.productId) {
            singleProduct.productAttachmentFiles = singleProduct.productAttachmentFiles.filter(
                (file) => file.fileId !== action.fileId
            );
        }
        return {
            ...state,
            singleProduct: singleProduct,
        };
    }),

    on(ProductActions.setDisplayDetailExtension, (state, action) => {
        const setting = _.cloneDeep(state.productManagementPageSettings);
        setting.displayDetailExtension = action.displayDetailExtension;
        return {
            ...state,
            productManagementPageSettings: setting,
        };
    }),

    on(ProductActions.addProductFormTags, (state, action) => {
        const setting = _.cloneDeep(state.productManagementPageSettings);
        const find = _.find(setting.productForm.tags, {
            text: action.tag.text,
            relateCountryId: action.tag.relateCountryId,
            isCountry: action.tag.isCountry,
        });
        if (!find) {
            setting.productForm.tags.push(action.tag);
        }

        return {
            ...state,
            productManagementPageSettings: setting,
        };
    }),

    on(ProductActions.removeProductFormTags, (state, action) => {
        const setting = _.cloneDeep(state.productManagementPageSettings);
        const find: ITag = _.find(setting.productForm.tags, { text: action.text });
        if (find !== undefined && find.isCountry) {
            _.remove(setting.productForm.tags, (tag) => tag.relateCountryId == find.relateCountryId);
        } else {
            _.remove(setting.productForm.tags, (tag) => tag.text == action.text);
        }

        return {
            ...state,
            productManagementPageSettings: setting,
        };
    }),

    on(ProductActions.resetProductForm, (state, action) => {
        const setting = _.cloneDeep(state.productManagementPageSettings);
        setting.productForm.tags = [];
        setting.productForm.selectedCountries = [];
        return {
            ...state,
            singleProductSuccessId: null,
            productManagementPageSettings: setting,
        };
    }),

    on(ProductActions.setProductFormCountry, (state, action) => {
        const setting = _.cloneDeep(state.productManagementPageSettings);
        const key = _.findKey(setting.productForm.selectedCountries, { id: action.country.id });
        if (!key) {
            setting.productForm.selectedCountries.push(action.country);
        } else {
            setting.productForm.selectedCountries[key] = action.country;
        }

        return {
            ...state,
            productManagementPageSettings: setting,
        };
    }),

    on(ProductActions.setProductFormCountries, (state, action) => {
        const setting = _.cloneDeep(state.productManagementPageSettings);
        action.countries.forEach((country) => {
            if (_.find(setting.productForm.selectedCountries, { id: country.id }) === undefined) {
                setting.productForm.selectedCountries.push(country);
            }
        });

        _.remove(
            setting.productForm.selectedCountries,
            (country) => _.find(action.countries, { id: country.id }) === undefined
        );

        return {
            ...state,
            productManagementPageSettings: setting,
        };
    }),

    on(ProductActions.deleteProduct, (state) => state),
    on(ProductActions.deleteProductSuccess, (state) => {
        return { ...state, productDeletionSuccess: true };
    }),
    on(ProductActions.deleteProduct, (state) => {
        return { ...state, productDeletionSuccess: false };
    }),
    on(ProductActions.deleteProduct, (state) => {
        return { ...state, productDeletionSuccess: null };
    }),

    on(ProductActions.setProductPageIsSubmitted, (state, action) => {
        return { ...state, productManagementPageIsSubmitted: action.isSubmitted };
    }),
    on(ProductActions.setProductPageIsCanceled, (state, action) => {
        return { ...state, productManagementPageIsCanceled: action.isCanceled };
    })
);

export function reducer(state: ProductState | undefined, action: Action) {
    return productReducer(state, action);
}
