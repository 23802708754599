import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appUnallowedCharacters]',
})
export class UnallowedCharactersDirective {
    @Input('appUnallowedCharacters') unallowedCharacters: string[];

    constructor(private el: ElementRef) {}

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        const e = event as KeyboardEvent;
        const key = e.key;
        if (this.unallowedCharacters.includes(key)) {
            e.preventDefault();
        }
    }
}
