import { Province } from './province.model';

export interface ICountry {
    id: number;
    nameTH: string;
    nameEN: string;
    iconUrl: string;
    provinces: Province[];

    deserialize(item): Country;
}

export class Country implements ICountry {
    public id: number;
    public nameTH: string;
    public nameEN: string;
    public iconUrl: string;
    public provinces: Province[];

    constructor() {
        this.provinces = [];
    }

    public deserialize(item): Country {
        this.id = item.id;
        this.nameTH = item.name_th;
        this.nameEN = item.name_en;
        this.iconUrl = item.icon_url;
        return this;
    }
}
