import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-error-modal',
    templateUrl: './error-modal.component.html',
    styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit, OnDestroy {
    
    message: string;

    constructor(public bsModalRef: BsModalRef, public bsModalService: BsModalService, private router: Router) {}

    ngOnInit(): void {
        this.closeOnRouteChange();
    }

    ngOnDestroy(): void {
        this.bsModalService.setDismissReason('refresh');
    }

    private closeOnRouteChange(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.bsModalRef.hide();
        });
    }
}
