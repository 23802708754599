<div
    class="form-group calendar"
    [class.for-add-booking-modal]="forAddBookingModalStyling"
    [style.margin-bottom]="settings.style.marginBottom"
>
    <div class="">
        <input
            type="text"
            class="form-control hidden"
            [bsConfig]="bsConfig"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [bsValue]="bsValue"
            (bsValueChange)="onChangeBsValue($event)"
            (keydown)="onKeydown($event)"
            bsDatepicker
            #dp="bsDatepicker"
            [style.height]="settings.style.height"
        />
        <!-- [value]="(setDateValue()) + (bsValue | thaiMonthDate)" -->
        <input
            readonly
            onkeypress="return false;"
            type="text"
            class="form-control"
            [placeholder]="placeholder"
            (click)="dp.toggle()"
            [value]="bsValue | thaiDatePipe: true:true"
            *ngIf="showOnlyMonth"
            [ngClass]="{ 'my-red-border': !valid }"
            [style.height]="settings.style.height"
            [style.font-size]="settings.style.fontSize"
        />
        <input
            readonly
            onkeypress="return false;"
            type="text"
            class="form-control"
            [placeholder]="placeholder"
            (click)="dp.toggle()"
            [value]="bsValue | thaiDatePipe: true:false"
            *ngIf="!showOnlyMonth"
            [ngClass]="{ 'my-red-border': !valid }"
            [style.height]="settings.style.height"
        />
        <img class="icon" src="assets/icons/icon-calendar.svg" />
    </div>
</div>
