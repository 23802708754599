<div class="modal-header">
    <button type="button" class="close" aria-label="Close" *ngIf="hasCloseButton" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div [ngClass]="{ 'success-tick': isSuccessful, 'unsuccess-tick': !isSuccessful }"></div>
    <h3>{{ message || 'บันทึกข้อมูลเรียบร้อยแล้ว' }}</h3>
    <p class="description" *ngIf="description">
        {{ description.text }}
        <a *ngIf="description.linkText" [routerLink]="[description.linkUrl || '/']">
            {{ description.linkText }}
        </a>
    </p>
</div>
<div class="modal-footer">
    <div class="w-100 d-flex align-items-center justify-content-center">
        <button type="button" class="btn btn-primary" (click)="bsModalRef.hide()">{{ buttonText }}</button>
    </div>
</div>
