import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash-es';

import { ITag } from '../models/tag.model';

@Pipe({
    name: 'convert2StringArray',
})
export class Convert2StringArrayPipe implements PipeTransform {
    transform(tags: ITag[]): string[] {
        tags = _.uniqBy(tags, 'text');
        if (tags) {
            return tags.map((tag) => tag.text);
        }

        return [];
    }
}
