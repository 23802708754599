import { Component, Input, OnInit } from '@angular/core';
import { IBreadcrumb } from 'src/app/models/breadcrumb.model';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
    @Input() items: IBreadcrumb[] = [];
    breadcrumb: string[];
    constructor() {}

    ngOnInit(): void {
        this.breadcrumb = this.items.map((item) => item.name);
    }
}
