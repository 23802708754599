import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENDPOINTS } from '../constants/endpoint.constants';
import { HttpProService } from './base/http-pro.service';

@Injectable({
    providedIn: 'root',
})
export class ProductOrderService {
    constructor(private httpService: HttpProService) {}

    getCountProductSubCategory(searchString?: string): Observable<any> {
        const url = ENDPOINTS.PRODUCT_ORDER.GET_COUNT_PRODUCT_SUB_CATEGORY;
        let params = new HttpParams();
        if (searchString) {
            params = params.set('search_string', searchString);
        }

        return this.httpService.get(`${url}?${params.toString()}`);
    }

    getCountOrderStatus(
        productSubCategorySlug: 'all' | 'outbound' | 'domestic',
        searchString?: string
    ): Observable<any> {
        const url = ENDPOINTS.PRODUCT_ORDER.GET_COUNT_ORDER_STATUS;
        let params = new HttpParams();
        if (productSubCategorySlug) {
            params = params.set('product_sub_category_slug', productSubCategorySlug);
        }
        if (searchString) {
            params = params.set('search_string', searchString);
        }

        return this.httpService.get(`${url}?${params.toString()}`);
    }

    getProductOrderListPaginated(
        orderStatusSlug: 'waiting_confirm' | 'waiting_first_paid' | 'waiting_full_paid' | 'complete' | 'cancel',
        page?: number,
        pageEntries?: number,
        productSubCategorySlug?: 'all' | 'outbound' | 'domestic',
        searchString?: string,
        sortBy?: 'created_at_desc'
    ): Observable<any> {
        const url = ENDPOINTS.PRODUCT_ORDER.GET_ORDER_LIST_PAGINATED;
        let params = new HttpParams();
        if (orderStatusSlug) {
            params = params.set('order_status_slug', orderStatusSlug);
        }
        if (page) {
            params = params.set('page', page);
        }
        if (pageEntries) {
            params = params.set('page_entries', pageEntries);
        }
        if (productSubCategorySlug) {
            params = params.set('product_sub_category_slug', productSubCategorySlug);
        }
        if (searchString) {
            params = params.set('search_string', searchString);
        }
        if (sortBy) {
            params = params.set('sort_by', sortBy);
        }

        return this.httpService.get(`${url}?${params.toString()}`);
    }
}
