export enum ProductCategoryId {
    JOIN_PROGRAM_OUTBOUND = 1,
    PACKAGE_PROGRAM_OUTBOUND = 2,
    PACKAGE_PROGRAM_DOMESTIC = 6,
    JOIN_PROGRAM_DOMESTIC = 7,
}

export const CATEGORY_SUB_PRODUCTS_TO_STRING = {
    1: 'Outbound Join Tour',
    2: 'Outbound Package Tour',
    6: 'Domestic Tour',
    7: 'Domestic Join Tour',
};
