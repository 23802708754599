import { CategoryTransport } from '../models/category-transport.model';

export enum TransportTypeId {
    Unknown = 0,
    Plane = 1,
    Train = 3,
    Bus = 6,
    Van = 9,
    Car = 10,
    Boat = 14,
}

export enum TransportTypeName {
    Plane = 'เครื่องบิน',
    Train = 'รถไฟ',
    Bus = 'รถบัส',
    Van = 'รถตู้',
    Car = 'รถยนต์',
    Boat = 'เรือ',
}

export const TransportCategories = [
    new CategoryTransport().deserialize({
        id: TransportTypeId.Plane,
        slug: TransportTypeName.Plane,
    }),
    new CategoryTransport().deserialize({
        id: TransportTypeId.Bus,
        slug: TransportTypeName.Bus,
    }),
    new CategoryTransport().deserialize({
        id: TransportTypeId.Train,
        slug: TransportTypeName.Train,
    }),
    new CategoryTransport().deserialize({
        id: TransportTypeId.Boat,
        slug: TransportTypeName.Boat,
    }),
    new CategoryTransport().deserialize({
        id: TransportTypeId.Van,
        slug: TransportTypeName.Van,
    }),
    new CategoryTransport().deserialize({
        id: TransportTypeId.Car,
        slug: TransportTypeName.Car,
    }),
];
