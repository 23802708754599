import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { WholesaleService } from 'src/app/services/wholesale.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private authService: AuthenticationService,
        private wholesaleService: WholesaleService
    ) {
        this.authService.fetchSupplierDataFromToken();
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            const token = params['token'];
            let redirectPage = params['redirectPage'];

            if (token) {
                this.authService.saveJWTToken(token);
                this.authService.fetchSupplierDataFromToken();

                if (redirectPage) {
                    redirectPage = '/' + this.wholesaleService.getSupplierSlug() + redirectPage;
                    window.location.replace(redirectPage);
                }
            } else if (!this.authService.isLoggetIn()) {
                this.authService.logout();
            }
        });
    }
}
