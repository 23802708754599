import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReport from './report.reducer';

export const selectReportState = createFeatureSelector<fromReport.ReportState>(fromReport.reportFeatureKey);

export const selectActiveProductsCount = createSelector(
    selectReportState,
    (state: fromReport.ReportState) => state.activeProductCount
);

export const selectContinents = createSelector(selectReportState, (state: fromReport.ReportState) => state.continents);

export const selectRegions = createSelector(selectReportState, (state: fromReport.ReportState) => state.regions);

export const selectDashboardProductPeriods = createSelector(
    selectReportState,
    (state: fromReport.ReportState) => state.productPeriods
);

export const selectObOrderCount = createSelector(
    selectReportState,
    (state: fromReport.ReportState) => state.obOrderCount
);

export const selectAgcyAgencyCount = createSelector(
    selectReportState,
    (state: fromReport.ReportState) => state.agcyAgencyCount
);

export const selectObAgencyCount = createSelector(
    selectReportState,
    (state: fromReport.ReportState) => state.obAgencyCount
);
export const selectObAgencyContactCount = createSelector(
    selectReportState,
    (state: fromReport.ReportState) => state.obAgencyContactCount
);
export const selectObAgencyContacts = createSelector(
    selectReportState,
    (state: fromReport.ReportState) => state.obAgencyContacts
);
