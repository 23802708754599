import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'joinText',
})
export class JoinTextPipe implements PipeTransform {
    transform(textList: any[], key: string, separator?: string): string {
        if (textList) {
            return textList.map((m) => m[key]).join(separator || ' - ');
        }
        return '';
    }
}
