import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENDPOINTS } from '../constants/endpoint.constants';
import { DateTimeHelpers } from '../helpers/date-time.helpers';
import { PeriodDetail } from '../models/product/product-period/period-detail.model';
import { HttpProService } from './base/http-pro.service';

@Injectable({
    providedIn: 'root',
})
export class ProductPeriodService {
    constructor(private httpService: HttpProService, private http: HttpClient) {}

    public getPeriods(
        filters: {
            minDate: Date;
            maxDate: Date;
        } = null
    ): Observable<any> {
        const url: string = ENDPOINTS.PRODUCT.PERIOD.GET_LIST;

        let params = new HttpParams();
        if (filters) {
            params = params.set(
                'filters',
                JSON.stringify({
                    date_range: {
                        min_date: filters.minDate ? DateTimeHelpers.convertDateToYMDString(filters.minDate) : null,
                        max_date: filters.maxDate ? DateTimeHelpers.convertDateToYMDString(filters.maxDate) : null,
                    },
                })
            );
        }

        return this.httpService.get(url + '?' + params.toString(), true);
    }

    public getSinglePeriod(productId: number, periodId: number): Observable<any> {
        const url: string = ENDPOINTS.PRODUCT.PERIOD.GET_PERIOD_DETAILS.replace('{product_id}', productId).replace(
            '{period_id}',
            periodId
        );
        return this.httpService.get(url, true);
    }

    public createPeriod(productId: number, period: PeriodDetail): Observable<any> {
        const url: string = ENDPOINTS.PRODUCT.PERIOD.POST_NEW_PERIOD.replace('{product_id}', productId);
        const body = period.serialize();
        return this.httpService.post(url, body, true);
    }

    public updatePeriod(productId: number, periodId: number, period: PeriodDetail): Observable<any> {
        const url: string = ENDPOINTS.PRODUCT.PERIOD.UPDATE_PERIOD.replace('{product_id}', productId).replace(
            '{period_id}',
            periodId
        );
        const body = period.serialize();
        return this.httpService.put(url, body, true);
    }

    public verifyPeriod(productId: number, periodId: number, updatedAt: string): Observable<{data: boolean, message: string, status: string}> {
        const url: string = ENDPOINTS.PRODUCT.PERIOD.VERIFY_PERIOD.replace('{product_id}', productId).replace(
            '{period_id}',
            periodId
        );
        const body = { updated_at: updatedAt };
        return this.httpService.put(url, body, true);
    }

    public deletePeriod(productId: number, periodId: number): Observable<any> {
        const url: string = ENDPOINTS.PRODUCT.PERIOD.DELETE_PERIOD.replace('{product_id}', productId).replace(
            '{period_id}',
            periodId
        );
        return this.httpService.delete(url, true);
    }
}
