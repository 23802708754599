import { Injectable } from '@angular/core';
import { retry, timeout } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class HttpProService {
    constructor(private http: HttpClient, private authService: AuthenticationService) {}

    // get
    public get(url: string, hasAuthorization: boolean = true): Observable<any> {
        return new Observable((observer) => {
            this.getRequestOptions(hasAuthorization).subscribe((headers) => {
                this.http
                    .get(environment.apiProWsUrl + url, headers)
                    .pipe(timeout(30000), retry(5))
                    .subscribe((result) => {
                        if (result['status'] === 'error' && result['error']['code']) {
                            this.redirectToErrorPage(observer, result);
                        } else {
                            observer.next(result);
                            observer.complete();
                        }
                    }, this.onHttpRequestFail);
            }, this.onCannotCreateRequestHeader);
        });
    }

    // post
    public post(url: string, body: any, hasAuthorization: boolean = true): Observable<any> {
        return new Observable((observer) => {
            this.getRequestOptions(hasAuthorization).subscribe((headers) => {
                this.http
                    .post(environment.apiProWsUrl + url, body, headers)
                    .pipe(retry(5))
                    .subscribe((result) => {
                        if (result['status'] === 'error') {
                            this.redirectToErrorPage(observer, result);
                        }

                        observer.next(result);
                    }, this.onHttpRequestFail);
            }, this.onCannotCreateRequestHeader);
        });
    }

    // post
    public postWithFormData(url: string, body: any, hasAuthorization: boolean = true): Observable<any> {
        return new Observable((observer) => {
            this.getRequestOptions(hasAuthorization, false).subscribe((headers) => {
                this.http
                    .post(environment.apiProWsUrl + url, body, headers)
                    .pipe(retry(5))
                    .subscribe((result) => {
                        if (result['status'] === 'error') {
                            this.redirectToErrorPage(observer, result);
                        }

                        observer.next(result);
                    }, this.onHttpRequestFail);
            }, this.onCannotCreateRequestHeader);
        });
    }

    // put
    public put(url: string, body: any, hasAuthorization: boolean = true): Observable<any> {
        return new Observable((observer) => {
            this.getRequestOptions(hasAuthorization).subscribe((headers) => {
                this.http
                    .put(environment.apiProWsUrl + url, body, headers)
                    .pipe(retry(5))
                    .subscribe((result) => {
                        if (result['status'] === 'error') {
                            this.redirectToErrorPage(observer, result);
                        }

                        observer.next(result);
                    }, this.onHttpRequestFail);
            }, this.onCannotCreateRequestHeader);
        });
    }

    // delete
    public delete(url: string, hasAuthorization: boolean = true): Observable<any> {
        return new Observable((observer) => {
            this.getRequestOptions(hasAuthorization).subscribe((headers) => {
                this.http
                    .delete(environment.apiProWsUrl + url, headers)
                    .pipe(retry(5))
                    .subscribe((result) => {
                        if (result['status'] === 'error') {
                            this.redirectToErrorPage(observer, result);
                        } else {
                            observer.next(result);
                        }
                    }, this.onHttpRequestFail);
            }, this.onCannotCreateRequestHeader);
        });
    }

    private redirectToErrorPage(observer, result) {
        console.error(result['error']['code']);
        // switch (result['error']['code']) {
        //     case 401:
        //         this.router.navigate(['401']);
        //         break;
        //     case 404:
        //         this.router.navigate(['404']);
        //         break;
        //     case 500:
        //         this.router.navigate(['500']);
        //         break;
        // }
        observer.error(result);
        observer.complete();
    }

    // custom request
    public request(request: Request, headers: any): Observable<any> {
        const newUrl = { url: environment.apiProWsUrl + request.url };
        request = Object.assign(request, newUrl);
        headers.responseType = 'json';
        return this.http.request(request.method.toString(), request.url, headers);
    }

    public getRequestOptions(hasAuthorization: boolean, withContentTypeJson: boolean = true): Observable<any> {
        return new Observable((observer) => {
            const httpHeaders = new HttpHeaders({
                Accept: 'application/json',
                'Content-Type': 'application/json',
            });

            const requestOptions = {
                headers: withContentTypeJson ? httpHeaders : new HttpHeaders(),
                responseType: 'json',
            };

            if (!hasAuthorization) {
                observer.next(requestOptions);
                observer.complete();
            }

            const token = this.authService.getJWTToken();
            if (token) {
                requestOptions.headers = requestOptions.headers.set('Authorization', token);
                observer.next(requestOptions);
                observer.complete();
            }

            observer.next(requestOptions);
            observer.complete();
        });
    }

    private onHttpRequestFail(reason: any): Observable<any> {
        return throwError(reason);
    }

    private onCannotCreateRequestHeader(reason: any): Observable<any> {
        return throwError(reason);
    }

    public getQueryString(obj: any): string {
        const params = new URLSearchParams();
        for (const key in obj) {
            let param = obj[key];
            if (typeof param === 'object') {
                param = JSON.stringify(param);
            }
            params.set(key, param);
        }
        return params.toString();
    }
}
