import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENDPOINTS } from '../constants/endpoint.constants';
import { HttpProService } from './base/http-pro.service';

@Injectable({
    providedIn: 'root',
})
export class AgcyAgencyService {
    constructor(private httpService: HttpProService) {}

    public getAgencyCount(): Observable<any> {
        const url = ENDPOINTS.AGCY_AGENCY.GET_COUNT;
        return this.httpService.get(url, true);
    }
}
