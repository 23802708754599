<div id="sidenav-container">
    <figure id="tourwow-pro-logo">
        <img src="assets/images/logoTourwowPro_white.png" alt="Tourwow Pro Backoffice 2.0 {{ appVersion }}" />
    </figure>
    <div id="user-wrapper" class="text-center">
        <div id="logo"></div>
        <div *ngIf="profile$ | async as profile" id="user">
            <span>{{ profile.supplier?.nameTH }}</span>
            <br />
            <span *ngIf="profile.nickName">({{ profile.nickName }})</span>
        </div>
    </div>

    <!-- Nav menu -->
    <div id="sidenav">
        <div class="main-menu">
            <a class="menu-wrap" [routerLink]="['/dashboard']">
                <i class="menu-icon icon icon-dashboard-white"></i>
                <span>หน้าแรก</span>
            </a>
        </div>
        <div class="main-menu">
            <a class="menu-wrap" [routerLink]="['/product/management']">
                <i class="menu-icon fas fa-suitcase"></i>
                <span>จัดการข้อมูลสินค้า</span>
            </a>
        </div>
        <div class="main-menu">
            <a [href]="redirectToV1('agency/send-link-register')" class="menu-wrap">
                <i class="menu-icon fas fa-address-card"></i>
                <span>ส่ง Link สมัคร Online Booking</span>
            </a>
        </div>
        <div class="main-menu">
            <a class="menu-wrap" (click)="onToggleSubmenu('sales')">
                <i class="menu-icon far fa-handshake"></i>
                <span>งานขาย</span>
                <i class="fas fa-chevron-right chevron-icon" [ngClass]="{ spin: !salesManagementCollapse }"></i>
            </a>
            <div class="sub-menu" [ngClass]="{ collapse: salesManagementCollapse }">
                <a [href]="redirectToV1('order/new-list')" class="sub-menu-wrap">Order จาก Online Booking</a>
                <a [href]="redirectToV1('order/overdue-list')" class="sub-menu-wrap">Order เกินกำหนดชำระเงิน</a>
                <a [routerLink]="['/order/program-list']" class="sub-menu-wrap" *ngIf="showTwmOrderMenu">
                    Order จาก Tourwowmall
                </a>
            </div>
        </div>
        <div class="main-menu">
            <a class="menu-wrap" (click)="onToggleSubmenu('account')">
                <i class="menu-icon fas fa-money-bill"></i>
                <span>งานบัญชี-การเงิน</span>
                <i class="fas fa-chevron-right chevron-icon" [ngClass]="{ spin: !accountManagementCollapse }"></i>
            </a>
            <div class="sub-menu" [ngClass]="{ collapse: accountManagementCollapse }">
                <a [href]="redirectToV1('order/payment-list')" class="sub-menu-wrap">
                    ข้อมูลงวดชำระเงิน Online Booking
                </a>
                <a [href]="redirectToV1('order/transaction-list')" class="sub-menu-wrap">
                    ข้อมูลแจ้งชำระเงิน Online Booking
                </a>
            </div>
        </div>
        <div class="main-menu">
            <a class="menu-wrap" (click)="onToggleSubmenu('agency')">
                <i class="menu-icon fas fa-users"></i>
                <span>ข้อมูล Agency</span>
                <i class="fas fa-chevron-right chevron-icon" [ngClass]="{ spin: !agencyManagementCollapse }"></i>
            </a>
            <div class="sub-menu" [ngClass]="{ collapse: agencyManagementCollapse }">
                <a [href]="redirectToV1('agency/list')" class="sub-menu-wrap">Agency ทั้งหมด</a>
                <a [href]="redirectToV1('agency/member/list')" class="sub-menu-wrap">สมาชิก Online Booking</a>
            </div>
        </div>
        <div class="main-menu">
            <a class="menu-wrap" (click)="onToggleSubmenu('download')">
                <i class="menu-icon fa fa-table"></i>
                <span>ดาวน์โหลดข้อมูล</span>
                <i class="fas fa-chevron-right chevron-icon" [ngClass]="{ spin: !downloadManagementCollapse }"></i>
            </a>
            <div class="sub-menu" [ngClass]="{ collapse: downloadManagementCollapse }">
                <a [href]="redirectToV1('report/order/download')" class="sub-menu-wrap">ข้อมูล Order</a>
                <a [href]="redirectToV1('report/transaction/download')" class="sub-menu-wrap">ข้อมูลการชำระเงิน</a>
            </div>
        </div>
        <hr />
        <div class="main-menu">
            <a class="menu-wrap" (click)="logout()"> <i class="menu-icon fas fa-sign-out-alt"></i>ออกจากระบบ </a>
        </div>
    </div>
</div>
