import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { Tag } from 'src/app/models/tag.model';
import { ConfirmDeleteTagModalComponent } from 'src/app/modules/product/modals/confirm-delete-tag-modal/confirm-delete-tag-modal.component';
import { State } from 'src/app/stores/reducers';

@Component({
    selector: 'app-tags-input',
    templateUrl: './tags-input.component.html',
    styleUrls: ['./tags-input.component.scss'],
})
export class TagsInputComponent {
    @Input() tags: Tag[] = [];
    @Input() placeholder: string = '';
    @Input() hidingInputBox: boolean = false;
    @Output() tagChange = new EventEmitter<Tag[]>();
    @Output() tagRemove = new EventEmitter<Tag>();
    @ViewChild('inputTag') inputTag: ElementRef;

    tag: string = null;

    constructor(protected store: Store<State>, protected bsModalService: BsModalService) {}

    onEnter(event: any): void {
        let value: string = event.target.value;
        value = value.trim();
        if (!value) {
            return;
        }

        const tags = value.split(',');
        tags.forEach((value) => {
            if (!value) {
                return;
            }

            if (!this.tags.some((t) => t.text === value)) {
                const newTag = new Tag();
                newTag.text = value.trim();
                this.tags = [...this.tags, newTag];
                this.tagChange.emit(this.tags);
            }
        });

        this.tag = null;
    }

    removeTag(tag: Tag): void {
        if (tag.isCountry) {
            return;
        }

        const confirmDeleteModal = this.bsModalService.show(ConfirmDeleteTagModalComponent, {
            class: 'modal-delete-tag modal-dialog-centered',
            ignoreBackdropClick: true,
            initialState: {
                title: '',
                areYouSureText: 'คุณแน่ใจหรือว่าต้องการลบคำที่เกี่ยวข้องนี้ ?',
            },
        });

        confirmDeleteModal.content.confirmed.pipe(take(1)).subscribe(() => {
            this.tags = this.tags.filter((t) => t !== tag);
            this.tagChange.emit(this.tags);
            this.tagRemove.emit(tag);
        });
    }

    focusInputTag(): void {
        this.inputTag.nativeElement.focus();
    }
}
