import { createAction, props } from '@ngrx/store';

// Models
import { IWholesale } from 'src/app/models/wholesale.model';

export enum ActionTypes {
    UPDATE_PROFILE = '[Wholesale] Update wholesale profile.',
}

export const updateProfile = createAction(ActionTypes.UPDATE_PROFILE, props<{ data: IWholesale }>());
