import {
    Component,
    Input,
    Output,
    EventEmitter,
    HostListener,
    ElementRef,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
    SimpleChanges,
    ViewChild,
    OnChanges,
} from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-searchable-dropdown',
    templateUrl: './searchable-dropdown.component.html',
    styleUrls: ['./searchable-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchableDropdownComponent implements OnInit, OnDestroy, OnChanges {
    /**
     * The constructor
     */
    constructor(private context: ElementRef) {}

    @ViewChild('mainInput') mainInput;

    /**
     * The dropdown settings
     */
    @Input() settings: SearchableDropdownSettings;

    /**
     * dropdown disabled
     */
    @Input() disabled = false;

    /**
     * dropdown unclickable
     */
    @Input() unclickable = false;

    /**
     * Displaying red border or not
     */
    @Input() isBorderRed = false;

    /**
     * The dropdown items
     */
    @Input() items: SearchableDropdownItem[] = [];
    items$: BehaviorSubject<SearchableDropdownItem[]>;

    /**
     * The output
     */
    @Output() selected: EventEmitter<number[]> = new EventEmitter();

    /**
     * The state of select all output
     */
    isAllChecked = false;

    /**
     * The selected items
     */
    selectedItems$: BehaviorSubject<SearchableDropdownItem[]>;

    /**
     * The state of filtering
     */
    isFiltering = false;

    /**
     * The current filter text
     */
    filterText = '';

    /**
     * The accordion state
     */
    isExpand = false;

    /**
     * The raw data of items
     */
    private _items: SearchableDropdownItem[];

    /**
     * The unsubscription
     */
    private itemsSubscription: Subscription;

    /**
     * On init
     */
    ngOnInit(): void {
        this.initState();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.itemsSubscription.unsubscribe();
    }

    /**
     * On change
     */
    ngOnChanges(changes: SimpleChanges): void {
        if ('items' in changes) {
            const itemsChange = changes.items;
            if (!itemsChange.firstChange) {
                this.initState();
            }
        }
    }

    /**
     * On open dropdown
     */
    openDropdown(): void {
        if (!this.disabled) {
            if (!this.isExpand) {
                this.filterText = '';
                this.filterItems(this.filterText);
                this.updateState();
            }
            this.isExpand = !this.isExpand;
        }
    }

    /**
     * Select all items
     */
    selectAllItems(isChecked: boolean): void {
        this.isAllChecked = isChecked;
        this.selectedItems$.next([]);
        this.items.forEach((item) => {
            item.isChecked = item.preselectedFixed ? true : isChecked;
            this.addOrRemoveItem(item);
        });
        this.selected.emit(this.selectedItems$.value.map((v) => v.id));
    }

    /**
     * Select specific item
     */
    selectItem(item: SearchableDropdownItem): void {
        if (this.settings.multipleSelection && item.isChecked) {
            item.isChecked = false;
        } else {
            item.isChecked = true;
        }

        this.addOrRemoveItem(item);
        this.selected.emit(this.selectedItems$.value.map((v) => v.id));

        if (this.settings.closeAfterSelect) {
            this.openDropdown();
        }
    }

    /**
     * Remove the item from listing
     */
    removeItem(item: SearchableDropdownItem, event: any): void {
        item.isChecked = false;
        this.addOrRemoveItem(item);
        this.selected.emit(this.selectedItems$.value.map((v) => v.id));
        event.stopPropagation();
    }

    /**
     * Typing to search item
     */
    filterItems(value: string): void {
        if (this.isFiltering) {
            return;
        }
        this.isFiltering = true;
        this.filterSelectedItems(value).subscribe(() => this.setSelectedItems());
    }

    /**
     * Filter by keyword
     */
    private filterSelectedItems(keyword: string): Observable<void> {
        return new Observable((observer) => {
            if (keyword) {
                const filteredItems = this.items.filter((item) => {
                    if (this.settings.searchBoxIgnoreCase) {
                        return item.name.toUpperCase().includes(keyword.toUpperCase());
                    }
                    return item.name.includes(keyword);
                });
                this.items$.next(filteredItems);
            } else {
                this.items$.next(this.items);
            }
            observer.next();
        });
    }

    /**
     * set checked to items
     */
    private setSelectedItems(): void {
        const selectedIds = this.selectedItems$.value.map((v) => v.id);
        this.items.forEach((dropDownItem) => {
            dropDownItem.isChecked = selectedIds.indexOf(dropDownItem.id) !== -1;
        });
        this.isFiltering = false;
    }

    /**
     * Add or remove item
     */
    private addOrRemoveItem(item: SearchableDropdownItem): void {
        if (item.isChecked) {
            if (!this.settings.multipleSelection) {
                this.selectedItems$.next([]);
                this.items.forEach((currentLoopItem) => {
                    if (currentLoopItem.id !== item.id) {
                        currentLoopItem.isChecked = false;
                    }
                });
            }
            this.selectedItems$.value.push(item);
            if (this.selectedItems$.value.length === this.items.length) {
                this.isAllChecked = true;
            }
        } else {
            const indexItems = this.selectedItems$.value.findIndex((v) => v.id === item.id);
            this.selectedItems$.value.splice(indexItems, 1);
            if (this.selectedItems$.value.length === 0) {
                this.isAllChecked = false;
            }
        }
    }

    /**
     * Initializing various state
     */
    private initState(): void {
        this.items$ = new BehaviorSubject(this.items);
        this.selectedItems$ = new BehaviorSubject([]);
        this.itemsSubscription = this.items$.pipe(debounceTime(1000)).subscribe(() => this.updateState());
    }

    /**
     * Update selected items
     */
    private updateState(): void {
        this.selectedItems$.next(this.items.filter((dropDownItem) => dropDownItem.isChecked));
        this._items = this.items;
        let hasNonPrefixedItem = false;
        this.items.forEach((dropDownItem) => {
            if (!dropDownItem.preselectedFixed) {
                hasNonPrefixedItem = true;
            }
        });
        if (!hasNonPrefixedItem) {
            this.isAllChecked = false;
        }
    }

    @HostListener('document:click', ['$event.target'])
    onClick(targetElement) {
        const clickedInside = this.context.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.isExpand = false;
        }
    }

    countOverflow(): number {
        let hiddenCount = 0;

        if (this.mainInput) {
            const items = this.mainInput.nativeElement.getElementsByClassName('searchable-dropdown-view-selected');
            for (let i = 0; i < items.length; i++) {
                if (!this.isElementVisible(items[i])) {
                    hiddenCount++;
                }
            }
        }

        return hiddenCount;
    }

    isElementVisible(el: Element): boolean {
        const rect = el.getBoundingClientRect();
        const elemTop = rect.top;
        const elemBottom = rect.bottom;

        const parentRect = el.parentElement.getBoundingClientRect();
        const parentElemTop = parentRect.top;
        const parentElemBottom = parentRect.bottom;

        const isVisible = elemTop >= parentElemTop && elemBottom <= parentElemBottom;
        return isVisible;
    }

    refresh(): void {
        this.updateState();
    }
}

/**
 * The searchable dropdown settings
 */
export class SearchableDropdownSettings {
    dropdownListPlaceholder?: string;
    hideSearchBox: boolean = false;
    searchBoxIgnoreCase: boolean = false;
    searchBoxPlaceholder?: string;
    dropdownListAllItemsLabel?: string;
    multipleSelection: boolean = true;
    closeAfterSelect: boolean = false;
    customSearchIcon: string = 'icon-location';
    styleSetting: SearchableDropdownStyleSetting = new SearchableDropdownStyleSetting();
    have2Rows: boolean = false;
    unableToUncheck: boolean = false;
    underlineAfterFirstItem: boolean = false;
    hideSelectedItemsTags: boolean = false;
    dropdownlistHeight: number = 400;
    displayPreSelectName: boolean = false;
    customSelectItem: string = '';
}

export class SearchableDropdownStyleSetting {
    fontSize?: string;
    searchBoxPlaceholderFontSize?: string;
    height?: string;
    width?: string;
}

/**
 * The searchable dropdown item
 */
export class SearchableDropdownItem {
    id: any;
    name: string;
    value?: any = null;
    imageUrl?: string;
    isChecked?: boolean;
    preselectedFixed?: boolean;
    preSelectName?: string;
    groupId?: number | null;

    constructor({
        id,
        name,
        value,
        imageUrl,
        isChecked,
        preselectedFixed,
        preSelectName,
        groupId
    }: {
        id: any;
        name: string;
        value?: any;
        imageUrl?: string;
        isChecked?: boolean;
        preselectedFixed?: boolean;
        preSelectName?: string;
        groupId?: number | null;
    }) {
        this.id = id;
        this.name = name;
        this.value = value;
        this.imageUrl = imageUrl;
        this.isChecked = isChecked;
        this.preselectedFixed = preselectedFixed;
        this.preSelectName = preSelectName;
        this.groupId = groupId;
    }
}
