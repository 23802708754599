import { CategoryTransport } from '../category-transport.model';
import { Province } from '../province.model';
import { Country } from '../country.model';
import { ProductDescription } from './product-description.model';
import { Transportation } from '../transportation.model';
import { IProductAttachmentFile, ProductAttachmentFile } from './product-attachment-file.model';
import { Tag } from '../tag.model';
import { ProductActionLog } from './product-action-log.model';
import { NumberHelper } from 'src/app/helpers/number-helper';
import { ProductTourwowConfig } from './product-tourwow-config.model';
import { FirstPricePeriod } from './product-period/first-price-period';
import { TwProductFile } from './tw-product-file.model';
import { ITwBannerImageFile } from '../interfaces/tw-banner-image-file.interface';
import { IKeywordPaginated } from 'src/app/modules/product/tabs/tourwow-landmark-tab/interfaces/keyword-paginated.interface';

export class ProductSingle {
    id: number;
    name: string;
    tourCode: string;
    countries: Country[];
    provinces: Province[];
    durationDay: number;
    durationNight: number;
    price: number;
    firstPricePeriod: FirstPricePeriod;
    startAt: Date;
    endAt: Date;
    commissionCompany: number;
    commissionSeller: number;
    categoryTransport: CategoryTransport;
    transportation: Transportation;
    bannerImageFiles: IBannerImageFile[];
    firstActivePeriod: FirstActivePeriod;
    docFileUrl: string;
    pdfFileUrl: string;
    hilightDescription: string;
    tags: Tag[];
    tourCondition: string;
    note: string;
    updatedAt: Date;
    productAttachmentFiles: IProductAttachmentFile[];
    periods: ProductSinglePeriod[];
    descriptions: ProductDescription[];
    actionLogs: ProductActionLog[];
    tourwowConfig: ProductTourwowConfig;
    twName: string | null;
    twHilightDescription: string | null;
    twBannerImageFiles: TwProductFile[] | [];

    hasVisa: boolean;
    visaPrice: number | null;
    hotelStar: number | null;
    mealAmount: number | null;
    freeDay: number | null;
    //

    keywords: IKeywordPaginated[];

    public deserialize(item: any) {
        this.id = item.id;
        this.name = item.name;
        this.tourCode = item.tour_code;
        this.countries = item.countries.map((p) => new Country().deserialize(p));
        this.provinces = item.provinces.map((p) => new Province().deserialize(p));
        this.durationDay = item.duration_day;
        this.durationNight = item.duration_night;
        this.price = NumberHelper.anyToInteger(item.price);
        this.firstPricePeriod = new FirstPricePeriod().deserialise(item.first_price_period);
        this.startAt = item.start_at ? new Date(item.start_at) : null;
        this.endAt = item.end_at ? new Date(item.end_at) : null;
        this.commissionCompany = NumberHelper.anyToInteger(item.commission_company);
        this.commissionSeller = NumberHelper.anyToInteger(item.commission_seller);
        this.categoryTransport = new CategoryTransport().deserialize(item.category_transport);
        this.transportation = new Transportation().deserialize(item.transportation);
        this.bannerImageFiles = BannerImageFiles.deserialise(item.banner_image_files);
        this.firstActivePeriod = new FirstActivePeriod().deserialise(item.first_active_period);
        this.docFileUrl = item.doc_file_url;
        this.pdfFileUrl = item.pdf_file_url;
        this.hilightDescription = item.hilight_description;
        this.tags = item.tags.map((t) => new Tag().deserialise(t));
        this.tourCondition = item.tour_condition;
        this.note = item.note;
        this.updatedAt = item.updated_at ? new Date(item.updated_at) : null;
        this.descriptions = item.product_descriptions.map((description) =>
            new ProductDescription().deserialise(description)
        );
        this.productAttachmentFiles = item.product_attachment_files.map((attachment) =>
            new ProductAttachmentFile().deserialise(attachment)
        );
        this.periods = item.product_periods.map((period) => new ProductSinglePeriod().deserialise(period));
        this.actionLogs = (item.product_action_logs as any[]).map((log) => new ProductActionLog().deserialize(log));
        this.tourwowConfig = new ProductTourwowConfig().deserialize(item.tourwow_config);
        this.hasVisa = item.has_visa;
        this.visaPrice = item.visa_price;
        this.hotelStar = item.hotel_star;
        this.mealAmount = item.meal_amount;
        this.freeDay = item.free_day;
        this.twName = item.tw_name;
        this.twHilightDescription = item.tw_hilight_description;
        this.twBannerImageFiles = item.tw_banner_image_files.map((twBannerImageFile: ITwBannerImageFile) =>
            new TwProductFile().deserialize(twBannerImageFile)
        );
        //

        this.keywords = item.keywords;

        return this;
    }

    public serializeDetail(): any {
        return {
            tags: [],
            tour_condition: this.tourCondition,
            product_descriptions: this.descriptions.map((description) => {
                return description.serialise();
            }),
        };
    }
}

export class ProductSinglePeriod {
    id: number;
    startAt: string;
    endAt: string;
    priceAdultSingle: number;
    priceAdultDouble: number;
    priceAdultTriple: number;
    priceChildNoBed: number;
    commissionCompany: number;
    commissionSeller: number;
    quantity: number;
    quantityTourLead: number;
    quantityRemaining: number;
    isExpired: boolean;

    isChannelTwpOnlineBooking: boolean;
    sellStatusCode: number;
    sellStatusReason: string | null;

    public deserialise(period: any): this {
        this.id = period.id;
        this.startAt = period.start_at;
        this.endAt = period.end_at;
        this.priceAdultSingle = period.price_adult_single;
        this.priceAdultDouble = period.price_adult_double;
        this.priceAdultTriple = period.price_adult_triple;
        this.priceChildNoBed = period.price_child_no_bed;
        this.commissionCompany = period.commission_company;
        this.commissionSeller = period.commission_seller;
        this.quantity = period.quantity;
        this.quantityTourLead = period.quantity_tour_lead;
        this.quantityRemaining = period.quantity_remaining;
        this.isExpired = period.is_expired;

        this.isChannelTwpOnlineBooking = period.is_channel_twp_online_booking;
        this.sellStatusCode = +period.sell_status_code;
        this.sellStatusReason = period.sell_status_reason;

        return this;
    }
}

class FirstActivePeriod {
    public price: number;
    public priceCompare: number;
    public startAt: string;
    public endAt: string;
    public commissionCompany: string;
    public commissionSeller: string;
    public categoryTransport: CategoryTransport;
    public transportation: Transportation;

    public deserialise(first_active_period: any): this {
        if (first_active_period) {
            this.price = first_active_period.price;
            this.priceCompare = first_active_period.price_compare;
            this.startAt = first_active_period.start_at;
            this.endAt = first_active_period.end_at;
            this.commissionCompany = first_active_period.commission_company;
            this.commissionSeller = first_active_period.commission_seller;
            this.categoryTransport = new CategoryTransport().deserialize(first_active_period.category_transport);
            this.transportation = new Transportation().deserialize(first_active_period.transportation);
            return this;
        }
        return null;
    }
}

interface IBannerImageFile {
    fileId: number;
    fileUrl: string;
    isMain: boolean;
}

class BannerImageFiles {
    public static deserialise(dataArray): IBannerImageFile[] {
        return dataArray.map((data) => {
            return { fileId: data.file_id, fileUrl: data.file_url, isMain: data.is_main };
        });
    }
}
