import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
    selector: 'app-logout-page',
    templateUrl: './logout-page.component.html',
    styleUrls: ['./logout-page.component.scss'],
})
export class LogoutPageComponent implements OnInit {
    constructor(private authService: AuthenticationService) {}

    ngOnInit(): void {
        this.authService.logout();
    }
}
