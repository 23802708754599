import { Action, createReducer, on } from '@ngrx/store';
import {
    CountOrderStatus,
    CountProductSubCategory,
    ProductOrderListPaginated,
} from 'src/app/models/order/product-order-list.model';
import * as OrderActions from './order.actions';

export const orderFeatureKey = 'order';

export interface OrderState {
    countProductSubCategory: CountProductSubCategory;
    countOrderStatus: CountOrderStatus;
    productOrderListPaginated: ProductOrderListPaginated;
}

export const initialState: OrderState = {
    countProductSubCategory: null,
    countOrderStatus: null,
    productOrderListPaginated: null,
};

export const orderReducer = createReducer(
    initialState,

    on(OrderActions.getCountProductSubCategorySuccess, (state, action) => {
        return {
            ...state,
            countProductSubCategory: action.model,
        };
    }),

    on(OrderActions.getCountOrderStatusSuccess, (state, action) => {
        return {
            ...state,
            countOrderStatus: action.model,
        };
    }),

    on(OrderActions.getProductOrderListPaginatedSuccess, (state, action) => {
        let items = state.productOrderListPaginated ? [...state.productOrderListPaginated.result] : [];
        if (action.model.page === 1) {
            items = action.model.result;
        } else {
            items.push(...action.model.result);
        }

        const model = new ProductOrderListPaginated(
            action.model.page,
            action.model.pageEntries,
            action.model.total,
            action.model.result
        );

        return {
            ...state,
            productOrderListPaginated: model,
        };
    })
);

export function reducer(state: OrderState | undefined, action: Action) {
    return orderReducer(state, action);
}
