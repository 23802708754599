import { createAction, props } from '@ngrx/store';
import {
    CountOrderStatus,
    CountProductSubCategory,
    ProductOrderListPaginated,
} from 'src/app/models/order/product-order-list.model';

export const getCountProductSubCategory = createAction(
    '[Order] User try to get the count product sub category',
    props<{ searchString?: string }>()
);
export const getCountProductSubCategorySuccess = createAction(
    '[Order] Count product sub category fetched successfully',
    props<{ model: CountProductSubCategory }>()
);
export const getCountProductSubCategoryError = createAction(
    '[Order] Count product sub category fetched unsuccessfully',
    props<{ error: any }>()
);

export const getCountOrderStatus = createAction(
    '[Order] User try to get the count order status',
    props<{ productSubCategorySlug: 'all' | 'outbound' | 'domestic'; searchString?: string }>()
);
export const getCountOrderStatusSuccess = createAction(
    '[Order] Count order status fetched successfully',
    props<{ model: CountOrderStatus }>()
);
export const getCountOrderStatusError = createAction(
    '[Order] Count order status fetched unsuccessfully',
    props<{ error: any }>()
);

export const getProductOrderListPaginated = createAction(
    '[Order] User try to get the product order list paginated',
    props<{
        orderStatusSlug: 'waiting_confirm' | 'waiting_first_paid' | 'waiting_full_paid' | 'complete' | 'cancel';
        page?: number;
        pageEntries?: number;
        productSubCategorySlug?: 'all' | 'outbound' | 'domestic';
        searchString?: string;
        sortBy?: 'created_at_desc';
    }>()
);
export const getProductOrderListPaginatedSuccess = createAction(
    '[Order] Product order list paginated fetched successfully',
    props<{ model: ProductOrderListPaginated }>()
);
export const getProductOrderListPaginatedError = createAction(
    '[Order] Product order list paginated fetched unsuccessfully',
    props<{ error: any }>()
);
