<div class="modal-container">
    <div class="modal-header">
        <button type="button" class="close pull-right w-auto" aria-label="Close" (click)="cancelButton()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="mb-4">
            <h4 class="modal-title text-center"><i class="icon icon-garbage text-danger mr-2"></i>{{ title }}</h4>
            <small class="are-you-sure">{{ areYouSureText }}</small>
        </div>
        <div class="item-title" [innerHTML]="itemTitle"></div>
    </div>
    <div class="modal-footer">
        <div class="d-flex buttons">
            <button class="btn btn-primary" (click)="confirmButton()">ยืนยันลบข้อมูล</button>
            <button class="btn btn-secondary" (click)="cancelButton()">ยกเลิก</button>
        </div>
    </div>
</div>
