import { CurrencyPipe } from '@angular/common';
import { Directive, OnInit, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appTwcurrency]',
    providers: [CurrencyPipe],
})
export class CurrencyDirective implements OnInit {
    get el(): HTMLInputElement {
        return this.elementRef.nativeElement;
    }

    constructor(private elementRef: ElementRef, private formatcurrencypipe: CurrencyPipe) {}

    ngOnInit(): void {
        this.onInitTransform();
    }

    private onInitTransform(): void {
        this.el.value = this.el.value
            ? this.formatcurrencypipe.transform(this.el.value.replace(/\D/g, ''), 'THB', '', '1.0-0')
            : null;
    }

    @HostListener('focus', ['$event.target.value', '$event'])
    onFocus(value: any, event: FocusEvent): void {
        this.el.value = this.formatcurrencypipe.transform(value.replace(/\D/g, ''), 'THB', '', '1.0-0');
        if (event.which == 9) {
            return;
        }

        this.el.select();
    }

    @HostListener('blur', ['$event.target.value'])
    onBlur(value: any): void {
        if (this.el.value) {
            this.el.value = this.formatcurrencypipe.transform(value.replace(/\D/g, ''), 'THB', '', '1.0-0');
        }
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent): void {
        let isKeyAllowed = true;
        if (e.key.length == 1) {
            if (e.metaKey || e.ctrlKey) {
                /** detecting cmd or ctrl */
                isKeyAllowed = true;
            } else if (e.key === '.') {
                isKeyAllowed = true;
            } else if (new RegExp('^[0-9]$').test(e.key) === false) {
                isKeyAllowed = false;
            }
        }
        if (!isKeyAllowed) {
            e.preventDefault();
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(e: ClipboardEvent): void {
        const text = e.clipboardData.getData('text');
        if (new RegExp('^([0-9]+)(.[0-9]+)?$').test(text) === false) {
            e.preventDefault();
        }
    }
}
