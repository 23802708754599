import { ProductFileType } from 'src/app/constants/product/product-file-types.constant';

export interface IProductFile {
    id: number;
    file: File;
    fileName?: string;
    pathURL: string;
    typeSlug: ProductFileType;
}

export class ProductFile implements IProductFile {
    public id: number;
    public file: File;
    public fileName?: string;
    public pathURL: string;
    public typeSlug: ProductFileType;

    public deserialize(input: any, file: File) {
        this.id = input.product_image_file_id;
        this.pathURL = input.product_image_url;
        this.file = file;
        return this;
    }
}
