<div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="top-wrapper">
    <div class="exclamation-mark"></div>
    <div class="message">
        <p>ระบบยังไม่ได้บันทึกข้อมูล</p>
        <p>กดปุ่มยืนยัน หากต้องการออกจากหน้านี้</p>
    </div>
    <div class="d-flex buttons">
        <button class="btn btn-secondary" (click)="onConfirmClick()">ยืนยัน</button>
        <button class="btn btn-secondary" (click)="bsModalRef.hide()">ยกเลิก</button>
    </div>
</div>
