export class Transportation {
    id: number = null;
    nameTH: string = null;
    nameEN: string = null;
    code: string = null;

    public deserialize(item: any) {
        if (item) {
            this.id = item.id;
            this.nameTH = item.name_th;
            this.nameEN = item.name_en;
            this.code = item.code;
            return this;
        }

        return null;
    }
}
